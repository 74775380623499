import React, { useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslate } from '../components/TranslationText'
import { OperatingSystems } from '../components/Layout';
import DownloadAppsSection from '../components/DownloadAppsSection';

const appleStoreLink = process.env.REACT_APP_APPLE_LINK;
const playStoreLink = process.env.REACT_APP_PLAY_LINK;

export default function OpenApp() {
  const { translate } = useTranslate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const AppStore = searchParams.has("os") ? searchParams.get("os") : null;
  const storeLink = AppStore == OperatingSystems.ANDROID ? playStoreLink : AppStore == OperatingSystems.IOS ? appleStoreLink : '';
  const openAppRef = useRef();
  const openstoreRef = useRef();
  useEffect(() => {
    // setTimeout(() => {
    //   openstoreRef.current.click();
    // }, 1000);
    openAppRef.current.click();

  }, [])

  // intent://#Intent;scheme=myflutterapp;package=${packageName};S.browser_fallback_url=${encodeURIComponent(playStoreUrl)};end;

  return (
    <div className='row'>
      {/* <Link ref={openAppRef} to={'myappscheme://success'} className='d-none'>OpenApp</Link> */}
      <Link ref={openAppRef} to={`intent://#Intent;scheme=myappscheme://success;package=com.eahs.main;S.browser_fallback_url=${encodeURIComponent(storeLink)};end;`} className='d-none'>OpenApp</Link>
      {/* <Link ref={openstoreRef} to={storeLink} className='d-none'>OpenStore</Link> */}
      <DownloadAppsSection />
    </div>
  )
}
