import React, { useState } from 'react'
import ArenaTable from './ArenaTable';
import { TableObject } from './TableObject';
import { useControls } from 'react-zoom-pan-pinch';

export default function Arena77ZonesTable({ totalTables, toggleTableSelection, BaseURL, translate, activeZone, setActiveZone }) {
    const { zoomToElement, } = useControls();
    return <div className="bookingTableContainer" style={{ width: 1350 }} >
        <div className="bookingTablesRow" style={{ marginLeft: 50 }}>
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            {/* <div className="vipSection" style={{ marginLeft: 15 }}>{translate('web.judgesNotForSelleing', 'Judges Not For Selleing')}</div> */}
            {/* Refactor to be part of map */}
        </div>
        <div className="bookingTableCenter" >
            {!(activeZone == 'B' || activeZone == 'A') &&
                <div className="bookingTablesColumn d-flex flex-column " style={{ marginRight: 0, height: 500 }}>
                    <div className='zonebg my-auto' onClick={() => { setActiveZone('A'); zoomToElement('A', '0.8'); }}>
                        Zone D
                    </div>
                    <div className='zonebg my-auto' onClick={() => { setActiveZone('B'); zoomToElement('B', '0.8'); }}>
                        Zone C
                    </div>
                </div>
            }
            {
                <>
                    <div className={`${activeZone == 'A' && 'mb-auto'}  ${activeZone == 'B' && 'mt-auto'} `} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        {activeZone == 'B' &&
                            <div className='zonebg mb-2' style={{height:250}} onClick={() => { setActiveZone('A'); zoomToElement('A', '0.8'); }}>
                                Zone D
                            </div>
                        }
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div id='A' className={`bookingTablesColumn ${activeZone == 'A' && 'mb-auto'}  ${activeZone == 'B' && 'mt-auto'} `} style={{ marginRight: 10 }}>

                                {activeZone == 'A' &&
                                    <>
                                        {totalTables
                                            .filter((item, index) => item.tableNo > 60 && item.tableNo < 66)
                                            .map((item, index) => (
                                                index < 10 &&
                                                <ArenaTable
                                                    key={item.tableNo}
                                                    tableNumber={item.tableNo}
                                                    tablePrice={item.price}
                                                    state={TableObject.fromRaw(item).state()}
                                                    onClick={() => toggleTableSelection(item.id, item.price)} />
                                            ))}
                                    </>
                                }
                                {activeZone == 'B' &&
                                    <>
                                        {totalTables
                                            .filter((item, index) => item.tableNo > 55 && item.tableNo < 61)
                                            .map((item, index) => (
                                                index < 10 &&
                                                <ArenaTable
                                                    key={item.tableNo}
                                                    tableNumber={item.tableNo}
                                                    tablePrice={item.price}
                                                    state={TableObject.fromRaw(item).state()}
                                                    onClick={() => toggleTableSelection(item.id, item.price)} />
                                            ))}
                                    </>
                                }
                            </div>
                            <div className={`bookingTablesColumn ${activeZone == 'A' && 'mb-auto'} ${activeZone == 'B' && 'mt-auto'} `} style={{ marginRight: 10 }}>
                                {activeZone == 'A' && <>
                                    {totalTables
                                        .filter((item, index) => item.tableNo > 50 && item.tableNo < 56)
                                        .map((item, index) => (
                                            index < 10 &&
                                            <ArenaTable
                                                key={item.tableNo}
                                                tableNumber={item.tableNo}
                                                tablePrice={item.price}
                                                state={TableObject.fromRaw(item).state()}
                                                onClick={() => toggleTableSelection(item.id, item.price)} />
                                        ))}
                                </>}
                                {activeZone == 'B' && <>
                                    {totalTables
                                        .filter((item, index) => item.tableNo > 45 && item.tableNo < 51)
                                        .map((item, index) => (
                                            index < 10 &&
                                            <ArenaTable
                                                key={item.tableNo}
                                                tableNumber={item.tableNo}
                                                tablePrice={item.price}
                                                state={TableObject.fromRaw(item).state()}
                                                onClick={() => toggleTableSelection(item.id, item.price)} />
                                        ))}
                                </>}
                            </div>
                            <div className={`bookingTablesColumn ${activeZone == 'A' && 'mb-auto'} ${activeZone == 'B' && 'mt-auto'} `} style={{ marginRight: 10 }}>
                                {activeZone == 'A' && <>
                                    {totalTables

                                        .filter((item, index) => item.tableNo > 40 && item.tableNo < 46)
                                        .map((item, index) => (
                                            index < 11 &&
                                            <ArenaTable
                                                key={item.tableNo}
                                                tableNumber={item.tableNo}
                                                tablePrice={item.price}
                                                state={TableObject.fromRaw(item).state()}
                                                onClick={() => toggleTableSelection(item.id, item.price)} />
                                        ))}
                                </>}
                                {activeZone == 'B' && <>
                                    {totalTables

                                        .filter((item, index) => item.tableNo > 34 && item.tableNo < 41)
                                        .map((item, index) => (
                                            index < 11 &&
                                            <ArenaTable
                                                key={item.tableNo}
                                                tableNumber={item.tableNo}
                                                tablePrice={item.price}
                                                state={TableObject.fromRaw(item).state()}
                                                onClick={() => toggleTableSelection(item.id, item.price)} />
                                        ))}
                                </>}
                            </div>
                        </div>
                        {activeZone == 'A' &&
                            <div className='zonebg mt-2' style={{height:250}} onClick={() => { setActiveZone('B'); zoomToElement('B', '0.8'); }}>
                                Zone C
                            </div>
                        }
                    </div>
                </>
            }
            <div className="BookingTableArena">
                <img
                    style={{ background: '#A67B52' }}
                    src={`${BaseURL}/assets/img/HorseArena.png`}
                    alt="Horse Arena" />
            </div>
        </div>
        <div id='B' style={{ height: 1, width: 1 }} />
        {!(activeZone == 'C' || activeZone == 'D') &&
            <div className="bookingTablesRow d-flex" style={{ marginRight: 0, width: 1080, justifyContent: 'space-between' }}>
                <div className='zonebg ' style={{ width: 250, marginLeft: 100, marginRight: 50, height: 125}} onClick={() => { setActiveZone('C'); zoomToElement('C', '0.8'); }}>
                    Zone B
                </div>
                <div className="vipSection" style={{height:125}}>{translate('web.vvipPlatForm', 'VVIP Platform')}</div>

                <div className='zonebg mx-auto' style={{ width: 250, height: 125 }} onClick={() => { setActiveZone('D'); zoomToElement('D', '0.8'); }}>
                    Zone A
                </div>
            </div>}
        {
            <div className={!(activeZone == 'C' || activeZone == 'D') && 'invisible'}>
                <div className="bookingTablesRow withVIPSection" style={{ marginLeft: 100 , height:70}}>
                    {activeZone == 'D' && <div className='zonebg' style={{ width: 250, height: 125, marginRight: 50 }} onClick={() => { setActiveZone('C'); zoomToElement('C', '0.8'); }}>
                        Zone B
                    </div>}
                    {activeZone == 'C' && totalTables
                        .filter((item, index) => item.tableNo > 6 && item.tableNo < 13)
                        .map((item, index) => (
                            index < 7 &&
                            <ArenaTable
                                key={item.tableNo}
                                tableNumber={item.tableNo}
                                tablePrice={item.price}
                                state={TableObject.fromRaw(item).state()}
                                onClick={() => toggleTableSelection(item.id, item.price)} />
                        ))}
                    <div className="vipSection" >{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
                    {activeZone == 'C' && <div className='zonebg' style={{ width: 250, height: 125, marginLeft: 50 }} onClick={() => { setActiveZone('D'); zoomToElement('D', '0.8'); }}>
                        Zone A
                    </div>}
                    {activeZone == 'D' && totalTables

                        .filter((item, index) => item.tableNo < 7)
                        .map((item, index) => (
                            index < 6 &&
                            <ArenaTable
                                key={item.tableNo}
                                tableNumber={item.tableNo}
                                tablePrice={item.price}
                                state={TableObject.fromRaw(item).state()}
                                onClick={() => toggleTableSelection(item.id, item.price)} />
                        ))}
                </div>
                <div className="bookingTablesRow withVIPSection secondVIPRow" style={{ marginLeft: 50 }}>
                    {activeZone == 'C' && totalTables

                        .filter((item, index) => item.tableNo > 17 && item.tableNo < 24)
                        .map((item, index) => (
                            index < 6 &&
                            <ArenaTable
                                key={item.tableNo}
                                tableNumber={item.tableNo}
                                tablePrice={item.price}
                                state={TableObject.fromRaw(item).state()}
                                onClick={() => toggleTableSelection(item.id, item.price)} />
                        ))}
                    <div className="vipSection" style={activeZone == 'D' ? { marginLeft: 300 } : {}}>{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
                    {activeZone == 'D' && totalTables
                        .filter((item, index) => item.tableNo > 12 && item.tableNo < 18)
                        .map((item, index) => (
                            index < 5 &&
                            <ArenaTable
                                key={item.tableNo}
                                tableNumber={item.tableNo}
                                tablePrice={item.price}
                                state={TableObject.fromRaw(item).state()}
                                onClick={() => toggleTableSelection(item.id, item.price)} />
                        ))}
                </div>
                <div className="bookingTablesRow withVIPSection secondVIPRow" style={{ marginLeft: 50 }}>
                    {activeZone == 'C' && totalTables

                        .filter((item, index) => item.tableNo > 28 && item.tableNo < 35)
                        .map((item, index) => (
                            index < 6 &&
                            <ArenaTable
                                key={item.tableNo}
                                tableNumber={item.tableNo}
                                tablePrice={item.price}
                                state={TableObject.fromRaw(item).state()}
                                onClick={() => toggleTableSelection(item.id, item.price)} />
                        ))}
                    <div className="vipSection" style={activeZone == 'D' ? { marginLeft: 300 } : {}}>{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
                    {activeZone == 'D' && totalTables
                        .filter((item, index) => item.tableNo > 23 && item.tableNo < 29)
                        .map((item, index) => (
                            index < 5 &&
                            <ArenaTable
                                key={item.tableNo}
                                tableNumber={item.tableNo}
                                tablePrice={item.price}
                                state={TableObject.fromRaw(item).state()}
                                onClick={() => toggleTableSelection(item.id, item.price)} />
                        ))}
                </div>
            </div>
        }
        <div id='C' style={{ height: 50, width: 100, background: 'none', marginLeft: 350, marginRight: 'auto' }} />
        <div id='D' style={{ height: 50, width: 100, background: 'none', marginLeft: 'auto', marginRight: 400 }} />

    </div>;
}