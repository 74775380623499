import React, { useEffect, useState } from 'react'
import { useTranslate } from '../components/TranslationText';
import { useSelector } from 'react-redux';
import axiosUmbraco from '../axios/axiosUmbraco';
import Loader from '../components/Loader';
import Popup from 'reactjs-popup';
import OrgChartCard from '../components/OrgChartCard';

export default function OrgChartPage() {
    const fetch = process.env.REACT_APP_ORGCHART_ID
    const { translate } = useTranslate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const { currentLanguage } = useSelector(
        (state) => state.studBookSlice
    );
    const [orgChart, setOrgChart] = useState([]);
    const [level1, setlevel1] = useState([]);
    const [level2, setlevel2] = useState([]);
    const [level3, setlevel3] = useState([]);
    const [level2Responsive, setlevel2Responsive] = useState([]);
    const [loaded, setloaded] = useState(false);
    const [total, setTotal] = useState(0);






    function getCurrentLanguageUmbraco() {
        // Replace this with your logic to retrieve the current language
        // For example, you can retrieve it from localStorage or from a state variable
        const lang = localStorage.getItem("eahsLang") ?? "en";
        if (lang == "en") return 'en-US';
        else return 'ar-AE'; // Defaulting to English for demonstration
    }
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    useEffect(() => {
        async function getdata() {
            const umbraco = await axiosUmbraco.get(`umbraco/delivery/api/v1/content?fetch=${fetch}&skip=0&take=10`, {
                headers: {
                    "accept-language": getCurrentLanguageUmbraco(),

                }
            })
            const formattedData = umbraco.data?.items?.map((item) => ({
                organizationalChartChildsList: item?.properties?.content?.items ?? [],
                organizationalChartLevel: item?.properties?.organizationalChartLevel ?? '',
                organizationalChartOrder: item?.properties?.organizationalChartOrder ?? 0,
                name: item?.properties?.organizationalChartTitle ?? '',
                title: item?.properties?.organizationalChartSubtitle ?? '',
                img: item?.properties?.personImage?.length > 0 ? item?.properties?.personImage[0]?.url : '',
                about: item?.properties?.orgChartAbout ?? '',
                facebookLink: item?.properties?.orgChartFacebookLink ?? '',
                instagramLink: item?.properties?.orgChartInstagramLink ?? '',
                twitterLink: item?.properties?.orgChartXLink ?? '',
                phoneNumber: item?.properties?.orgChartPhone ?? '',
                email: item?.properties?.orgChartEmail ?? '',
                aboutTitle: item?.properties?.orgChartAboutTitle ?? '',
                aboutFooter: item?.properties?.orgChartAboutFooter ?? '',
                isViewMore: item?.properties?.isViewMore ?? false,
            }));;
            setOrgChart(formattedData);
            // console.log('formated data is', formattedData)

            if (umbraco.data.items.length > 0) {
                const level1sortedData = formattedData
                    .filter(level1 => level1.organizationalChartLevel === "Level 1")
                    .sort((a, b) => a.organizationalChartOrder - b.organizationalChartOrder);
                const level2sortedData = formattedData
                    .filter(level2 => level2.organizationalChartLevel === "Level 2")
                    .sort((a, b) => a.organizationalChartOrder - b.organizationalChartOrder);
                const level3sortedData = formattedData
                    .filter(level3 => level3.organizationalChartLevel === "Level 3")
                    .sort((a, b) => a.organizationalChartOrder - b.organizationalChartOrder);
                setlevel1(level1sortedData);
                setlevel2(level2sortedData);
                setlevel3(level3sortedData);
                // const [first, second] = level2sortedData;
                // const responsivelevel2sortedData = [first, second];
                setlevel2Responsive(level2sortedData);
                setloaded(true);
            }
            setTotal(umbraco.data.total)
        }
        getdata();

    }, [currentLanguage])
    return (
        <>
            {total < 1 ? <Loader /> :
                windowWidth > 970 &&
                <div className='container-fluid px75'>
                    <div className='OrgChartContainer'>
                        <div className='OrgChartHeader'>
                            {/* first Card */}
                            {level1.map((item, index) => (
                                <div className='OrgChartHeaderCardContainer' style={{ position: index == (level1.length - 1) ? 'relative' : 'static' }}>

                                    <OrgChartCard cardInfo={item} translate={translate} isFirstLevel={true} index={index} />

                                    {index == (level1.length - 1) ?
                                        <>
                                            <div className='OrgChartHeaderCardSpacer hidetheSpacer' />
                                            <div className='OrgChartHeaderCardSpacer lastSpacer' style={{ height: (0.1016 * windowWidth + 168) }} />
                                        </>
                                        : <div className={`OrgChartHeaderCardSpacer ${(index == 0 || (!!item.img)) && 'mb-5'}`} />
                                    }
                                </div>
                            ))}
                        </div>
                        <div className={`OrgChartLevelTwoContainer MultiItemInRow ${level2.some(obj => !!obj.img) ? 'mt-5':'my-5'} `}>
                            <div className='OrgChartHeaderCardContainer'>
                                {level2.map((item, index) => (
                                    <>
                                        {index <= 2 &&
                                            <>
                                                <OrgChartCard cardInfo={item} translate={translate} isVertical={true} />
                                                {index < 1 &&
                                                    <div className='OrgChartHorizentalHeaderCardSpacer' />
                                                }
                                            </>


                                        }
                                    </>

                                ))}
                            </div >

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: 165 }}>
                                <div className='OrgChartHeaderCardSpacer hidetheSpacer' />
                                <div className='OrgChartHeaderCardSpacer hidetheSpacer' />
                            </div>
                        </div >
                        <div className='OrgChartSectionSpacer' style={{ width: 0.8837 * windowWidth - 312.11 }} />
                        <div className='OrgChartLevelTwoContainer'>
                            {level3.map((item, index) => (
                                <>
                                    {index <= 3 &&
                                        <div className='OrgChartHeaderCardContainer'>
                                            <div className={`OrgChartHeaderCardSpacer ${!!item.img && 'mb-5'}`} />
                                            <OrgChartCard cardInfo={item} translate={translate} />

                                        </div>
                                    }
                                </>

                            ))}
                        </div>
                    </div>
                </div>
            }
            {windowWidth <= 970 &&
                <>
                    <div className='container-fluid px75'>
                        <div className='OrgChartContainer'>
                            <div className='OrgChartHeader'>
                                {/* first Card */}
                                {level1.map((item, index) => (
                                    <div className='OrgChartHeaderCardContainer Responsive '>
                                        <OrgChartCard isMobileView={true} isFirstLevel={true} cardInfo={item} translate={translate} index={index} />
                                        {index < level1.length - 1 ?
                                            <div className='OrgChartHeaderCardSpacer' style={{ height: 110 }}>
                                            </div> : <br />
                                        }
                                    </div>
                                ))}
                            </div>
                            <div className='OrgChartLevelTwoContainer OrgChartLevelTwoContainerResponsive NewLevel2ResponsiveContainer'>
                                <div className='OrgChartSectionSpacerResponsive' />
                                {level2Responsive.map((item, index) => (
                                    <>
                                        {index <= 2 &&
                                            <div className='OrgChartHeaderCardContainer Responsive pt-5 OrgChartHeaderCardContainerResponsiveLevel2 NewLevel2Responsive'>
                                                <OrgChartCard cardInfo={item} isRelative={true} index={index} addBottomSpace={true}  translate={translate}/>
                                                <div className='orgChartHorizentalSpacerResponsive ' style={{top:190}} />
                                            </div>
                                        }

                                    </>

                                ))}
                            </div>
                            <div className='OrgChartLevelTwoContainer OrgChartLevelTwoContainerResponsive'>
                                <div className='OrgChartSectionSpacerResponsive' />
                                {level3.map((item, index) => (
                                    <>
                                        {index <= 3 &&
                                            <div className='OrgChartHeaderCardContainer Responsive pt-5 OrgChartHeaderCardContainerResponsiveLevel2 NewLevel2Responsive'>
                                                <div className='orgChartHorizentalSpacerResponsive' style={{top:190}} />
                                                <OrgChartCard cardInfo={item} translate={translate} isRelative={true} index={index+1}  addBottomSpace={true} />
                                               
                                                <br />
                                            </div>
                                        }

                                    </>

                                ))}
                            </div>
                        </div>
                    </div>

                </>

            }
        </>
    )
}


