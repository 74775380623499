import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NetaqInput from "../components/NetaqInput";
import ActionButton from "../components/ActionButton";
import { ToastContainer, toast } from "react-toastify";
import axios from "../axios/axios";
import axiosUmbraco from "../axios/axiosUmbraco";
import { useTranslate } from "../components/TranslationText";
import { useSelector } from "react-redux";
import { RemovePlusFromString } from "../helper/RemovePlusFromString";
import Loader from "../components/Loader";
import ArenaTable from "../components/ArenaTable";
import ComingSoon from "../components/ComingSoon";
import CronometerCounter from '../components/CronometerCounter';
import {
  getFirestore,
  collection,
  where,
  query,
  onSnapshot,
  orderBy,
  limit,
  serverTimestamp,
} from "firebase/firestore";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import axiosInstance from "../axios/axios";
import { formatDate } from "../helper/formatDate";
import { bookingTablesRequest } from "../firebase/collections";
import { useLocation, useNavigate } from "react-router";
import Arena64Table from "../components/Arena64Table";
import Arena22Table from "../components/Arena22Table";
import Popup from "reactjs-popup";
import Alshira3Table from "../components/Alshira3Table";
import { GuidGenerator } from "../helper/GuidGenerator";
import Arena77Table from "../components/Arena77Table";
import Arena77ZonesTable from "../components/Arena77ZonesTable";



const Controls = ({ setActiveZone }) => {
  const { zoomIn, zoomOut, resetTransform, zoomToElement } = useControls();
  const { translate } = useTranslate();

  return (
    <div className="zoomTools">
      <button className="tooltip" onClick={() => zoomIn()}><i class="fa-solid fa-magnifying-glass-plus"></i>   <span class="tooltiptext"> {translate('web.zoomIn', 'Zoom In')}</span></button>
      <button className="redbtn tooltip" onClick={() => zoomOut()}><i class="fa-solid fa-magnifying-glass-minus"></i> <span class="tooltiptext">{translate('web.zoomOut', 'Zoom Out')}</span></button>
      <button className="blueBtn tooltip" onClick={() => { resetTransform(); setActiveZone(''); }}><i class="fa-solid fa-magnifying-glass"></i> <span class="tooltiptext">{translate('web.zoomReset', 'Zoom Reset')}</span></button>
      <button className="blueBtn tooltip" onClick={() => { setActiveZone(''); resetTransform(); }}><i class="fa-solid fa-undo"></i> <span class="tooltiptext">{translate('web.zoneReset', 'Zone Reset')}</span></button>
    </div>
  );
};
const UMBRACOURL = process.env.REACT_APP_UMBRACO;

export default function BookTable({ isAds = false }) {
  // umbraco
  const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const ContactUsID = process.env.REACT_APP_CONTANCT_US_ID;
  const { translate } = useTranslate();
  const location = useLocation();
  const maxTablesToReserve = 1;
  const [showPopUp, setShowPopUp] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const [showForm, setShowForm] = useState(false);
  const anonymousUserId = localStorage.getItem('AnonymousUserId') ?? GuidGenerator();
  localStorage.setItem('AnonymousUserId', anonymousUserId);

  const zonzesActive = searchParams.has("zonesActive")
    ? searchParams.get("zonesActive") == 'true' ? 'true' : 'false'
    : 'false';
  const currentEventId = searchParams.has("eventid")
    ? searchParams.get("eventid")
    : null;

  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const [contactus, setContactus] = useState([]);
  const [loaded, setloaded] = useState(false);
  const [templateName, setTemplateName] = useState('');

  const [totalTables, setTotalTables] = useState([]);
  const navigate = useNavigate();

  const [activeZone, setActiveZone] = useState('');




  const id = ContactUsID;

  function getCurrentLanguageUmbraco() {
    const lang = localStorage.getItem("eahsLang") ?? "en";
    return lang === "en" ? "en-US" : "ar-AE";
  }

  const [openingEvents, setOpeningEvents] = useState([]);
  const [eventsLoaded, setEventsLoaded] = useState(false)
  // get Open TO book Template
  async function getOpeningEvents() {
    const lang = localStorage.getItem("eahsLang") ?? "en";
    const response = await axiosInstance.get(`bookingtable/getbookingtablesevents?Langauge=${lang}&isAdvertisingPackage=${isAds}`);
    // setOpeningEvents(response.data.result);
    setEventsLoaded(true);
    setEventsLoaded(true);

    const filteredEvent = response.data.result.filter((item) => item.id == currentEventId);
    setOpeningEvents(filteredEvent)
    return filteredEvent;

  }

  async function getTemplateTables(event, id) {
    let userProfileCahched = localStorage.getItem('eahsUserProfile');
    let userProfile = '';
    if (userProfileCahched !== "") {
      userProfile = JSON.parse(localStorage.getItem('eahsUserProfile')) ?? '';
    }
    const eventID = id;
    const eventType = 'Show';

    const response = await axiosInstance.get(`bookingtable/geteventtemplatetables?EventId=${eventID}&EentType=${eventType}`);
    setTemplateName(response.data.result.templateCode);
    setTotalTables(response.data.result.tablesList.sort((a, b) => b.tableNo - a.tableNo));
    const pastHoldingTables = response.data.result.tablesList;
    let filterdTables = []
    if (!!userProfile?.id) {
      filterdTables = await pastHoldingTables.filter((x) => (x.ownerId == userProfile.id && x.ownerId != "" && x.ownerId != null && x.ownerId != undefined && x.status === 4))
      setSelectedTables(filterdTables.map((x) => x.id));
    } else {

      filterdTables = await pastHoldingTables.filter((x) => (x.anonymousUserId == anonymousUserId && x.anonymousUserId != "" && x.anonymousUserId != null && x.anonymousUserId != undefined && x.status === 4))
      setSelectedTables(filterdTables.map((x) => x.id));
    }

    if (filterdTables.length > 0) {
      setNowDate(new Date(filterdTables[0].holdEndDate))
    }

    setIsLoading(false);
    const lastTotal = filterdTables.map((x) => x.price).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    setTotalAmount(lastTotal);
    // subscribeToRealTimeHoldedTables(filterdTables);
  }
  const getRealTimeHoldedTables = async () => {
    try {
      const q = query(
        bookingTablesRequest,
        where('EventId', '==', currentEventId),
        orderBy('DateTime', 'desc')
        , limit(1)
      );
      return q;
    } catch (error) {
      console.log('error')
    }
  };

  const [TimerIsFinished, setTimerIsFinished] = useState(false);
  let userProfileCahched = localStorage.getItem('eahsUserProfile');
  let userProfile = '';
  if (userProfileCahched !== "") {
    userProfile = JSON.parse(localStorage.getItem('eahsUserProfile')) ?? '';
  }
  const updateBackendWithFirestore = (backendArray, firestoreArray) => {
    const nowDate = new Date();

    // Calculate the date and time 20 minutes from now
    const twentyMinutesFromNow = new Date(nowDate.getTime() + 20 * 60 * 1000);

    // Filter the array to get only items where holdEndDate is within the next 20 minutes
    const firestoreTables = firestoreArray.filter(item => {
      const holdEndDate = new Date(item.holdEndDate);
      return holdEndDate >= nowDate && holdEndDate <= twentyMinutesFromNow;
    });
    const excludeIds = firestoreTables.map(item => item.id);

    const filterdBackendarray = backendArray.filter(item => !excludeIds.includes(item.id));

    if (firestoreArray.length > 0) {
      return [...firestoreArray];
    } else {
      return [...backendArray];
    }
  };

  const [fireBaseConnected, setFireBaseConnected] = useState(false);
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);  // Flag to track the initial load

  async function subscribeToRealTimeHoldedTables() {
    try {
      const q = await getRealTimeHoldedTables(); // Await the Promise to get the Query object

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newData = [];
        querySnapshot.forEach((doc) => {
          newData.push(doc.data());
        });

        // console.log("newDataTables Are", newData);
        if (newData.length === 0) return;

        const latestTables = newData[0];
        // console.log("latest Tables Are", latestTables.TablesList);
        const isLoggedInUser = !!userProfile;

        const formattedData = latestTables.TablesList.map((table, index) => ({
          holdEndDate: table.HoldEndDate,
          id: table.Id,
          listOfTables: table.ListOfTables,
          ownerId: table.OwnerId,
          price: table.Price,
          status: table.Status,
          tableNo: table.TableNo,
          anonymousUserId: table.AnonymousUserId
        }));

        setFireBaseConnected(true);
        if (!initialLoadCompleted) {
          setInitialLoadCompleted(true);
          return;
        }

        // console.log('allTables From Back end are', totalTables);
        if (totalTables.length > 0) {
          const updatedTables = updateBackendWithFirestore(totalTables, formattedData);
          setTotalTables(updatedTables.sort((a, b) => b.tableNo - a.tableNo));
        }
      });

      return unsubscribe;
    } catch (error) {
      console.log('Error in Firestore', error);
    }
  }



  useEffect(() => {
    async function getdata() {
      const umbraco = await axiosUmbraco.get(
        `/umbraco/delivery/api/v1/content/item?id=${id}`,
        {
          headers: {
            "accept-language": getCurrentLanguageUmbraco(),
          },
        }
      );
      const formattedData = umbraco.data?.map((item) => ({
        contactWhatsApp: item?.properties?.contactWhatsApp ?? "",
        contactTitle: item?.properties?.contactTitle ?? "",
        contactPhone: item?.properties?.contactPhone ?? "",
        contactMapUrl: item?.properties?.contactMapUrl ?? "",
        contactEmail: item?.properties?.contactEmail ?? "",
        contactDescription: item?.properties?.contactDescription ?? "",
        contactAddress: item?.properties?.contactAddress ?? "",
      }));
      setContactus(formattedData);
      if (umbraco.data.length > 0) {
        setloaded(true);
      }
    }
    // getdata();
    getOpeningEvents()
    if (openingEvents.length > 0) {

      getTemplateTables(openingEvents[0], currentEventId);
      subscribeToRealTimeHoldedTables();

    }


    const metaTag = document.createElement("meta");
    metaTag.name = "viewport";
    metaTag.content = "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no";
    document.head.appendChild(metaTag);

    // Cleanup function to remove the meta tag when the component is unmounted
    return () => {
      document.head.removeChild(metaTag);
    };


  }, [currentLanguage, eventsLoaded, fireBaseConnected]);


  // end of umbraco
  const [paymentDetails, setPaymentDetails] = useState({
    tableEglishName: userProfile.fullName,
    tableArabicName: userProfile.fullNameAr,
    email: userProfile.email,
    contactNumber: userProfile.phone,
    collectorName: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTables, setSelectedTables] = useState([]);


  const handleChange = (event) => {
    setPaymentDetails({
      ...paymentDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handelUrlToPayment = (isOnlinePayment = true) => {
    const returnUrl = `${BaseURL}/profile?activeTab=paymentHistory`;

    const encodeURI = encodeURIComponent(returnUrl);
    const homeurl = `${BaseURL}/`;
    const encodedHome = encodeURIComponent(homeurl);

    if (!!userProfile) {
      window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${openingEvents[0].id}&paymentType=5&userId=${userProfile.id}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment ? 'yes' : 'no'}`;
    } else {
      window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${openingEvents[0].id}&paymentType=5&userId=${paymentDetails?.email}&returnUrl=${encodedHome}&isOnline=yes&anonymousUserId=${anonymousUserId}}`;
    }
  };

  const handleSubmit = async (event, isOnlinePayment = true) => {
    event.preventDefault();
    const fields = ["tableEglishName", "tableArabicName", "contactNumber", "email", "collectorName"]; // Add more fields as needed
    const emptyFields = fields.filter((field) => !paymentDetails[field]);
    setIsLoading(true);
    if (emptyFields.length > 0) {
      toast.error(
        `${translate(
          "web.pleaseFillInTheAllfields",
          "Please fill in the All fields"
        )}: ${emptyFields.join(", ").toUpperCase()}`
      );
      setIsLoading(false);
      return;
    }
    try {
      const send = await axios.post("/bookingtable/createbookingtablesrequest", {
        ...paymentDetails, listOfTables: selectedTables, ...(!!anonymousUserId ? { anonymousUserId: anonymousUserId } : {})
      });
      if (send.data.responseCode === "200") {
        if (isOnlinePayment) {
          toast.success(
            translate("web.redirectToPaymentPage", "Redirect To Payment Page")
          );
        } else {
          toast.success(translate("web.reservationDone", "Reservation Done"))
        }
        setIsLoading(false);
        setPaymentDetails({
          tableEglishName: "",
          tableArabicName: "",
          email: '',
          contactNumber: '',
          collectorName: ''
        });
        handelUrlToPayment(isOnlinePayment)
      } else {
        toast.error("Payment Request does not Sent Succecfully Check API");
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Error in try Catch");
      setIsLoading(false);
    }
  };

  const dismissAll = () => toast.dismiss();
  const [totalAmount, setTotalAmount] = useState(0);
  const [nowDate, setNowDate] = useState(new Date());
  async function removeHold(tableID, istimeout = false) {

    let currentDate = new Date();
    // Add 10 minutes to the current time
    currentDate.setMinutes(currentDate.getMinutes() + 10);
    setNowDate(currentDate);
    if (!istimeout) {
      setTimerIsFinished(false);
    }
    const response = await axiosInstance.post('bookingtable/removetablehold', { id: tableID, ...(!!anonymousUserId ? { anonymousUserId: anonymousUserId } : {}) })

  }
  async function addToHold(selectedTables) {
    if (isAds && selectedTables.length > maxTablesToReserve) {
      return;
    }

    let currentDate = new Date();

    // Add 10 minutes to the current time
    currentDate.setMinutes(currentDate.getMinutes() + 10);
    setNowDate(currentDate);
    setTimerIsFinished(false);

    if (!!userProfile?.id) {
      const response = await axiosInstance.post('bookingtable/settableshold', { listOfTables: selectedTables.toString() })
    } else {
      const response = await axiosInstance.post('bookingtable/settableshold', { listOfTables: selectedTables.toString(), anonymousUserId: anonymousUserId })
    }

  }


  const toggleTableSelection = (tableID, tablePrice) => {
    // if (!userProfile) {
    //   navigate(`/login?redirectURL=${location.pathname}?eventid=${currentEventId}`);
    //   return;
    // }

    setSelectedTables((prevState) => {
      const isSelected = prevState.includes(tableID);

      // Only allow adding if maxTablesToReserve limit is not reached
      if (!isSelected && prevState.length >= maxTablesToReserve && isAds) {
        // Optional: Show a message or warning to the user
        console.warn(`You can only reserve a maximum of ${maxTablesToReserve} tables.`);
        // setShowPopUp(true)
        toast.error(translate('web.youCanOnlyReserveAMaximumOf', 'You can only reserve a maximum of') + " " + maxTablesToReserve + " " + translate('web.table', 'table') + " " + translate('web.perTime', 'Per Time'))
        return prevState;
      }

      const updatedTables = isSelected
        ? prevState.filter((num) => num !== tableID)  // Deselect table
        : [...prevState, tableID];  // Select table
      // Update total amount based on table selection
      setTotalAmount((prevAmount) =>
        isSelected ? prevAmount - tablePrice : prevAmount + tablePrice
      );
      if (isSelected) {

        removeHold(tableID);  // Remove hold if deselected

      } else {
        addToHold([...prevState, tableID]);  // Add hold if selected

      }
      // Update the table's status only if selection was successful (i.e., limit not reached)
      setTotalTables((prev) => {
        const currentTableIndex = prev.findIndex((table) => table.id === tableID);
        const leftSlice = prev.slice(0, currentTableIndex);
        const currentTable = prev[currentTableIndex];
        currentTable.status = currentTable.status === 1 ? 4 : 1;
        currentTable.ownerId = userProfile?.id;
        currentTable.anonymousUserId = anonymousUserId;
        const rightSlice = prev.slice(currentTableIndex + 1);
        return [...leftSlice, currentTable, ...rightSlice];
      });

      return updatedTables;
    });
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    if (TimerIsFinished) {
      selectedTables.forEach(table => {
        // removeHold(table, true);
        const tablePrice = totalTables.filter((alltable) => alltable.id == table);

        toggleTableSelection(table, tablePrice[0].price)
      })
    }
  }, [TimerIsFinished])


 

  return (
    <>
      {
        eventsLoaded && (
          <>
            <ToastContainer
              position="top-right"
              onClick={dismissAll}
              theme="colored"
              autoClose={10000}
            />
            {showPopUp &&
              <Popup
                open={showPopUp}
                onClose={() => setShowPopUp(false)}
              >
                {(close) => (
                  <>
                    <div className="text-center text-danger" onClick={() => setShowPopUp(false)}>
                      {translate('web.youCanReserveJust', 'You Can Reserve Just') + " " + maxTablesToReserve + " " + translate('web.table', 'table')}
                    </div>
                    <br />
                    <ActionButton type={'normalbtn'} text={translate("web.close", "Close")} onClick={() => close()} />
                  </>
                )}
              </Popup>
            }
            <div className="container-fluid px75">
              <div className="row noMarginRow rtl ">
                {isAds &&
                  <>
                    <h3 className=" NewsPostTitle bold  rtl">
                      {translate('web.advertisingPackages', 'Advertising Packages')} ( {openingEvents[0].eventName.length} {translate('web.packShows', 'shows')})
                    </h3>
                  </>
                }
                {!isAds &&
                  <h3 className=" NewsPostTitle bold  rtl">
                    {translate('web.tableReservationOf', 'Table reservation of')} {openingEvents[0].eventName} {new Date(openingEvents[0].startDate).getFullYear()}
                  </h3>
                }
                <br />
                <br />
                {!isAds &&
                  <div className="row rtl " style={{ width: 'unset' }} >

                    <div className=" showDetaisLabel  rtl  " style={{ width: 'unset' }}>
                      <i
                        className="fa fa-calendar C89C66"
                        style={{ margin: "0 5px" }}
                      ></i>
                      <span>
                        {translate("web.from", "From")} : {formatDate(openingEvents[0].startDate)}
                      </span>
                    </div>
                    <div className=" showDetaisLabel  rtl" style={{ width: 'unset' }} >
                      <i
                        className="fa fa-calendar C89C66"
                        style={{ margin: "0 5px" }}
                      ></i>
                      <span>
                        {translate("web.to", "To")} : {formatDate(openingEvents[0].endate)}
                      </span>
                    </div>
                  </div>}
                {!!(openingEvents[0]?.addressText) &&
                  (!!openingEvents[0]?.mapLocation[0] && !!openingEvents[0].addressText && !isAds
                    ?
                    <div className="justRotate px-0" style={{ width: 'unset' }}>
                      <Link to={openingEvents[0].mapLocation[0]} target="_blank" className="d-inline-block showDetaisLabel  justRotate rtl C89C66" style={{ width: 'unset' }}>
                        <i
                          className="fa-solid fa-location-dot C89C66"
                          style={{ margin: "0 5px" }}
                        ></i>
                        <span style={{ textDecoration: 'underline' }}>
                          {openingEvents[0]?.addressText}
                        </span>
                      </Link>
                    </div>
                    :
                    (!!openingEvents[0].addressText && !isAds) &&
                    <div className="justRotate px-0" style={{ width: 'unset' }} >
                      <div className="d-inline-block showDetaisLabel justRotate  rtl C89C66" style={{ width: 'unset' }}>
                        <i
                          className="fa-solid fa-location-dot C89C66"
                          style={{ margin: "0 5px" }}
                        ></i>
                        <span>
                          {openingEvents[0]?.addressText}
                        </span>
                      </div>
                    </div>
                  )
                }
              </div>
              {isAds &&
                <div className="row rtl m0" style={{ flexWrap: 'wrap-reverse' }}>

                  <div className="col-lg-12 col-md-12 col-sm-12 row rtl m0">
                    {!!openingEvents[0].advertisingPackageTitle &&
                      <div className="col-lg-12 col-md-12 colsm-12 mx-auto currentFont" dangerouslySetInnerHTML={{ __html: openingEvents[0].advertisingPackageTitle }} />
                    }
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 row m0 align-items-start p0">
                    <img style={{ width: '100%', }} src={`${BaseURL}/assets/img/boolingTableBanner.png`} />
                  </div>
                </div>
              }
              <br />
              <div className="row noMarginRow  ">
                <div className="col-lg-12 col-md-12 col-sm-12 " style={{ position: 'relative' }}>

                  <div
                    className="row noMarginRow bookingArenaBorder bg-light"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      // maxWidth: '80%',
                      margin: 'auto',
                      position: 'relative'
                    }}
                  >
                    <TransformWrapper
                      initialScale={windowWidth > 1367 ? 0.5 : windowWidth * 0.0005}
                      initialPositionX={0}
                      initialPositionY={0}
                      minScale={0.1}
                      smooth
                      centerOnInit={windowWidth > 1367 ? (templateName == 'Alshira3' ? false : true) : true}
                      centerZoomedOut
                      pinch={{ disabled: true, }}
                      wheel={{ wheelDisabled: true, touchPadDisabled: true }}
                      className={'bg-light'}
                    >
                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <>
                          <Controls setActiveZone={setActiveZone} />
                          <TransformComponent wrapperStyle={{ width: '100%', height: 400, paddingTop: zonzesActive == 'true' ? 50 : 25 }}  >
                            {templateName == 'IthadArena' && zonzesActive == 'false' &&
                              <Arena77Table totalTables={totalTables} activeZone={activeZone} setActiveZone={setActiveZone} toggleTableSelection={toggleTableSelection} BaseURL={BaseURL} translate={translate} />
                            }
                            {templateName == 'IthadArena' && zonzesActive == 'true' &&
                              <Arena77ZonesTable totalTables={totalTables} activeZone={activeZone} setActiveZone={setActiveZone} toggleTableSelection={toggleTableSelection} BaseURL={BaseURL} translate={translate} />
                            }
                            {templateName == 'Template64' &&
                              <Arena64Table totalTables={totalTables} toggleTableSelection={toggleTableSelection} BaseURL={BaseURL} translate={translate} />
                            }
                            {templateName == 'Template22' &&
                              <Arena22Table totalTables={totalTables} toggleTableSelection={toggleTableSelection} BaseURL={BaseURL} translate={translate} />
                            }
                            {templateName == 'Alshira3' &&
                              <Alshira3Table totalTables={totalTables} toggleTableSelection={toggleTableSelection} BaseURL={BaseURL} translate={translate} />
                            }
                          </TransformComponent>
                        </>
                      )}
                    </TransformWrapper>

                  </div>
                  <div className="BookingTableRow alignselfflexCenter TabelLegendContainer">
                    <div className="TableLegend">
                      <ArenaTable
                        tableNumber={'available'}
                        tablePrice={0}
                        state={'available'}
                      />
                      <h6> {translate('web.availableTable', 'Available Table')}</h6>
                    </div>
                    <div className="TableLegend">
                      <ArenaTable
                        tableNumber={'booked'}
                        tablePrice={0}
                        state={'booked'}
                      />
                      <h6> {translate('web.bookedTable', 'Booked Table')}</h6>
                    </div>
                    <div className="TableLegend">
                      <ArenaTable
                        tableNumber={'selected'}
                        tablePrice={0}
                        state={'selected'}
                      />
                      <h6> {translate('web.selectedTable', 'Selected Table')}</h6>
                    </div>
                    <div className="TableLegend">
                      <ArenaTable
                        tableNumber={'NA'}
                        tablePrice={0}
                        state={'NA'}
                      />
                      <h6> {translate('web.unavailableTable', 'Unavailable Table')}</h6>
                    </div>
                    <div className="TableLegend">
                      <ArenaTable
                        tableNumber={'holded'}
                        tablePrice={'0'}
                        state={'hold'}
                      />
                      <h6> {translate('web.holdedTable', 'Holded Table')}</h6>
                    </div>
                    {/* <div className="TableLegend">
                      <ArenaTable
                        tableNumber={'system'}
                        tablePrice={'0'}
                        state={'system'}
                      />
                      <h6> {translate('web.systemTable', 'System Table')}</h6>
                    </div> */}
                  </div>
                  {/* <div className="BookingTableRow alignselfflexCenter TabelLegendContainer rtl">
                    <div className="TableLegend text-secondary ">
                      <h3 className="bold text-secondary"><span className="">{translate('web.note', 'Note')} : </span> {isAds ? translate('web.eachTableContains7Chairs', 'Each table contains 7 chairs') : translate('web.eachTableContains6Chairs', 'Each table contains 6 chairs')}</h3>
                    </div>
                  </div> */}

                </div>
                {selectedTables.length > 0 && TimerIsFinished == false && !showForm &&
                  <div onClick={() => setShowForm(true)} style={{ position: 'fixed', bottom: 0, left: 0, zIndex: 50000 }}>
                    <div className="btn btn-success w-100 d-flex justify-content-center align-items-center fw-bold"   > {translate('web.continue', 'Continue')} </div>
                  </div>
                }


                {selectedTables.length > 0 && TimerIsFinished == false &&
                  <div className={`bookingFormContainer ${showForm && 'showBookingForm'} d-flex pt-5`}>
                    <form
                      className="col-lg-12 col-md-12 col-sm-12 contactFormContainer px-2 py-1 bg-white mx-auto rtl"
                      style={{ position: "relative", maxWidth: 500, height: '90vh', overflowY: 'auto', overflowX: 'hidden' }}
                    >
                      <div onClick={() => setShowForm(false)} className="btn btn-danger" style={{ position: 'absolute', top: 5, right: 5 }} ><i className="fa fa-times fa-lg"></i></div>
                      {selectedTables.length > 0 && TimerIsFinished == false &&
                        <>
                          <CronometerCounter isMinutes={true} className="redbtn" isBookingPage={true} endDate={nowDate} setTimerIsFinished={setTimerIsFinished} />
                        </>
                      }
                      <div className="row mx-0">
                        {selectedTables.map((table) => (
                          <div className="contactUsLable  rtl py-0" style={{ fontSize: 14 }}>
                            <span className="Contact-US-Desc fw-normal px-0 mx-0">
                              <i className="fa-solid fa-box mt-auto me-1"></i> <span style={{ width: 125, display: 'inline-block' }}> {translate('web.tableno', 'TableNo')}   {totalTables.find((item) => (item.id == table)).tableNo} </span> <i className=" ms-3 me-1 mt-auto fa-solid fa-couch"></i> 6 <i className=" ms-3 mt-auto fa-solid fa-sack-dollar"></i> {totalTables.find((item) => (item.id == table)).price.toLocaleString('en-US')} {translate('web.aed', 'AED')}
                            </span>
                          </div>
                        ))}
                        <div className="contactUsLable rtl py-0" style={{ fontSize: 14 }}>
                          <span className="Contact-US-Desc fw-normal px-0 mx-0">
                            <i className="fa-solid fa-sack-dollar "></i> <span style={{ width: 125, display: 'inline-block' }}>{translate('web.totalAmount', 'Total Amount')}</span> {Math.abs(totalAmount).toLocaleString('en-US')} {translate('web.aed')}
                          </span>
                        </div>
                      </div>

                      <NetaqInput
                        required={true}
                        value={paymentDetails.tableEglishName}
                        onChange={handleChange}
                        name={"tableEglishName"}
                        label={translate(
                          "web.EnglishName(NameToBePrintedOnTable)",
                          "English Name (Name to be printed on table)"
                        )}
                        placeholder={translate("web.fullName", "Full Name")}
                        type={"text"}
                      />
                      <NetaqInput
                        required={true}
                        value={paymentDetails.tableArabicName}
                        onChange={handleChange}
                        name={"tableArabicName"}
                        label={translate(
                          "web.ArabicName(NameToBePrintedOnTable)",
                          "Arabic Name (Name to be printed on table)"
                        )}
                        placeholder={translate(
                          "web.ArabicName(NameToBePrintedOnTable)",
                          "Arabic Name (Name to be printed on table)"
                        )}
                        type={"text"}
                      />
                      <NetaqInput
                        required={true}
                        value={paymentDetails.contactNumber}
                        onChange={handleChange}
                        name={"contactNumber"}
                        label={translate("web.contactNumber", "Contact Number")}
                        placeholder={translate("web.phone", "Phone")}
                        type={"text"}
                      />
                      <NetaqInput
                        required={true}
                        value={paymentDetails.email}
                        onChange={handleChange}
                        name={"email"}
                        label={translate("web.email", "Email")}
                        placeholder={translate("web.email", "Email")}
                        type={"email"}
                      />
                      <NetaqInput
                        required={true}
                        value={paymentDetails.collectorName}
                        onChange={handleChange}
                        name={"collectorName"}
                        label={translate("web.collectorName", "Collector Name")}
                        placeholder={translate("web.collectorName", "Full Name")}
                        type={"text"}
                      />


                      {isLoading ? (
                        <Loader />
                      ) : (
                        <>
                          <ActionButton
                            type={"submit"}
                            text={
                              <>
                                <i class="fa-solid fa-sack-dollar fa-lg marginrightleft-10"></i>
                                {translate("web.proceedToPayment", "Proceed To Payment")}
                              </>
                            }
                            margin={"15px auto"}
                            width={"100%"}
                            onClick={(e) => { handleSubmit(e, true) }}
                          />
                          {userProfile.participateWithoutPayment == true &&
                            <ActionButton
                              type={"bootstrapbtn"}
                              className="btn-info text-white"
                              text={
                                `<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>
                            ${translate("web.payLater", "Pay Later")}`
                              }
                              margin={"15px auto"}
                              width={"100%"}
                              onClick={(e) => { handleSubmit(e, false) }}
                            />
                          }
                        </>
                      )}
                    </form>
                  </div>
                }

              </div>

            </div>
          </>
        )
      }
      {/* <ComingSoon /> */}
    </>
  );
}