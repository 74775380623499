import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchCreateRegisteringNewArabianHorseFoalServiceRequestthunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
  FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownthunk,
  FetchUpdateRegisteringNewArabianHorseFoalServiceRequestthunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetGetServicesRequestByIdData,
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import dayjs from "dayjs";
import { FetchHorseColorsthunk } from "../Redux/LookUp/LookUpAction";
import axios from "../axios/axios";
import { FetchOwnerHorsesDropDownthunk } from "../Redux/OwnerProfile/OwnerProfileAction";
import { isMoreThanDays } from "../helper/isMoreThanDays";
import axiosMap from "axios";
import EahsForm from "../components/EahsForm";

export default function RequestRegisteringNewArabianHorseFoal() {
  //env
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  //const UMBRACOURL = process.env.REACT_APP_Local_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const { horseColorsData } = useSelector(
    (state) => state.lookUpSlice
  );

  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
    ownerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownData,
    createRegisteringNewArabianHorseFoalServiceRequestData,
  } = useSelector((state) => state.servicesRequestsSlice);

  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));

  const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

  const dispatch = useDispatch();

  const { translate } = useTranslate();

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    coveringCertificate: "",
    coveringCertificateDrop: {
      label: translate("web.coveringCertificate", "Covering Certificate"),
      value: null,
    },
    mareHorse: "",
    stallionHorse: "",
    mareOwner: "",
    stallionOwner: "",
    breeder: "",
    coveringDate: "",

    nameEn: "",
    nameAr: "",
    birthDate: null,
    color: "",
    colorDrop: {
      label: translate("web.color", "Color"),
      value: null,
    },
    sex: "Stallion",
    emirate: "",
    emirateDrop: {
      label: translate("web.emirate", "Emirate"),
      value: null,
    },
    area: "",
    areaDrop: {
      label: translate("web.area", "Area"),
      value: null,
    },
    locationOnMap: "",

    isRegistration: true,
    address: "",
    mobile: "",

    eventName: "",
    comment: "",
    ownerComment: "",
    referenceNumber: "",
    requestsType: "",
    paymentStatus: "",
    registeringNewArabianHorseFoalId: null,
    gpsLocationLink: "",

  });

  const [errorCoveringCertificate, setErrorCoveringCertificate] = useState(false);
  const [errorNameEn, setErrorNameEn] = useState(false);
  const [errorNameAr, setErrorNameAr] = useState(false);
  const [errorBirthDate, setErrorBirthDate] = useState(false);
  const [errorColor, setErrorColor] = useState(false);
  const [errorEmirate, setErrorEmirate] = useState(false);
  const [errorArea, setErrorArea] = useState(false);
  const [errorLocationOnMap, setErrorLocationOnMap] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false);
  const [errorMobile, setErrorMobile] = useState(false);

  const [checkedItems, setCheckedItems] = useState({
    option1: false,
    option2: false
  });

  const [profile, setProfile] = useState({
    label:
      !!userProfile &&
      userProfile?.ownerRegisterNo +
      " - " +
      userProfile[`fullName${currentLanguage === "en" ? "" : "Ar"}`],
    value: localStorage.getItem("EAHSUserID"),
  });

  const subAccountId = localStorage.getItem("currentSubAccountId");

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  //const [geoLocation, setGeoLocation] = useState({ lat: 51.505, lng: -0.09 }); // Default to London
  //const [statusGeoLocation, setStatusGeoLocation] = useState("new");

  const [latitude, setLatitude] = useState(23.55344);
  const [longitude, setLongitude] = useState(53.56701);
  const [lockey] = useState(Math.floor(Math.random() * 10000 + 1000));

  const _isOnlinePayment = useRef("");
  const _status = useRef("");

  const [emiratelistoptions, setEmirateListOptions] = useState([]);
  const [regionsList, setRegionsList] = useState([]);

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  //function
  const setPhoneNumber = (value) => {
    setFormModel((prev) => ({
      ...prev,
      mobile: value
    }));
  }

  const handleFormChange = (e, input) => {
    if (input === "coveringCertificate") {
      let coveringCertificate = ownerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownData.filter((item) => item.id == e.value);

      setFormModel({
        ...formModel,
        coveringCertificate: e.value,
        coveringCertificateDrop: e,
        mareHorse: coveringCertificate[0]?.mare ?? "",
        stallionHorse: coveringCertificate[0]?.stallion ?? "",
        mareOwner: coveringCertificate[0]?.mareOwner ?? "",
        stallionOwner: coveringCertificate[0]?.stallionOwner ?? "",
        coveringDate: coveringCertificate[0]?.coveringDate ?? "",
        //coveringDate: formattedDate,
        breeder: coveringCertificate[0]?.breeder ?? "",
        isEmbryoTransfer: coveringCertificate[0]?.isEmbryoTransfer ?? false,
      });
    } else if (!!input && input === "color") {
      setFormModel({
        ...formModel,
        color: e.value,
        colorDrop: e,
      });
    } else if (!!input && input === "emirate") {
      getRegionListbyID(e.value);

      setFormModel({
        ...formModel,
        emirate: e.value,
        emirateDrop: e,
        area: "",
        areaDrop: {
          label: translate("web.area", "Area"),
          value: null,
        }
      });
    } else if (!!input && input === "area") {
      setFormModel({
        ...formModel,
        area: e.value,
        areaDrop: e,
      });
    } else if (input === "receiveType") {
      setFormModel({
        ...formModel,
        isRegistration: e.target.value === "Registration",
        address: "",
        mobile: ""
      });
    } else if (!!input && input === "nameEn") {
      // const regex = /^[a-zA-Z0-9]*$/;
      const regex = /^[a-zA-Z._]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          nameEn: e.target.value.toUpperCase(),
        });
      }

    } else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validInputs = () => {
    let valid = true;

    if (
      formModel.coveringCertificate === ""
    ) {
      setErrorCoveringCertificate(true);
      valid = false;
    }
    else {
      setErrorCoveringCertificate(false);
    }

    if (
      formModel.nameEn === ""
    ) {
      setErrorNameEn(true);
      valid = false;
    }
    else {
      setErrorNameEn(false);
    }

    if (
      formModel.nameAr === ""
    ) {
      setErrorNameAr(true);
      valid = false;
    }
    else {
      setErrorNameAr(false);
    }

    if (
      formModel.birthDate === null
    ) {
      setErrorBirthDate(true);
      valid = false;
    }
    else {
      setErrorBirthDate(false);
    }

    if (
      formModel.color === ""
    ) {
      setErrorColor(true);
      valid = false;
    }
    else {
      setErrorColor(false);
    }

    if (
      formModel.emirate === ""
    ) {
      setErrorEmirate(true);
      valid = false;
    }
    else {
      setErrorEmirate(false);
    }

    if (
      formModel.area === ""
    ) {
      setErrorArea(true);
      valid = false;
    }
    else {
      setErrorArea(false);
    }

    // if (
    //   formModel.locationOnMap === ""
    // ) {
    //   setErrorLocationOnMap("");
    //   valid = false;
    // }
    // else {
    //   setErrorLocationOnMap("none");
    // }

    if (
      !formModel.isRegistration && (formModel.address === "" || formModel.address == null)
    ) {
      setErrorAddress(true);
      valid = false;
    }
    else {
      setErrorAddress(false);
    }

    if (
      !formModel.isRegistration && (formModel.mobile === "" || formModel.mobile == null)
    ) {
      setErrorMobile(true);
      valid = false;
    }
    else {
      setErrorMobile(false);
    }

    //terms
    if (
      !checkedItems.option1 ||
      (!checkedItems.option2 && formModel.birthDate != null && isMoreThanDays(new Date(formModel.birthDate), getServiceDefinitionByIdData.penaltyDuration))
    ) {
      toast.error(
        translate("web.pleaseCheckAllTerms", "Please Check All Terms")
      );
      valid = false;
    }

    if (
      formModel.eventName === "DNANegative" && (formModel.ownerComment === "" || formModel.ownerComment === "null")
    ) {
      toast.error(
        translate("web.pleaseEnterOwnerComment", "Please Enter Owner Comment")
      );
      valid = false;
    }

    return valid;
  }

  const handelFormSubmit = (status, isOnlinePayment) => {
    _isOnlinePayment.current = isOnlinePayment;
    _status.current = status;

    if (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice" && formModel.eventName !== "SentBack" && formModel.eventName !== "DNANegative") {
      const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

      const encodeURI = encodeURIComponent(returnUrl);

      window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=9&userId=${localStorage.getItem("EAHSUserID")}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment}&lang=${currentLanguage}`;
    }

    else {
      if (validInputs()) {
        let params = {
          id: id,
          status: status,
          eventName: formModel.eventName,
          ownerId: getUserID(),


          coveringCertificateId: formModel.coveringCertificate,
          horseNameEn: formModel.nameEn,
          horseNameAr: formModel.nameAr,
          birthDateString: dayjs(formModel.birthDate).format("DD/MM/YYYY"),
          color: formModel.color,
          sex: formModel.sex,
          emirate: formModel.emirate,
          area: formModel.area,
          // locationOnMap: formModel.locationOnMap,
          //latitude: geoLocation.lat,
          //longitude: geoLocation.lng,
          latitude: latitude,
          longitude: longitude,
          receiveType: formModel.isRegistration ? 1 : 2,
          address: formModel.address,
          mobile: formModel.mobile,
          ownerNote: formModel.ownerComment,

          // registeringNewArabianHorseFoalId: formModel.registeringNewArabianHorseFoalId,
          // createdBy: localStorage.getItem("EAHSUserID"),
          // serviceDefinitionId: serviceId,
        };

        //if (action == null) {
        dispatch(FetchCreateRegisteringNewArabianHorseFoalServiceRequestthunk(params));
        //} else if (action === "edit" || formModel.eventName === "SentBack" || (action === "approve" && formModel.eventName === "DNANegative")) {
        //dispatch(FetchUpdateRegisteringNewArabianHorseFoalServiceRequestthunk(params));
        //}
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    let params = {
      ownerId: element.value,
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    dispatch(FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownthunk(element.value));

    setProfile(element);

    setFormModel({
      ...formModel,
      coveringCertificate: "",
      coveringCertificateDrop: {
        label: translate("web.coveringCertificate", "Covering Certificate"),
        value: null,
      },
      mareHorse: "",
      stallionHorse: "",
      mareOwner: "",
      stallionOwner: "",
      breeder: "",
      coveringDate: "",
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  function CheckIfAgentHasPermission() {
    const AgentHasPermission = userProfile.subAccounts
      .filter((x) => x.value == subAccountId)[0]
      .ownersPermissionsList.some(
        (x) =>
          x.permissionName === "FoalRegistrations" || x.permissionName === "All"
      );
    return AgentHasPermission;
  }

  const getUserID = () => {
    const userID =
      !!userProfile && userProfile?.accountType !== "Agent" ? profile.value : subAccountId;
    return userID;
  };

  const getSelectedAccountName = () => {
    const selectAccount = userProfile.subAccounts.filter(
      (x) => x.value == subAccountId
    );

    if (selectAccount.length > 0) {
      return selectAccount[0];
    }
    else {
      return false;
    }
  };

  const dismissAll = () => toast.dismiss();

  async function getEmirateList() {
    try {
      const emiratelist = await axios.get('/lookup/getmaincities')

      const formattedData = await emiratelist.data.result.map((item) => ({
        text: item.name,
        value: item.id,
      }));

      setEmirateListOptions(formattedData)
    } catch (error) {
      toast.error(translate("web.errorFetchingEmirateList", "Error fetching emirate list"));
    }
  }

  async function getRegionListbyID(id) {
    try {
      const regionList = await axios.get(`/lookup/getareasbyemirateid?EmirateId=${id}`)
      const formattedData = await regionList.data.result.map((item) => ({
        text: item.name,
        value: item.id,
      }));
      setRegionsList(formattedData)
    } catch (error) {
      toast.error(translate("web.errorFetchingAreaList", "Error fetching area list"));
    }
  }

  const selectLocation = async () => {
    try {
      const response = await axiosMap.get(
        `https://maps.atlasapps.net/map.aspx?getLocationString=${lockey}`
      );

      if (!response.data) {
        toast.error(translate("web.pleaseMoveLocationMap", "Please move the location on the map"));
      } else {
        const [lat, lng] = response.data.split(',').map(Number);

        setLatitude(lat);
        setLongitude(lng);

        setFormModel({
          ...formModel,
          gpsLocationLink: `https://www.google.com/maps?q=${lat},${lng}`,
        });

        toast.success(translate("web.locationSuccessfullyLocated", "Location successfully located."));
      }
    } catch (error) {
      toast.error(translate("web.errorFetchingLocationData", "Error fetching location data."));
    }
  };

  const handelDisabledButton = () => {
    if (
      action === "view"
      || (action === "approve" && formModel.eventName !== "SentBack")
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowGeneralComments = () => {
    if (
      !!formModel.comment
      || (formModel.eventName === "SentBack" || formModel.eventName === "DNANegative")
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowOwnerComment = () => {
    if (formModel.eventName === "SentBack" || formModel.eventName === "DNANegative") {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowoption2 = () => {
    if (
      formModel.birthDate != null
      && isMoreThanDays(new Date(formModel.birthDate), getServiceDefinitionByIdData.penaltyDuration)
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowGeneralButtons = () => {
    if (
      action == null
      || action === "edit"
      ||
      (action === "approve"
        &&
        (
          formModel.eventName === "SentBack"
          || formModel.eventName === "DNANegative"
          || (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice")
        )
      )
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowDraftButton = () => {
    if (action == null || action === "edit") {
      // if (formModel.eventName === "" || formModel.eventName === "Draft") {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowPayLaterButton = () => {
    if (
      userProfile?.participateWithoutPayment === true
      && getServiceDefinitionByIdData.serviceFees > 0
      && formModel.eventName !== "SentBack"
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowPayButton = () => {
    if (getServiceDefinitionByIdData.serviceFees > 0
      && formModel.eventName !== "SentBack"
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowSubmitButton = () => {
    if (
      (
        getServiceDefinitionByIdData.serviceFees === 0
        && (action == null || action === "edit")
      )
      ||
      (action === "approve" && formModel.eventName === "SentBack")
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelMinDateForBirthDate = () => {
    let formattedDate = "";

    if (formModel.coveringDate !== "") {

      const [day, month, year] = formModel.coveringDate.split('/');

      //formattedDate = `${year}/${month}/${day}`;

      // Create a Date object
      const date = new Date(`${year}-${month}-${day}`);
      // Add 9 months
      date.setMonth(date.getMonth() + 9);

      // Add 20 days
      date.setDate(date.getDate() + 20);

      // Format back to YYYY/MM/DD
      formattedDate = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;

    }

    return dayjs(formattedDate).format("YYYY-MM-DD")
  };

  const handelMaxDateForBirthDate = () => {
    let formattedDate = "";

    if (formModel.coveringDate !== "") {

      const [day, month, year] = formModel.coveringDate.split('/');

      // Create a Date object
      const date = new Date(`${year}-${month}-${day}`);
      // Add 12 months
      date.setMonth(date.getMonth() + 12);

      // Add 10 days
      date.setDate(date.getDate() + 10);

      // Format back to YYYY/MM/DD
      formattedDate = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;

    }

    return dayjs(formattedDate).format("YYYY-MM-DD")
  };

  //Effect
  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      dispatch(FetchGetServicesRequestByIdthunk(params));
    }

    return () => {
      dispatch(resetGetServicesRequestByIdData());
    };
  }, []);

  useEffect(() => {
    if (!!userProfile && userProfile?.haveProfileSignture != true) {
      toast.error(
        translate(
          "web.completeOwnerProfileData",
          "Please complete the personal data of the profile."
        )
      );

      setTimeout(() => {
        navigate("/profile");
      }, 3000);
    }

    if (!!userProfile && userProfile?.accountType === "Agent") {
      if (userProfile.id == subAccountId) {
        navigate("/403-Not-Authrized");
      }

      if (!CheckIfAgentHasPermission()) {
        navigate("/403-Not-Authrized");
      }
    }

    dispatch(FetchHorseColorsthunk({ langauge: currentLanguage }));

    getEmirateList();

    dispatch(FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownthunk(getUserID()));

    let subOwnerProfilesParams = {
      id: getUserID(),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));
  }, [currentLanguage]);

  useEffect(() => {
    if (
      Object.keys(getServicesRequestByIdData).length > 0 && (getServicesRequestByIdData.serviceDefinitionId === serviceId)
    ) {
      setFormModel({
        ...formModel,
        eventName: getServicesRequestByIdData.eventName,
        comment: getServicesRequestByIdData.comment,
        requestsType: getServicesRequestByIdData.requestsType,
        paymentStatus: getServicesRequestByIdData.paymentStatus,
        referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
        registeringNewArabianHorseFoalId: getServicesRequestByIdData.registeringNewArabianHorseFoal.registeringNewArabianHorseFoalId,

        coveringCertificate: getServicesRequestByIdData.registeringNewArabianHorseFoal.coveringCertificateId,
        coveringCertificateDrop: {
          label: getServicesRequestByIdData.registeringNewArabianHorseFoal.referenceNumber + " / " + translate("web.mare", "Mare") + ":" + getServicesRequestByIdData.registeringNewArabianHorseFoal.mare + " / " + translate("web.stallion", "Stallion") + ":" + getServicesRequestByIdData.registeringNewArabianHorseFoal.stallion,
          value: getServicesRequestByIdData.registeringNewArabianHorseFoal.coveringCertificateId,
        },

        mareHorse: getServicesRequestByIdData.registeringNewArabianHorseFoal.mare,
        stallionHorse: getServicesRequestByIdData.registeringNewArabianHorseFoal.stallion,
        mareOwner: getServicesRequestByIdData.registeringNewArabianHorseFoal.mareOwner,
        stallionOwner: getServicesRequestByIdData.registeringNewArabianHorseFoal.stallionOwner,
        coveringDate: getServicesRequestByIdData.registeringNewArabianHorseFoal.coveringDate,
        breeder: getServicesRequestByIdData.registeringNewArabianHorseFoal.breeder,
        nameEn: getServicesRequestByIdData.registeringNewArabianHorseFoal.horseNameEn,
        nameAr: getServicesRequestByIdData.registeringNewArabianHorseFoal.horseNameAr,
        birthDate: getServicesRequestByIdData.registeringNewArabianHorseFoal.birthDate != null ? dayjs(getServicesRequestByIdData.registeringNewArabianHorseFoal.birthDate).format("YYYY-MM-DD") : null,
        color: getServicesRequestByIdData.registeringNewArabianHorseFoal.color,
        colorDrop: {
          label: getServicesRequestByIdData.registeringNewArabianHorseFoal.colorName,
          value: getServicesRequestByIdData.registeringNewArabianHorseFoal.color,
        },
        sex: getServicesRequestByIdData.registeringNewArabianHorseFoal.sex,
        emirate: getServicesRequestByIdData.registeringNewArabianHorseFoal.emirate,
        emirateDrop: {
          label: getServicesRequestByIdData.registeringNewArabianHorseFoal.emirateName,
          value: getServicesRequestByIdData.registeringNewArabianHorseFoal.emirate,
        },
        area: getServicesRequestByIdData.registeringNewArabianHorseFoal.area,
        areaDrop: {
          label: getServicesRequestByIdData.registeringNewArabianHorseFoal.areaName,
          value: getServicesRequestByIdData.registeringNewArabianHorseFoal.area,
        },
        //locationOnMap: getServicesRequestByIdData.registeringNewArabianHorseFoal.locationOnMap,     
        isRegistration: getServicesRequestByIdData.registeringNewArabianHorseFoal.isRegistration,
        address: getServicesRequestByIdData.registeringNewArabianHorseFoal.address,
        mobile: getServicesRequestByIdData.registeringNewArabianHorseFoal.mobile,
        ownerComment: getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerNote === "null" || getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerNote === null ? "" : getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerNote,
        gpsLocationLink: `https://www.google.com/maps?q=${getServicesRequestByIdData.registeringNewArabianHorseFoal.latitude},${getServicesRequestByIdData.registeringNewArabianHorseFoal.longitude}`,
      });

      // setGeoLocation({ lat: getServicesRequestByIdData.registeringNewArabianHorseFoal.latitude, lng: getServicesRequestByIdData.registeringNewArabianHorseFoal.longitude });
      // setStatusGeoLocation("edit");

      setLatitude(getServicesRequestByIdData.registeringNewArabianHorseFoal.latitude);
      setLongitude(getServicesRequestByIdData.registeringNewArabianHorseFoal.longitude);

      //another owner
      if (
        (getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerId !== getServicesRequestByIdData.registeringNewArabianHorseFoal.createdBy)
        && subOwnerProfilesByIdData.length > 0
        && getServicesRequestByIdData.requestsType !== "BackOffice"
      ) {

        dispatch(FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownthunk(getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerId));

        //update profile
        setProfile({
          label:
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerId
            )[0].registerNo +
            " - " +
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerId
            )[0][`text${currentLanguage === "en" ? "" : "Ar"}`],
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerId
          )[0].value,
        });
      }

      //options
      setCheckedItems({
        option1: true,
        option2: true,
      });
    }
  }, [
    getServicesRequestByIdData,
  ]);

  useEffect(() => {
    //save
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_isOnlinePayment.current !== "") {
        toast.success(
          translate(
            "web.yourRequestHasSuccessfully",
            "Your Request Has Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        if (_isOnlinePayment.current !== "" && _status.current === "Draft") {

          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id == null ? createRegisteringNewArabianHorseFoalServiceRequestData.id : id}&paymentType=9&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${_isOnlinePayment.current}&lang=${currentLanguage}`;

        } else if (_isOnlinePayment.current !== "" && _status.current === "Submit") {

          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=10&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${_isOnlinePayment.current}&lang=${currentLanguage}`;

        } else {
          navigate(`/profile?activeTab=${"e_services"}`);
        }
      }, 1000);
    }

    //error
    else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
      toast.error(
        translate(toasterMessageServicesRequests, toasterMessageServicesRequests)
      );

      dispatch(resetToasterMessageServicesRequests());
    }
  }, [toasterMessageServicesRequests]);

  //consoles
  console.log(formModel)

  return (
    <div className="container-fluid container-fluid-padding">

      {/* loading div */}
      <div
        className={`page-overlay text-center ${servicesRequestsLoading
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      {/* toast */}
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      {/* content page */}
      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl">

          {/* Request Embryo Transfer Right Purchace Agreement title */}
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage === "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>

          {/* Selected Account */}
          {subOwnerProfilesByIdData.length > 0 &&
            (action == null || action === "edit") &&
            (
              <div className="col-md-6 col-sm-12 mb-3">
                {!!userProfile && userProfile?.accountType !== "Agent" ? (
                  <>
                    <label className="capatalize_text">
                      {translate("web.selectedAccount", "Selected Account")}
                    </label>
                    <Select
                      className="mt-10 zindex200"
                      options={[
                        {
                          label:
                            userProfile?.ownerRegisterNo +
                            " - " +
                            userProfile[
                            `fullName${currentLanguage === "en" ? "" : "Ar"}`
                            ],
                          value: localStorage.getItem("EAHSUserID"),
                        },
                        ...(!!subOwnerProfilesByIdData &&
                          !!subOwnerProfilesByIdData.length > 0 &&
                          subOwnerProfilesByIdData.map((a) => ({
                            label:
                              a?.registerNo +
                              " - " +
                              a[`text${currentLanguage === "en" ? "" : "Ar"}`],
                            value: a.value,
                          }))),
                      ]}
                      value={profile}
                      onChange={(e) => {
                        handelSelectedAccountChange(e);
                      }}
                      placeholder={localStorage.getItem("EAHSUserFullName")}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      styles={customStyles}
                      key={"EAHSUserFullName"}
                    />
                  </>
                ) : (
                  <label className="capatalize_text">
                    {getSelectedAccountName()?.registerNo +
                      " - " +
                      getSelectedAccountName()[
                      `text${currentLanguage === "en" ? "" : "Ar"}`
                      ]}
                  </label>
                )}
              </div>
            )}

          {/* Covering Certificate details */}
          <EahsForm title={translate("web.coveringCertificateDetails", "Covering Certificate Details")}>
            <div className="row mx-0 col-md-12 col-sm-12 rtl">
              <div className="col-md-4 col-sm-12 mt-3">
                <label>
                  {translate("web.coveringCertificate", "Covering Certificate")}
                  <i className="colorred"> *</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.coveringCertificate", "Covering Certificate"),
                      value: "",
                    },
                    ...(!!ownerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownData &&
                      ownerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownData.map((a) => ({
                        label: a.referenceNumber + " / " + translate("web.mare", "Mare:") + a.mare + " / " + translate("web.stallion", "Stallion:") + a.stallion,
                        value: a.id,
                      }))),
                  ]}
                  value={formModel.coveringCertificateDrop}
                  onChange={(e) => handleFormChange(e, "coveringCertificate")}
                  placeholder={translate("web.coveringCertificate", "Covering Certificate")}
                  name={"coveringCertificate"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  autoFocus={true}
                  key={"horse"}
                  isDisabled={handelDisabledButton()}
                />

                {errorCoveringCertificate && (
                  <span style={{ color: "red" }}>
                    {translate("web.requiredField", "Required Field")}
                  </span>
                )}
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.mareHorse", "Mare Horse")}
                  name={"mareHorse"}
                  type={"text"}
                  disabled={true}
                  value={formModel.mareHorse}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.mareOwner", "Mare Owner")}
                  name={"mareOwner"}
                  type={"text"}
                  disabled={true}
                  value={formModel.mareOwner}
                />
              </div>
              {/* <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.breeder", "Breeder")}
                  name={"breeder"}
                  type={"text"}
                  disabled={true}
                  value={formModel.breeder}
                />
              </div> */}
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.stallionHorse", "Stallion Horse")}
                  name={"stallionHorse"}
                  type={"text"}
                  disabled={true}
                  value={
                    formModel.stallionHorse
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.stallionOwner", "stallion Owner")}
                  name={"stallionOwner"}
                  type={"text"}
                  disabled={true}
                  value={formModel.stallionOwner}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.coveringDate", "CoveringDate")}
                  name={"coveringDate"}
                  type={"text"}
                  disabled={true}
                  //value={formModel.coveringDate !== "" ? dayjs(formModel.coveringDate, "YYYY/MM/DD").format("DD/MM/YYYY") : formModel.coveringDate}
                  value={formModel.coveringDate}
                />
              </div>
            </div>
          </EahsForm>

          {/* Horse details */}
          <EahsForm title={translate("web.horseDetails", "Horse Details")}>
            <div className="col-md-6 col-sm-12">
              <NetaqInput
                label={translate("web.name", "Name") + " (EN)"}
                name={"nameEn"}
                type={"text"}
                onChange={(e) => handleFormChange(e, "nameEn")}
                value={formModel.nameEn}
                required={true}
                maxchars={27}
                disabled={handelDisabledButton()}
              />
              {/* <span style={{ color: "red" }}>
                {
                  translate(
                    "web.nameShouldNotExceed27LettersIncludingSpaces",
                    "*Name should not exceed 27 letters including spaces*"
                  )
                }
              </span>
              <br /> */}
              <span className="Gray_color">
                {
                  translate(
                    "web.matchingArabicEnglishName",
                    "*Matching Arabic English Name*"
                  )
                }
              </span>
              {errorNameEn && (
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              )}
            </div>

            <div className="col-md-6 col-sm-12">
              <NetaqInput
                label={translate("web.name", "Name") + " (AR)"}
                name={"nameAr"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.nameAr}
                required={true}
                maxchars={27}
                disabled={handelDisabledButton()}
              />
              <span className="Gray_color">
                {
                  translate(
                    "web.matchingArabicEnglishName",
                    "*Matching Arabic English Name*"
                  )
                }
              </span>
              {errorNameAr && (
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              )}
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                htmlTags={
                  formModel.birthDate ?
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      {
                        dayjs(formModel.birthDate).format("DD/MM/YYYY")
                      }
                    </p> :
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      DD/MM/YYYY
                    </p>
                }
                name={"birthDate"}
                type={"normalDateService"}
                label={translate("web.birthDate", "Birth Date")}
                onChange={handleFormChange}
                value={formModel.birthDate}
                required={true}
                //minDate={dayjs(formModel.coveringDate).format("YYYY-MM-DD")}
                minDate={handelMinDateForBirthDate()}
                //maxDate={dayjs().format("YYYY-MM-DD")}
                maxDate={handelMaxDateForBirthDate()}
                disabled={handelDisabledButton()}
              />
              {errorBirthDate && (
                <span style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </span>
              )}
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <label>
                {translate("web.color", "Color")}
                <i className="colorred"> *</i>
              </label>
              <Select
                options={[
                  {
                    label: translate("web.color", "Color"),
                    value: null,
                  },
                  ...(!!horseColorsData &&
                    horseColorsData.map((a) => ({
                      label: a.nameEn,
                      value: a.id,
                    }))),
                ]}
                value={formModel.colorDrop}
                onChange={(e) => handleFormChange(e, "color")}
                placeholder={translate("web.color", "Color")}
                name={"color"}
                isSearchable
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
                key={"color"}
                isDisabled={handelDisabledButton()}
              />
              {errorColor && (
                <span style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </span>
              )}
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <label>
                {translate("web.sex", "Sex")}
                <i className="colorred"> *</i>
              </label>
              <div className="AuctionFilterradioServiceRequestContainer mt-3 row">
                <div style={{ maxWidth: 150 }}>
                  <NetaqInput
                    name={"sex"}
                    type={"radioServiceRequest"}
                    value={"Stallion"}
                    label={`${translate("web.stallion", "Stallion")}-`}
                    onChange={(e) => handleFormChange(e, "sex")}
                    checked={formModel.sex === "Stallion"}
                    disabled={handelDisabledButton()}
                  />
                </div>
                <div style={{ maxWidth: 150 }}>
                  <NetaqInput
                    name={"sex"}
                    type={"radioServiceRequest"}
                    value={"Mare"}
                    label={`${translate("web.mare", "Mare")}-`}
                    onChange={(e) => handleFormChange(e, "sex")}
                    checked={
                      formModel.sex === "Mare"
                    }
                    disabled={handelDisabledButton()}
                  />
                </div>
                <div style={{ maxWidth: 150 }}>
                  <NetaqInput
                    name={"sex"}
                    type={"radioServiceRequest"}
                    value={"Gelding"}
                    label={`${translate("web.gelding", "Gelding")}-`}
                    onChange={(e) => handleFormChange(e, "sex")}
                    checked={formModel.sex === "Gelding"}
                    disabled={handelDisabledButton()}
                  />
                </div>
              </div>
            </div>
          </EahsForm>

          {/* horse Location title */}
          <EahsForm title={translate("web.horseLocation", "Horse Location")}>
            <div className="col-md-6 col-sm-12 rtl service">
              <NetaqInput
                type={"select"}
                label={translate("web.emirate", "Emirate")}
                placeholder={translate("web.emirate", "emirate")}
                name={"emirate"}
                listSelect={emiratelistoptions}
                value={formModel.emirateDrop}
                onChange={(e) => handleFormChange(e, "emirate")}
                required={true}
                disabled={handelDisabledButton()}
              />
              {errorEmirate && (
                <span style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </span>
              )}
            </div>

            <div className="col-md-6 col-sm-12 rtl service">
              <NetaqInput
                type={"select"}
                label={translate("web.area", "Area")}
                placeholder={translate("web.area", "Area")}
                name={"area"}
                listSelect={regionsList}
                value={formModel.areaDrop}
                onChange={(evt) => handleFormChange(evt, "area")}
                required={true}
                disabled={handelDisabledButton()}
              />
              {errorArea && (
                <span style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </span>
              )}
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.gpsLocationLink", "GPS Location Link")}
                name={"gpsLocationLink"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.gpsLocationLink}
                disabled={true}
              />
            </div>

            <div className="col-md-12 col-sm-12 rtl service mt-3">
              {/* <MapWithCurrentLocation
                status={statusGeoLocation}
                location={geoLocation}
                setLocation={setGeoLocation}
                title={translate("web.locationOnMap", "Location On Map")}
                zoom={3}
                required={true}
                isInteractive={((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                  false
                  : true} /> */}

              <iframe
                id="Imap"
                src={`https://maps.atlasapps.net/map.aspx?set=yes&location=${latitude},${longitude}&key=${lockey}&MapsKey=AIzaSyB26sBNX-V2SgrBTaWpepPfXvttfiIb2YM`}
                width="100%"
                height="350"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
              ></iframe>
              <div className="col-md-3 mt-3">
                <ActionButton
                  className="btn-info text-white w-50"
                  type={"bootstrapbtn"}
                  text={`<i class="fa-solid fa-location-dot fa-lg marginrightleft-10"></i>                   
                          ${translate("web.selectLocation", "Select Location")}
                          `}
                  margin={"0"}
                  width={"100%"}
                  onClick={
                    selectLocation
                  }
                  disabled={handelDisabledButton()}
                />
              </div>
              {errorLocationOnMap && (
                <span style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </span>
              )}
              {/* <NetaqInput
                label={translate("web.locationOnMap", "Location On Map")}
                name={"locationOnMap"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.locationOnMap}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              /> */}
            </div>
          </EahsForm>

          {/* How to receive a horse passport */}
          <EahsForm title={translate(
            "web.receiveHorsePassport",
            "How to receive a horse passport"
          )}>
            <div className="AuctionFilterRadioContainer">
              <div style={{ margin: "auto 0" }}>
                <NetaqInput
                  name={"receiveType"}
                  key={"Registration"}
                  type={"radioServiceRequest"}
                  value={"Registration"}
                  label={translate(
                    "web.registrationOffice",
                    "Received From EAHS Office"
                  )}
                  onChange={(e) => handleFormChange(e, "receiveType")}
                  checked={
                    formModel.isRegistration
                  }
                  disabled={handelDisabledButton()}
                />
              </div>
              <div style={{ margin: "auto 30px" }}>
                <NetaqInput
                  name={"receiveType"}
                  key={"Post"}
                  type={"radioServiceRequest"}
                  value={"Post"}
                  label={translate("web.postOffice", "Post Delivery")}
                  onChange={(e) => handleFormChange(e, "receiveType")}
                  checked={
                    !formModel.isRegistration
                  }
                  disabled={handelDisabledButton()}
                />
              </div>
            </div>
            {
              !formModel.isRegistration &&
              <>
                <div className="col-md-6 col-sm-12 mt-3">
                  <NetaqInput
                    label={translate("web.address", "Address")}
                    name={"address"}
                    type={"text"}
                    placeholder={translate("web.address", "Address")}
                    onChange={handleFormChange}
                    value={formModel.address}
                    required={true}
                    disabled={handelDisabledButton()}
                  />
                  {errorAddress && (
                    <span style={{ color: "red" }}>
                      {translate("web.requiredField", "Required Field")}
                    </span>
                  )}
                </div>
                <div className="col-md-6 col-sm-12 mt-3">
                  <NetaqInput
                    type={'phone'}
                    value={formModel.mobile}
                    label={translate("web.smsPhoneNo", "Mobile No")}
                    name={'mobile'}
                    placeholder={'971xxxxxxxxx'}
                    required={true}
                    onChange={setPhoneNumber}
                    setIsPhoneNumberValid={setIsPhoneNumberValid}
                    disabled={handelDisabledButton()}
                  />
                  {!isPhoneNumberValid &&
                    <div className='text-danger'>
                      {translate('web.phoneNumberIsNotValid', 'Phone Number Is Not Valid')}
                    </div>
                  }

                  {errorMobile && (
                    <span style={{ color: "red" }}>
                      {translate("web.requiredField", "Required Field")}
                    </span>
                  )}
                </div>
              </>
            }
          </EahsForm>

          {/* Comments */}
          {handelShowGeneralComments() && (
            <EahsForm title={translate("web.comments", "Comments")}>
              {!!formModel.comment && (
                <div className="col-md-6 col-sm-12">
                  <NetaqInput
                    label={translate("web.eahsComments", "EAHS Comments")}
                    name={"adminComment"}
                    type={"textarea"}
                    maxchars={1150}
                    value={formModel.comment}
                    disabled={true}
                  />
                </div>
              )}

              {handelShowOwnerComment() && (
                <div className="col-md-6 col-sm-12">
                  <NetaqInput
                    label={translate("web.ownerComment", "Owner Comment")}
                    name={"ownerComment"}
                    type={"textarea"}
                    maxchars={1150}
                    value={formModel.ownerComment}
                    onChange={handleFormChange}
                  />
                </div>
              )}
            </EahsForm>
          )}

          {/* terms*/}
          <EahsForm title={translate("web.terms", "Terms")}>
            {handelShowoption2() && (
              <div className="col-md-12 col-sm-12 rtl p0">
                <NetaqInput
                  type={"checkbox"}
                  label={translate(
                    "web.registeringNewArabianHorseFoalNote1",
                    "Registering New Arabian Horse Foal 1"
                  ) + " ( " + getServiceDefinitionByIdData.penalty + translate(
                    "web.delayMustBePaid",
                    "Delay Must Be Paid"
                  )}
                  name="option2"
                  checked={checkedItems.option2}
                  onChange={handleCheckboxChange}
                  disabled={formModel.eventName === "" ? false : true}
                />
              </div>
            )}
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.ServiceGeneralNote",
                  "Service General Note"
                )}
                name="option1"
                checked={checkedItems.option1}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
          </EahsForm>

          {/* buttons */}
          <div className="col-md-12 col-sm-12 mt-15 text-end">
            {/* draft/pay/submit */}
            {handelShowGeneralButtons() && (
              <div className="row mx-0 justify-content-lg-end justify-content-sm-start">

                {/* draft */}
                {handelShowDraftButton() &&
                  <div className="col-lg-2 my-1">
                    <ActionButton
                      type={"bootstrapbtn"}
                      className="btn btn-outline-danger mx-auto w-100"
                      label={translate("web.draft", "Draft")}
                      text={`<i class="fa fa-save fa-lg marginrightleft-10"></i>                   
                          ${translate("web.draft", "Draft")}
                          `}
                      margin={"auto 10px"}
                      width={"100%"}
                      onClick={() => handelFormSubmit("Draft", "")}
                    />
                  </div>
                }

                {/* pay later */}
                {handelShowPayLaterButton() && (
                  <div className="col-lg-3 my-1">
                    <ActionButton
                      className="btn-info text-white mx-auto"
                      type={"bootstrapbtn"}
                      text={`<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>                   
                          ${translate("web.approveAndPayLater", "Approve And Pay Later")}
                          `}
                      margin={"0"}
                      width={"100%"}
                      onClick={() =>
                        handelFormSubmit(formModel.eventName === "DNANegative" ? "Submit" : "Draft", "no")
                      }
                    />
                  </div>
                )}

                {/* pay */}
                {handelShowPayButton() &&
                  <div className="col-lg-3 my-1">
                    <ActionButton
                      className={"btn-success requestBtn mx-0 mx-auto w-100"}
                      type={"requests"}
                      label={translate("web.approvePay", "Approve And Pay")}
                      text={
                        <>
                          <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                          {translate("web.approvePay", "Approve And Pay")}
                        </>
                      }
                      margin={"0"}
                      width={"100%"}
                      onClick={() =>
                        handelFormSubmit(formModel.eventName === "DNANegative" ? "Submit" : "Draft", "yes")
                      }
                    />
                  </div>
                }

                {/* submit */}
                {handelShowSubmitButton() && (
                  <div className="col-lg-2 my-sm-1">
                    <ActionButton
                      type={"requests"}
                      className="btn-success text-white requestBtn w-100 mx-auto"
                      label={translate("web.submit", "Submit")}
                      text={
                        <>
                          <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                          {translate("web.submit", "Submit")}
                        </>
                      }
                      margin={"auto 25px"}
                      width={"100%"}
                      onClick={() =>
                        handelFormSubmit("Submit", "")
                      }
                    />
                  </div>
                )}
              </div>
            )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
