import { createSlice } from "@reduxjs/toolkit";
import {
  FetchApproveRejectRequestCoveringCertificatethunk,
  FetchApproveRejectRequestEmbryoTransferRightPurchaceAgreementthunk,
  FetchApproveRejectRequestthunk,
  FetchCancelRequestthunk,
  FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk,
  FetchConfirmImportDatethunk,
  FetchCreateCoveringCertificateServiceRequestthunk,
  FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk,
  FetchCreateEmbryoTransferRightPurchaceAgreementServiceRequestthunk,
  FetchCreateOrEditAddRacingNameForImportedArabianHorseServiceRequestthunk,
  FetchCreateOrEditDeclarationOfArabianHorseDeathServiceRequestthunk,
  FetchCreateOrEditHorseOwnershipCertificatesServiceRequestthunk,
  FetchCreateOrEditImportHorseFrozenSemenServiceRequestthunk,
  FetchCreateOrEditInactiveHorseRegistrationRequestServiceRequestthunk,
  FetchCreateOrEditIssuingHorsePassportDuplicateDamagedServiceRequestthunk,
  FetchCreateOrEditStudRegistrationCertificatesServiceRequestthunk,
  FetchCreateOrEditUpdateArabianHorseDataServiceRequestthunk,
  FetchCreateOwnerShipTransferServicesRequeststhunk,
  FetchCreateRegisteringNewArabianHorseFoalServiceRequestthunk,
  FetchDeleteOwnerShipTransferServicesRequeststhunk,
  FetchGetAllServicesRequestsOwnerToApprovethunk,
  FetchGetAllServicesRequeststhunk,
  FetchGetDocumentTypesByServiceIdthunk,
  FetchGetFilebase64thunk,
  FetchGetFilethunk,
  FetchGetLoacationNameByLatLangthunk,
  FetchGetRegisteredHorsesthunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
  FetchGetShippingAgentsthunk,
  FetchGetShowJournalistRequestTermsDatathunk,
  FetchIsHaseRequestEmbryoOocyteTransferPermitthunk,
  FetchOwnerCoveringCertificatesDropDownthunk,
  FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownthunk,
  FetchSubmitJournalistRequestthunk,
  FetchUpdateCoveringCertificateServiceRequestthunk,
  FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk,
  FetchUpdateEmbryoTransferRightPurchaceAgreementServiceRequestthunk,
  FetchUpdateOwnerShipTransferServicesRequeststhunk,
  FetchUpdateRegisteringNewArabianHorseFoalServiceRequestthunk,
} from "./ServicesRequestsAction";

const initialState = {
  createOwnerShipTransferServicesRequestsData: {},
  createCoveringCertificateServiceRequestData: {},
  createEmbryoOocyteTransferPermitServiceRequestData: {},
  createEmbryoTransferRightPurchaceAgreementServiceRequestData: {},
  createRegisteringNewArabianHorseFoalServiceRequestData: {},
  createOrEditHorseOwnershipCertificatesServiceRequestData: {},
  createOrEditStudRegistrationCertificatesServiceRequestData: {},
  createOrEditUpdateArabianHorseDataServiceRequestData: {},
  createOrEditDeclarationOfArabianHorseDeathServiceRequestData: {},
  createOrEditInactiveHorseRegistrationRequestData: {},
  createOrEditAddRacingNameForImportedArabianHorseData: {},
  createOrEditIssuingHorsePassportDuplicateDamagedData: {},
  importHorseFrozenSemenData: {},
  submitJournalistRequestData: {},
  getShowJournalistRequestTermsDataData: {},
  updateOwnerShipTransferServicesRequestsData: {},
  updateCoveringCertificateServiceRequestData: {},
  updateEmbryoOocyteTransferPermitServiceRequestData: {},
  updateEmbryoTransferRightPurchaceAgreementServiceRequestData: {},
  updateRegisteringNewArabianHorseFoalServiceRequestData: {},
  getAllServicesRequestsData: [],
  getServicesRequestByIdData: {},
  getAllServicesRequestsOwnerToApproveData: [],
  getServiceDefinitionByIdData: {},
  servicesRequestsLoading: false,
  servicesRequestsError: null,
  toasterMessageServicesRequests: null,
  deleteOwnerShipTransferServicesRequestsData: null,
  approveRejectRequestData: null,
  approveRejectRequestCoveringCertificateData: null,
  approveRejectRequestEmbryoTransferRightPurchaceAgreementData: null,
  checkLastChildrenOfHorseMareRegLessThanOneWeekData: {},
  isHaseRequestEmbryoOocyteTransferPermitData: {},
  getFileData: null,
  getFilebase64Data: null,
  getDocumentTypesByServiceIdData: [],
  getRegisteredHorsesData: [],
  cancelRequestData: null,
  ownerCoveringCertificatesDropDownData: [],
  ownerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownData: [],
  getLoacationNameByLatLangData: "",
  getShippingAgentsData: [],
};

const ServicesRequestsSlice = createSlice({
  name: "ServicesRequests",
  initialState,
  reducers: {
    resetToasterMessageServicesRequests: (state) => {
      state.toasterMessageServicesRequests = null;
    },
    resetGetServicesRequestByIdData: (state) => {
      state.getServicesRequestByIdData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      FetchCreateOwnerShipTransferServicesRequeststhunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateOwnerShipTransferServicesRequeststhunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createOwnerShipTransferServicesRequestsData =
          action.payload.result;
        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateOwnerShipTransferServicesRequeststhunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createOwnerShipTransferServicesRequestsData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchGetAllServicesRequeststhunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetAllServicesRequeststhunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getAllServicesRequestsData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetAllServicesRequeststhunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getAllServicesRequestsData = [];
      }
    );

    builder.addCase(
      FetchGetServicesRequestByIdthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetServicesRequestByIdthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getServicesRequestByIdData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetServicesRequestByIdthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getServicesRequestByIdData = {};
      }
    );

    builder.addCase(
      FetchDeleteOwnerShipTransferServicesRequeststhunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchDeleteOwnerShipTransferServicesRequeststhunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.deleteOwnerShipTransferServicesRequestsData = action.payload;
        state.toasterMessageServicesRequests = "save";
      }
    );
    builder.addCase(
      FetchDeleteOwnerShipTransferServicesRequeststhunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.deleteOwnerShipTransferServicesRequestsData = null;
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchGetAllServicesRequestsOwnerToApprovethunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetAllServicesRequestsOwnerToApprovethunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getAllServicesRequestsOwnerToApproveData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetAllServicesRequestsOwnerToApprovethunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getAllServicesRequestsOwnerToApproveData = {};
      }
    );

    builder.addCase(
      FetchUpdateOwnerShipTransferServicesRequeststhunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchUpdateOwnerShipTransferServicesRequeststhunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.updateOwnerShipTransferServicesRequestsData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchUpdateOwnerShipTransferServicesRequeststhunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.updateOwnerShipTransferServicesRequestsData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(FetchApproveRejectRequestthunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
      state.toasterMessageServicesRequests = null;
    });
    builder.addCase(
      FetchApproveRejectRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.approveRejectRequestData = action.payload;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchApproveRejectRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.approveRejectRequestData = null;
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchGetServiceDefinitionByIdthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetServiceDefinitionByIdthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getServiceDefinitionByIdData = action.payload;
      }
    );
    builder.addCase(
      FetchGetServiceDefinitionByIdthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getServiceDefinitionByIdData = {};
      }
    );

    builder.addCase(FetchGetFilethunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
    });
    builder.addCase(FetchGetFilethunk.fulfilled, (state, action) => {
      state.servicesRequestsLoading = false;
      state.getFileData = action.payload;
    });
    builder.addCase(FetchGetFilethunk.rejected, (state, action) => {
      state.servicesRequestsLoading = false;
      state.servicesRequestsError = action.payload;
      state.getFileData = null;
    });

    builder.addCase(FetchGetFilebase64thunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
    });
    builder.addCase(FetchGetFilebase64thunk.fulfilled, (state, action) => {
      state.servicesRequestsLoading = false;
      state.getFilebase64Data = action.payload;
    });
    builder.addCase(FetchGetFilebase64thunk.rejected, (state, action) => {
      state.servicesRequestsLoading = false;
      state.servicesRequestsError = action.payload;
      state.getFilebase64Data = null;
    });

    builder.addCase(
      FetchGetDocumentTypesByServiceIdthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetDocumentTypesByServiceIdthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getDocumentTypesByServiceIdData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetDocumentTypesByServiceIdthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getDocumentTypesByServiceIdData = {};
      }
    );

    builder.addCase(
      FetchGetRegisteredHorsesthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetRegisteredHorsesthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getRegisteredHorsesData =
          action.payload.horsesList;
      }
    );
    builder.addCase(
      FetchGetRegisteredHorsesthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getRegisteredHorsesData = [];
      }
    );

    //covering certificate
    builder.addCase(
      FetchCreateCoveringCertificateServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateCoveringCertificateServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;

        state.createCoveringCertificateServiceRequestData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateCoveringCertificateServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createCoveringCertificateServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchUpdateCoveringCertificateServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchUpdateCoveringCertificateServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.updateCoveringCertificateServiceRequestData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchUpdateCoveringCertificateServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.updateCoveringCertificateServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(FetchApproveRejectRequestCoveringCertificatethunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
      state.toasterMessageServicesRequests = null;
    });
    builder.addCase(
      FetchApproveRejectRequestCoveringCertificatethunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.approveRejectRequestCoveringCertificateData = action.payload;
        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchApproveRejectRequestCoveringCertificatethunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.approveRejectRequestCoveringCertificateData = null;
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchCancelRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCancelRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.cancelRequestData = action.payload;
        state.toasterMessageServicesRequests = "save";
      }
    );
    builder.addCase(
      FetchCancelRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.cancelRequestData = null;
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
    });
    builder.addCase(
      FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.checkLastChildrenOfHorseMareRegLessThanOneWeekData = action.payload?.result;
      }
    );
    builder.addCase(
      FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.checkLastChildrenOfHorseMareRegLessThanOneWeekData = {};
      }
    );

    //Embryo Oocyte Transfer Permit
    builder.addCase(
      FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createEmbryoOocyteTransferPermitServiceRequestData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createEmbryoOocyteTransferPermitServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createEmbryoOocyteTransferPermitServiceRequestData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createEmbryoOocyteTransferPermitServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(FetchIsHaseRequestEmbryoOocyteTransferPermitthunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
    });
    builder.addCase(
      FetchIsHaseRequestEmbryoOocyteTransferPermitthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.isHaseRequestEmbryoOocyteTransferPermitData = action.payload;
      }
    );
    builder.addCase(
      FetchIsHaseRequestEmbryoOocyteTransferPermitthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.isHaseRequestEmbryoOocyteTransferPermitData = {};
      }
    );

    //Embryo Transfer Right Purchace Agreement
    builder.addCase(
      FetchCreateEmbryoTransferRightPurchaceAgreementServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateEmbryoTransferRightPurchaceAgreementServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;

        state.createEmbryoTransferRightPurchaceAgreementServiceRequestData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateEmbryoTransferRightPurchaceAgreementServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createEmbryoTransferRightPurchaceAgreementServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchUpdateEmbryoTransferRightPurchaceAgreementServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchUpdateEmbryoTransferRightPurchaceAgreementServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;

        state.updateEmbryoTransferRightPurchaceAgreementServiceRequestData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchUpdateEmbryoTransferRightPurchaceAgreementServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.updateEmbryoTransferRightPurchaceAgreementServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(FetchApproveRejectRequestEmbryoTransferRightPurchaceAgreementthunk.pending, (state, action) => {
      state.servicesRequestsLoading = true;
      state.toasterMessageServicesRequests = null;
    });
    builder.addCase(
      FetchApproveRejectRequestEmbryoTransferRightPurchaceAgreementthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.approveRejectRequestEmbryoTransferRightPurchaceAgreementData = action.payload;
        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchApproveRejectRequestEmbryoTransferRightPurchaceAgreementthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.approveRejectRequestEmbryoTransferRightPurchaceAgreementData = null;
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchOwnerCoveringCertificatesDropDownthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchOwnerCoveringCertificatesDropDownthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;

        state.ownerCoveringCertificatesDropDownData = action.payload.result;
      }
    );
    builder.addCase(
      FetchOwnerCoveringCertificatesDropDownthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.ownerCoveringCertificatesDropDownData = [];
        state.toasterMessageServicesRequests = null;
      }
    );

    //Registering New Arabian Horse Foal
    builder.addCase(
      FetchCreateRegisteringNewArabianHorseFoalServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateRegisteringNewArabianHorseFoalServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createRegisteringNewArabianHorseFoalServiceRequestData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateRegisteringNewArabianHorseFoalServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createRegisteringNewArabianHorseFoalData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchUpdateRegisteringNewArabianHorseFoalServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchUpdateRegisteringNewArabianHorseFoalServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.updateRegisteringNewArabianHorseFoalServiceRequestData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchUpdateRegisteringNewArabianHorseFoalServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.updateRegisteringNewArabianHorseFoalData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;

        state.ownerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownData = action.payload.result;
      }
    );
    builder.addCase(
      FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.ownerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownData = [];
        state.toasterMessageServicesRequests = null;
      }
    );

    //Horse Ownership Certificates
    builder.addCase(
      FetchCreateOrEditHorseOwnershipCertificatesServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateOrEditHorseOwnershipCertificatesServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createOrEditHorseOwnershipCertificatesServiceRequestData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateOrEditHorseOwnershipCertificatesServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createOrEditHorseOwnershipCertificatesServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    //Stud Registration Certificates
    builder.addCase(
      FetchCreateOrEditStudRegistrationCertificatesServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateOrEditStudRegistrationCertificatesServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createOrEditStudRegistrationCertificatesServiceRequestData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateOrEditStudRegistrationCertificatesServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createOrEditStudRegistrationCertificatesServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    builder.addCase(
      FetchGetLoacationNameByLatLangthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchGetLoacationNameByLatLangthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getLoacationNameByLatLangData = action.payload;
      }
    );
    builder.addCase(
      FetchGetLoacationNameByLatLangthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getLoacationNameByLatLangData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    //Update Arabian Horse Data
    builder.addCase(
      FetchCreateOrEditUpdateArabianHorseDataServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateOrEditUpdateArabianHorseDataServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createOrEditUpdateArabianHorseDataServiceRequestData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateOrEditUpdateArabianHorseDataServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createOrEditUpdateArabianHorseDataServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    //Submit Journalist Request
    builder.addCase(
      FetchSubmitJournalistRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchSubmitJournalistRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.submitJournalistRequestData = action.payload.result;

        if (action.payload.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.responseText;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchSubmitJournalistRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.submitJournalistRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    //Get Show Journalist Request Terms Data
    builder.addCase(
      FetchGetShowJournalistRequestTermsDatathunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchGetShowJournalistRequestTermsDatathunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getShowJournalistRequestTermsDataData = action.payload.result;

        if (action.payload.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.responseText;
        }

      }
    );
    builder.addCase(
      FetchGetShowJournalistRequestTermsDatathunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getShowJournalistRequestTermsDataData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    //Declaration Of Arabian Horse Death
    builder.addCase(
      FetchCreateOrEditDeclarationOfArabianHorseDeathServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateOrEditDeclarationOfArabianHorseDeathServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createOrEditDeclarationOfArabianHorseDeathServiceRequestData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateOrEditDeclarationOfArabianHorseDeathServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createOrEditDeclarationOfArabianHorseDeathServiceRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    //Inactive Horse Registration Request
    builder.addCase(
      FetchCreateOrEditInactiveHorseRegistrationRequestServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateOrEditInactiveHorseRegistrationRequestServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createOrEditInactiveHorseRegistrationRequestData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateOrEditInactiveHorseRegistrationRequestServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createOrEditInactiveHorseRegistrationRequestData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    //Add Racing Name For Imported Arabian Horse
    builder.addCase(
      FetchCreateOrEditAddRacingNameForImportedArabianHorseServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateOrEditAddRacingNameForImportedArabianHorseServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createOrEditAddRacingNameForImportedArabianHorseData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateOrEditAddRacingNameForImportedArabianHorseServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createOrEditAddRacingNameForImportedArabianHorseData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    //IssuingHorsePassportDuplicateDamaged
    builder.addCase(
      FetchCreateOrEditIssuingHorsePassportDuplicateDamagedServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateOrEditIssuingHorsePassportDuplicateDamagedServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.createOrEditIssuingHorsePassportDuplicateDamagedData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateOrEditIssuingHorsePassportDuplicateDamagedServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.createOrEditIssuingHorsePassportDuplicateDamagedData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    //importHorseFrozenSemen
    builder.addCase(
      FetchCreateOrEditImportHorseFrozenSemenServiceRequestthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchCreateOrEditImportHorseFrozenSemenServiceRequestthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.importHorseFrozenSemenData = action.payload.result;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchCreateOrEditImportHorseFrozenSemenServiceRequestthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.importHorseFrozenSemenData = {};
        state.toasterMessageServicesRequests = null;
      }
    );

    //ShippingAgents
    builder.addCase(
      FetchGetShippingAgentsthunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
      }
    );
    builder.addCase(
      FetchGetShippingAgentsthunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.getShippingAgentsData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetShippingAgentsthunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.getShippingAgentsData = [];
      }
    );

    //ConfirmImportDate
    builder.addCase(
      FetchConfirmImportDatethunk.pending,
      (state, action) => {
        state.servicesRequestsLoading = true;
        state.toasterMessageServicesRequests = null;
      }
    );
    builder.addCase(
      FetchConfirmImportDatethunk.fulfilled,
      (state, action) => {
        state.servicesRequestsLoading = false;

        if (action.payload.status.responseCode === "100") {
          state.toasterMessageServicesRequests = action.payload.status.detailedResponseCode;
        }
        else {
          state.toasterMessageServicesRequests = "save";
        }
      }
    );
    builder.addCase(
      FetchConfirmImportDatethunk.rejected,
      (state, action) => {
        state.servicesRequestsLoading = false;
        state.servicesRequestsError = action.payload;
        state.toasterMessageServicesRequests = null;
      }
    );
  },
});

export const {
  resetToasterMessageServicesRequests,
  resetGetServicesRequestByIdData,
} = ServicesRequestsSlice.actions;
export default ServicesRequestsSlice.reducer;
