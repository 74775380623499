import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchCreateOrEditAddRacingNameForImportedArabianHorseServiceRequestthunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetGetServicesRequestByIdData,
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import dayjs from "dayjs";
import { FetchOwnerHorsesDropDownthunk } from "../Redux/OwnerProfile/OwnerProfileAction";
import EahsForm from "../components/EahsForm";

export default function RequestToAddARacingNameForAnImportedArabianHorse() {
  //env 
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  //const UMBRACOURL = process.env.REACT_APP_Local_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
    createOrEditAddRacingNameForImportedArabianHorseData,
  } = useSelector((state) => state.servicesRequestsSlice);

  const { ownerHorsesDropDownData } = useSelector(
    (state) => state.ownerProfileSlice
  );

  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

  const dispatch = useDispatch();

  const { translate } = useTranslate();

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    eventName: "",
    comment: "",
    referenceNumber: "",
    requestsType: "",
    paymentStatus: "",

    horse: "",
    horseDrop: {
      label: translate("web.horse", "Horse"),
      value: null,
    },

    gender: "",
    color: "",
    birthDate: "",
    origin: "",
    breeder: "",
    sire: "",
    dam: "",

    racingNameEn: "",
    racingNameAr: "",

    ownerComment: "",
  });

  const [errorHorse, setErrorHorse] = useState(false);
  const [errorRacingNameEn, setErrorRacingNameEn] = useState(false);
  const [errorRacingNameAr, setErrorRacingNameAr] = useState(false);

  const _isOnlinePayment = useRef("");

  const [checkedItems, setCheckedItems] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
  });

  const [profile, setProfile] = useState({
    label:
      !!userProfile &&
      userProfile?.ownerRegisterNo +
      " - " +
      userProfile[`fullName${currentLanguage === "en" ? "" : "Ar"}`],
    value: localStorage.getItem("EAHSUserID"),
  });

  const subAccountId = localStorage.getItem("currentSubAccountId");

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  //function

  const handleFormChange = (e, input) => {
    if (input === "horse") {
      let horse = ownerHorsesDropDownData.filter((item) => item.id == e.value);

      if (horse.length > 0) {
        setFormModel({
          ...formModel,
          horse: e.value,
          horseDrop: e,
          birthDate: dayjs(horse[0]?.birthDate ?? "").format('DD/MM/YYYY'),
          color: horse[0]?.color ?? "",
          gender: horse[0]?.gender ?? "",
          sire: horse[0].sire,
          dam: horse[0].dam,
          origin: horse[0].origin,
          breeder: horse[0].breeder,
        });
      }
      // else if (!!input && input === "racingNameEn") {

      //   // Allow only letters and numbers
      //   const regex = /^[a-zA-Z0-9]*$/;

      //   if (regex.test(e.target.value)) {
      //     setFormModel({
      //       ...formModel,
      //       nameEn: e.target.value.toUpperCase(),
      //     });
      //   }

      // } 
      else {
        setFormModel({
          ...formModel,
          horse: "",
          horseDrop: {
            label: translate("web.horse", "Horse"),
            value: null,
          },
          birthDate: "",
          color: "",
          gender: "",
          origin: "",
          breeder: "",
          sire: "",
          dam: "",
        });
      }
    }
    else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value.toUpperCase(),
      });
    }
  };

  const validInputs = () => {
    let valid = true;

    if (
      formModel.horse === ""
    ) {
      setErrorHorse(true);
      valid = false;
    }
    else {
      setErrorHorse(false);
    }

    if (
      formModel.racingNameEn === ""
    ) {
      setErrorRacingNameEn(true);
      valid = false;
    }
    else {
      setErrorRacingNameEn(false);
    }

    if (
      formModel.racingNameAr === ""
    ) {
      setErrorRacingNameAr(true);
      valid = false;
    }
    else {
      setErrorRacingNameAr(false);
    }

    //term
    if (
      !checkedItems.option1
      || !checkedItems.option2
      || !checkedItems.option3
      || !checkedItems.option4
    ) {
      toast.error(
        translate("web.pleaseCheckAllTerms", "Please Check All Terms")
      );
      valid = false;
    }

    return valid;
  }

  const handelFormSubmit = (status, isOnlinePayment) => {
    if (getServiceDefinitionByIdData.serviceFees > 0 && formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice" && formModel.eventName !== "SentBack") {
      const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

      const encodeURI = encodeURIComponent(returnUrl);

      window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=18&userId=${localStorage.getItem("EAHSUserID")}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment}&lang=${currentLanguage}`;
    }
    else {
      if (
        validInputs()
      ) {
        _isOnlinePayment.current = isOnlinePayment;

        let params = {
          id: id,
          ownerId: getUserID(),
          horse: formModel.horse,
          racingNameEn: formModel.racingNameEn,
          racingNameAr: formModel.racingNameAr,
          status: status,
          ownerComment: formModel.ownerComment,
        };

        dispatch(FetchCreateOrEditAddRacingNameForImportedArabianHorseServiceRequestthunk(params));
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    let params = {
      ownerId: element.value,
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    setProfile(element);

    setFormModel({
      ...formModel,
      horse: "",
      horseDrop: {
        label: translate("web.horse", "Horse"),
        value: null,
      },

      birthDate: "",
      color: "",
      gender: "",
      origin: "",
      breeder: "",
      sire: "",
      dam: "",
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  function CheckIfAgentHasPermission() {
    const AgentHasPermission = userProfile.subAccounts
      .filter((x) => x.value == subAccountId)[0]
      .ownersPermissionsList.some(
        (x) =>
          x.permissionName === "AddRacingNameForImportedArabianHorse" || x.permissionName === "All"
      );
    return AgentHasPermission;
  }

  const getUserID = () => {
    const userID =
      !!userProfile && userProfile?.accountType !== "Agent" ? profile.value : subAccountId;
    return userID;
  };

  const getSelectedAccountName = () => {
    const selectAccount = userProfile.subAccounts.filter(
      (x) => x.value == subAccountId
    );

    if (selectAccount.length > 0) {
      return selectAccount[0];
    }
    else {
      return false;
    }
  };

  const dismissAll = () => toast.dismiss();

  //Effect
  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      dispatch(FetchGetServicesRequestByIdthunk(params));
    }

    return () => {
      dispatch(resetGetServicesRequestByIdData());
    };
  }, []);

  useEffect(() => {
    if (!!userProfile && userProfile?.haveProfileSignture != true) {
      toast.error(
        translate(
          "web.completeOwnerProfileData",
          "Please complete the personal data of the profile."
        )
      );

      setTimeout(() => {
        navigate("/profile");
      }, 3000);
    }

    if (!!userProfile && userProfile?.accountType === "Agent") {
      if (userProfile.id == subAccountId) {
        navigate("/403-Not-Authrized");
      }

      if (!CheckIfAgentHasPermission()) {
        navigate("/403-Not-Authrized");
      }
    }

    let params = {
      ownerId: getUserID(),
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    let subOwnerProfilesParams = {
      id: getUserID(),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));
  }, [currentLanguage]);

  useEffect(() => {
    if (
      Object.keys(getServicesRequestByIdData).length > 0 && (getServicesRequestByIdData.serviceDefinitionId == serviceId)
    ) {
      setFormModel({
        ...formModel,
        eventName: getServicesRequestByIdData.eventName,
        comment: getServicesRequestByIdData.comment,
        requestsType: getServicesRequestByIdData.requestsType,
        paymentStatus: getServicesRequestByIdData.paymentStatus,
        referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,

        horse: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.horse,
        horseDrop: {
          label: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.horseName,
          value: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.horse,
        },
        birthDate: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.birthDate,
        color: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.color,
        gender: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.gender,
        origin: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.origin,
        breeder: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.breeder,
        sire: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.sire,
        dam: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.dam,
        racingNameEn: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.racingNameEn,
        racingNameAr: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.racingNameAr,
        ownerComment: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.ownerComment != null ? getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.ownerComment : "",
      });

      //another owner
      if (
        getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.ownerId !==
        getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.createdBy &&
        subOwnerProfilesByIdData.length > 0 &&
        getServicesRequestByIdData.requestsType !== "BackOffice"
      ) {
        let params = {
          ownerId: getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.ownerId,
          skip: 0,
          take: 1000,
          langauge: currentLanguage,
          horseTypeFilter: "1",
          searchValue: "",
          sortBy: "-1",
        };
        dispatch(FetchOwnerHorsesDropDownthunk(params));

        //update profile
        setProfile({
          label:
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.ownerId
            )[0].registerNo +
            " - " +
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.ownerId
            )[0][`text${currentLanguage === "en" ? "" : "Ar"}`],
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.addRacingNameForImportedArabianHorseDto.ownerId
          )[0].value,
        });
      }

      //options
      setCheckedItems({
        option1: true,
        option2: true,
        option3: true,
        option4: true,
      });
    }
  }, [
    getServicesRequestByIdData,
  ]);

  useEffect(() => {
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_isOnlinePayment.current !== "") {
        toast.success(
          translate(
            "web.yourRequestHasSubmittedSuccessfully",
            "Your Request Has Submitted Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        if (_isOnlinePayment.current !== "") {
          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${createOrEditAddRacingNameForImportedArabianHorseData.id}&paymentType=18&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${_isOnlinePayment.current}&lang=${currentLanguage}`;
        } else {
          navigate(`/profile?activeTab=${"e_services"}`);
        }
      }, 1000);
    }

    //error
    else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
      toast.error(
        translate(toasterMessageServicesRequests, toasterMessageServicesRequests)
      );

      dispatch(resetToasterMessageServicesRequests());
    }
  }, [toasterMessageServicesRequests]);

  //consoles
  console.log(formModel)

  return (
    <div className="container-fluid container-fluid-padding">

      {/* loading div */}
      <div
        className={`page-overlay text-center ${servicesRequestsLoading
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      {/* toast */}
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      {/* content page */}
      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl">

          {/* update Arabian Horse Data */}
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage === "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>

          {/* Selected Account */}
          {subOwnerProfilesByIdData.length > 0 &&
            (action == null || action === "edit") &&
            (
              <div className="col-md-6 col-sm-12 mb-3">
                {!!userProfile && userProfile?.accountType !== "Agent" ? (
                  <>
                    <label className="capatalize_text">
                      {translate("web.selectedAccount", "Selected Account")}
                    </label>
                    <Select
                      className="mt-10 zindex200"
                      options={[
                        {
                          label:
                            userProfile?.ownerRegisterNo +
                            " - " +
                            userProfile[
                            `fullName${currentLanguage === "en" ? "" : "Ar"}`
                            ],
                          value: localStorage.getItem("EAHSUserID"),
                        },
                        ...(!!subOwnerProfilesByIdData &&
                          !!subOwnerProfilesByIdData.length > 0 &&
                          subOwnerProfilesByIdData.map((a) => ({
                            label:
                              a?.registerNo +
                              " - " +
                              a[`text${currentLanguage === "en" ? "" : "Ar"}`],
                            value: a.value,
                          }))),
                      ]}
                      value={profile}
                      onChange={(e) => {
                        handelSelectedAccountChange(e);
                      }}
                      placeholder={localStorage.getItem("EAHSUserFullName")}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      styles={customStyles}
                      key={"EAHSUserFullName"}
                    />
                  </>
                ) : (
                  <label className="capatalize_text">
                    {getSelectedAccountName()?.registerNo +
                      " - " +
                      getSelectedAccountName()[
                      `text${currentLanguage === "en" ? "" : "Ar"}`
                      ]}
                  </label>
                )}
              </div>
            )}

          <EahsForm title={translate("web.horseDetails", "Horse Details")}>
            <div className="col-md-6 col-sm-12">
              <label>
                {translate("web.horse", "Horse")}
                <i className="colorred"> *</i>
              </label>
              <Select
                options={[
                  {
                    label: translate("web.horse", "Horse"),
                    value: null,
                  },
                  ...(!!ownerHorsesDropDownData &&
                    ownerHorsesDropDownData.filter(q => q.raceNameEn === "" || q.raceNameEn == null).map((a) => ({
                      label: a.registerNumber + " - " + a.name,
                      value: a.id,
                    }))),
                ]}
                value={formModel.horseDrop}
                onChange={(e) => handleFormChange(e, "horse")}
                placeholder={translate("web.horse", "Horse")}
                name={"horse"}
                isSearchable
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
                autoFocus={true}
                key={"horse"}
                isDisabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorHorse && (
                  <span style={{ color: "red" }}>
                    {translate("web.requiredField", "Required Field")}
                  </span>
                )}
            </div>

            <div className="col-md-6 col-sm-12 ">
              <NetaqInput
                label={translate("web.origin", "Origin")}
                name={"origin"}
                type={"text"}
                disabled={true}
                value={formModel.origin}
              />
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.breeder", "Breeder")}
                name={"breeder"}
                type={"text"}
                disabled={true}
                value={formModel.breeder}
              />
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.gender", "Gender")}
                name={"gender"}
                type={"text"}
                disabled={true}
                value={translate(
                  `web._${formModel.gender.toLowerCase()}`,
                  formModel.gender
                )}
              />
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.color", "Color")}
                name={"color"}
                type={"text"}
                value={
                  formModel.color
                }
                disabled={true}
              />
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.birthDate", "Birth Date")}
                name={"birthDate"}
                type={"text"}
                disabled={true}
                value={
                  formModel.birthDate
                }
              />
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.sire", "Sire")}
                name={"sire"}
                type={"text"}
                disabled={true}
                value={formModel.sire}
              />
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.dam", "Dam")}
                name={"dam"}
                type={"text"}
                disabled={true}
                value={formModel.dam}
              />
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.racingName", "Racing Name") + " (EN)"}
                name={"racingNameEn"}
                type={"text"}
                //onChange={(e) => handleFormChange(e, "racingNameEn")}
                onChange={handleFormChange}
                value={formModel.racingNameEn}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorRacingNameEn &&
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              }
            </div>
            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.racingName", "Racing Name") + " (AR)"}
                name={"racingNameAr"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.racingNameAr}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorRacingNameAr &&
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              }
            </div>
          </EahsForm>

          {/* Comments */}
          {(!!formModel.comment || !!formModel.ownerComment) && (
            <EahsForm title={translate("web.comments", "Comments")}>
              {!!formModel.comment && (
                // eahs Comment
                <div className="col-md-6 col-sm-12">
                  <NetaqInput
                    label={translate("web.eahsComments", "EAHS Comments")}
                    name={"adminComment"}
                    type={"textarea"}
                    maxchars={1150}
                    value={formModel.comment}
                    disabled={true}
                  />
                </div>
              )}

              {(!!formModel.ownerComment || formModel.eventName === "SentBack") && (
                // owner Comment 
                <div className="col-md-6 col-sm-12">
                  <NetaqInput
                    label={translate("web.ownerComment", "Owner Comment")}
                    name={"ownerComment"}
                    type={"textarea"}
                    maxchars={1150}
                    value={formModel.ownerComment}
                    onChange={handleFormChange}
                    disabled={formModel.eventName !== "SentBack" ? true : false}
                  />
                </div>
              )}
            </EahsForm>
          )}

          <EahsForm title={translate("web.terms", "Terms")}>
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.addRacingNameForImportedArabianHorseNote1",
                  "Add Racing Name For Imported Arabian Horse Note1"
                )}
                name="option1"
                checked={checkedItems.option1}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.addRacingNameForImportedArabianHorseNote2",
                  "Add Racing Name For Imported Arabian Horse Note2"
                )}
                name="option2"
                checked={checkedItems.option2}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.addRacingNameForImportedArabianHorseNote3",
                  "Add Racing Name For Imported Arabian Horse Note3"
                )}
                name="option3"
                checked={checkedItems.option3}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.ServiceGeneralNote",
                  "Service General Note"
                )}
                name="option4"
                checked={checkedItems.option4}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
          </EahsForm>

          {/* buttons*/}
          <div className="col-md-12 col-sm-12 mt-5 text-end">
            {/* draft/pay/submit btn */}
            {(
              (action == null || action === "edit")
              ||
              (
                action === "approve"
                &&
                (
                  formModel.eventName === "SentBack"
                  ||
                  (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice")
                )
              )
            ) && (
                <div className="row mx-0 justify-content-lg-end justify-content-sm-start">
                  {(formModel.eventName === "" || formModel.eventName === "Draft") &&
                    <div className="col-lg-2 my-1">
                      <ActionButton
                        type={"bootstrapbtn"}
                        className="btn btn-outline-danger mx-auto w-100"
                        label={translate("web.draft", "Draft")}
                        text={`<i class="fa fa-save fa-lg marginrightleft-10"></i>                   
                          ${translate("web.draft", "Draft")}
                          `}
                        margin={"auto 10px"}
                        width={"100%"}
                        onClick={() => handelFormSubmit("Draft", "")}
                        disabled={
                          formModel.eventName === "" ||
                            formModel.eventName === "Draft"
                            ? false
                            : true
                        }
                      />
                    </div>
                  }

                  {userProfile?.participateWithoutPayment === true && formModel.eventName !== "SentBack" && getServiceDefinitionByIdData.serviceFees > 0 && (
                    <div className="col-lg-3 my-1">
                      <ActionButton
                        className="btn-info text-white mx-auto"
                        type={"bootstrapbtn"}
                        text={`<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>                   
                          ${translate("web.submitAndPayLater", "Submit And Pay Later")}
                          `}
                        margin={"0"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Draft", "no")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}

                  {formModel.eventName !== "SentBack" && getServiceDefinitionByIdData.serviceFees > 0 && (
                    <div className="col-lg-3 my-1">
                      <ActionButton
                        className={"btn-success requestBtn mx-0 mx-auto w-100"}
                        type={"requests"}
                        label={translate("web.approvePay", "Approve And Pay")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submitPay", "Submit And Pay")}
                          </>
                        }
                        margin={"0"}
                        onClick={() =>
                          handelFormSubmit("Draft", "yes")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}

                  {(getServiceDefinitionByIdData.serviceFees === 0 || formModel.eventName === "SentBack") && (
                    <div className="col-lg-2 my-sm-1">
                      <ActionButton
                        type={"requests"}
                        className="btn-success text-white requestBtn w-100 mx-auto"
                        label={translate("web.submit", "Submit")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submit", "Submit")}
                          </>
                        }
                        margin={"auto 25px"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Submit", "")
                        }
                        disabled={
                          getServiceDefinitionByIdData.serviceFees === 0 || formModel.eventName === "SentBack"
                            ? false
                            : true
                        }
                      />
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
