
export function CheckDateBeforUndefinedDays(date, days) {
    // Current date
    let currentDate = new Date();

    // Another date (example)
    let anotherDate = new Date(date); // Replace this with your desired date

    // Calculate the difference in time (milliseconds)
    let timeDifference = Math.abs(currentDate - anotherDate); // Absolute difference

    // Convert milliseconds to days
    let dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // Check if the day difference is 15 or less
    if (dayDifference <= days) {
        return true;
    } else {
        return false;
    }
}