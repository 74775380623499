import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchCreateOrEditImportHorseFrozenSemenServiceRequestthunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
  FetchGetShippingAgentsthunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetGetServicesRequestByIdData,
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import dayjs from "dayjs";
import EahsForm from "../components/EahsForm";
import { FetchStudBookCountriesthunk } from "../Redux/StudBook/StudBookAction";
import SimpleFileUpload from "../components/SimpleFileUpload";
import { base64ToBlob } from "../helper/base64ToBlob";

export default function ImportHorseFrozenSemen() {
  //env 
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  // const UMBRACOURL = process.env.REACT_APP_Local_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  //selector
  const { studBookCountries, currentLanguage } = useSelector((state) => state.studBookSlice);

  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
    importHorseFrozenSemenData,
    getShippingAgentsData,
  } = useSelector((state) => state.servicesRequestsSlice);

  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

  const dispatch = useDispatch();

  const { translate } = useTranslate();

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    importHorseFrozenSemenId: "",
    eventName: "",
    comment: "",
    referenceNumber: "",
    requestsType: "",
    paymentStatus: "",

    importCountry: "",
    importCountryDrop: {
      label: translate("web.chooseCountry", "Choose Country"),
      value: null,
    },

    originCountry: "",
    originCountryDrop: {
      label: translate("web.chooseCountry", "Choose Country"),
      value: null,
    },

    stallionName: "",
    horseCategory: "Show",
    ueln: "",

    sire: "",
    dam: "",
    originStudbookRefNo: "",
    currentStudbookRefNo: "",
    approvedCollectionCenter: "",
    noStraws: null,
    noDoses: null,
    dispatchNumber: "",
    importDate: null,
    uaeHospitalSemenStocking: "",
    shippingAgent: "",
    shippingAgentDrop: {
      label: translate("web.shippingAgent", "Shipping Agent"),
      value: null,
    },

    ownerComment: "",

    filePathHorsePassport: "",
    fileTypeHorsePassport: "",
    fileNameHorsePassport: "",
    documentBase64HorsePassport: "",
    horsePassportAttachmentFile: null,

    filePathLetterSemenStorageCenter: "",
    fileTypeLetterSemenStorageCenter: "",
    fileNameLetterSemenStorageCenter: "",
    documentBase64LetterSemenStorageCenter: "",
    letterSemenStorageCenterAttachmentFile: null,

    filePathResidencePermit: "",
    fileTypeResidencePermit: "",
    fileNameResidencePermit: "",
    documentBase64ResidencePermit: "",
    residencePermitAttachmentFile: null,
  });

  const [errorStallionName, setErrorStallionName] = useState(false);
  const [errorImportCountry, setErrorImportCountry] = useState(false);
  const [errorOriginCountry, setErrorOriginCountry] = useState(false);
  const [errorUeln, setErrorUeln] = useState(false);
  const [errorSire, setErrorSire] = useState(false);
  const [errorDam, setErrorDam] = useState(false);
  const [errorOriginStudbookRefNo, setErrorOriginStudbookRefNo] = useState(false);
  const [errorCurrentStudbookRefNo, setErrorCurrentStudbookRefNo] = useState(false);
  const [errorApprovedCollectionCenter, setErrorApprovedCollectionCenter] = useState(false);
  const [errorNoStraws, setErrorNoStraws] = useState(false);
  const [errorNoDoses, setErrorNoDoses] = useState(false);
  const [errorDispatchNumber, setErrorDispatchNumber] = useState(false);
  const [errorUaeHospitalSemenStocking, setErrorUaeHospitalSemenStocking] = useState(false);
  const [errorImportDate, setErrorImportDate] = useState(false);
  const [errorShippingAgent, setErrorShippingAgent] = useState(false);
  const [errorHorsePassport, setErrorHorsePassport] = useState(false);
  const [errorLetterSemenStorageCenter, setErrorLetterSemenStorageCenter] = useState(false);
  const [errorResidencePermit, setErrorResidencePermit] = useState(false);

  const [
    horsePassportAttachmentFile,
    setHorsePassportAttachmentFile,
  ] = useState([]);

  const [
    letterSemenStorageCenterAttachmentFile,
    setLetterSemenStorageCenterAttachmentFile,
  ] = useState([]);

  const [
    residencePermitAttachmentFile,
    setResidencePermitAttachmentFile,
  ] = useState([]);

  const _isOnlinePayment = useRef("");

  const [checkedItems, setCheckedItems] = useState({
    option1: false,
    option2: false,
    option3: false,
  });

  const [profile, setProfile] = useState({
    label:
      !!userProfile &&
      userProfile?.ownerRegisterNo +
      " - " +
      userProfile[`fullName${currentLanguage === "en" ? "" : "Ar"}`],
    value: localStorage.getItem("EAHSUserID"),
  });

  const subAccountId = localStorage.getItem("currentSubAccountId");

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  //function

  const handleFormChange = (e, input) => {
    if (input === "importCountry") {
      setFormModel({
        ...formModel,
        importCountry: e.value,
        importCountryDrop: e,
      });
    }
    else if (input === "originCountry") {
      setFormModel({
        ...formModel,
        originCountry: e.value,
        originCountryDrop: e,
      });
    }
    else if (input === "shippingAgent") {
      setFormModel({
        ...formModel,
        shippingAgent: e.value,
        shippingAgentDrop: e,
      });
    }
    else if (input === "stallionName" || input === "sire" || input === "dam") {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value.toUpperCase(),
      });
    }
    else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleUploadHorsePassportChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          horsePassportAttachmentFile: e.target.files[0],
        });

        setHorsePassportAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const handleUploadLetterSemenStorageCenterChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          letterSemenStorageCenterAttachmentFile: e.target.files[0],
        });

        setLetterSemenStorageCenterAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const handleUploadResidencePermitChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          residencePermitAttachmentFile: e.target.files[0],
        });

        setResidencePermitAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const validInputs = () => {
    let valid = true;

    if (
      formModel.stallionName === ""
    ) {
      setErrorStallionName(true);
      valid = false;
    }
    else {
      setErrorStallionName(false);
    }

    if (
      formModel.importCountry === ""
    ) {
      setErrorImportCountry(true);
      valid = false;
    }
    else {
      setErrorImportCountry(false);
    }

    if (
      formModel.originCountry === ""
    ) {
      setErrorOriginCountry(true);
      valid = false;
    }
    else {
      setErrorOriginCountry(false);
    }

    if (
      formModel.sire === ""
    ) {
      setErrorSire(true);
      valid = false;
    }
    else {
      setErrorSire(false);
    }

    if (
      formModel.dam === ""
    ) {
      setErrorDam(true);
      valid = false;
    }
    else {
      setErrorDam(false);
    }

    if (
      formModel.originStudbookRefNo === ""
    ) {
      setErrorOriginStudbookRefNo(true);
      valid = false;
    }
    else {
      setErrorOriginStudbookRefNo(false);
    }

    if (
      formModel.currentStudbookRefNo === ""
    ) {
      setErrorCurrentStudbookRefNo(true);
      valid = false;
    }
    else {
      setErrorCurrentStudbookRefNo(false);
    }

    if (
      formModel.approvedCollectionCenter === ""
    ) {
      setErrorApprovedCollectionCenter(true);
      valid = false;
    }
    else {
      setErrorApprovedCollectionCenter(false);
    }

    if (
      formModel.noStraws === "" || formModel.noStraws == null || Number(formModel.noStraws) < 1
    ) {
      setErrorNoStraws(true);
      valid = false;
    }
    else {
      setErrorNoStraws(false);
    }

    if (
      formModel.noDoses === "" || formModel.noStraws == null || Number(formModel.noDoses) < 1
    ) {
      setErrorNoDoses(true);
      valid = false;
    }
    else {
      setErrorNoDoses(false);
    }

    if (
      formModel.dispatchNumber === ""
    ) {
      setErrorDispatchNumber(true);
      valid = false;
    }
    else {
      setErrorDispatchNumber(false);
    }

    if (
      formModel.uaeHospitalSemenStocking === ""
    ) {
      setErrorUaeHospitalSemenStocking(true);
      valid = false;
    }
    else {
      setErrorUaeHospitalSemenStocking(false);
    }

    if (
      formModel.importDate === null
    ) {
      setErrorImportDate(true);
      valid = false;
    }
    else {
      setErrorImportDate(false);
    }

    if (
      formModel.shippingAgent === ""
    ) {
      setErrorShippingAgent(true);
      valid = false;
    }
    else {
      setErrorShippingAgent(false);
    }

    if (
      horsePassportAttachmentFile.length === 0
      && formModel.documentBase64HorsePassport === ""
    ) {
      setErrorHorsePassport(true);
      valid = false;
    }
    else {
      setErrorHorsePassport(false);
    }

    if (
      letterSemenStorageCenterAttachmentFile.length === 0
      && formModel.documentBase64LetterSemenStorageCenter === ""
    ) {
      setErrorLetterSemenStorageCenter(true);
      valid = false;
    }
    else {
      setErrorLetterSemenStorageCenter(false);
    }

    //term
    if (
      !checkedItems.option1
      || !checkedItems.option2
      || !checkedItems.option3
    ) {
      toast.error(
        translate("web.pleaseCheckAllTerms", "Please Check All Terms")
      );
      valid = false;
    }

    return valid;
  }

  const handelFormSubmit = (status, isOnlinePayment) => {
    if (getServiceDefinitionByIdData.serviceFees > 0 && formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice" && formModel.eventName !== "SentBack") {
      const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

      const encodeURI = encodeURIComponent(returnUrl);

      window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=20&userId=${localStorage.getItem("EAHSUserID")}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment}&lang=${currentLanguage}`;
    }
    else {
      if (
        validInputs()
      ) {
        _isOnlinePayment.current = isOnlinePayment;

        let params = {
          id: id == null ? '' : id,
          importHorseFrozenSemenId: formModel.importHorseFrozenSemenId,
          ownerId: getUserID(),
          stallionName: formModel.stallionName,
          importCountry: formModel.importCountry,
          originCountry: formModel.originCountry,
          horseCategory: formModel.horseCategory,
          ueln: formModel.ueln,
          sire: formModel.sire,
          dam: formModel.dam,
          originStudbookRefNo: formModel.originStudbookRefNo,
          currentStudbookRefNo: formModel.currentStudbookRefNo,
          approvedCollectionCenter: formModel.approvedCollectionCenter,
          noStraws: formModel.noStraws,
          noDoses: formModel.noDoses,
          dispatchNumber: formModel.dispatchNumber,
          uaeHospitalSemenStocking: formModel.uaeHospitalSemenStocking,
          importDate: formModel.importDate != null ? dayjs(formModel.importDate).format("DD/MM/YYYY") : '',
          shippingAgent: formModel.shippingAgent,
          horsePassportAttachmentFile: formModel.horsePassportAttachmentFile,
          letterSemenStorageCenterAttachmentFile: formModel.letterSemenStorageCenterAttachmentFile,
          residencePermitAttachmentFile: formModel.residencePermitAttachmentFile,
          status: status,
          ownerComment: formModel.ownerComment,
        };

        dispatch(FetchCreateOrEditImportHorseFrozenSemenServiceRequestthunk(params));
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    setProfile(element);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  function CheckIfAgentHasPermission() {
    const AgentHasPermission = userProfile.subAccounts
      .filter((x) => x.value == subAccountId)[0]
      .ownersPermissionsList.some(
        (x) =>
          x.permissionName === "ImportHorseFrozenSemen" || x.permissionName === "All"
      );
    return AgentHasPermission;
  }

  const getUserID = () => {
    const userID =
      !!userProfile && userProfile?.accountType !== "Agent" ? profile.value : subAccountId;
    return userID;
  };

  const getSelectedAccountName = () => {
    const selectAccount = userProfile.subAccounts.filter(
      (x) => x.value == subAccountId
    );

    if (selectAccount.length > 0) {
      return selectAccount[0];
    }
    else {
      return false;
    }
  };

  const dismissAll = () => toast.dismiss();

  //Effect
  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      dispatch(FetchGetServicesRequestByIdthunk(params));
    }

    return () => {
      dispatch(resetGetServicesRequestByIdData());
    };
  }, []);

  useEffect(() => {
    if (!!userProfile && userProfile?.haveProfileSignture != true) {
      toast.error(
        translate(
          "web.completeOwnerProfileData",
          "Please complete the personal data of the profile."
        )
      );

      setTimeout(() => {
        navigate("/profile");
      }, 3000);
    }

    if (!!userProfile && userProfile?.accountType === "Agent") {
      if (userProfile.id == subAccountId) {
        navigate("/403-Not-Authrized");
      }

      if (!CheckIfAgentHasPermission()) {
        navigate("/403-Not-Authrized");
      }
    }

    let subOwnerProfilesParams = {
      id: getUserID(),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

    dispatch(FetchStudBookCountriesthunk(currentLanguage));

    dispatch(FetchGetShippingAgentsthunk());

    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));
  }, [currentLanguage]);

  useEffect(() => {
    if (
      Object.keys(getServicesRequestByIdData).length > 0 && (getServicesRequestByIdData.serviceDefinitionId == serviceId)
    ) {
      setFormModel({
        ...formModel,
        eventName: getServicesRequestByIdData.eventName,
        comment: getServicesRequestByIdData.comment,
        requestsType: getServicesRequestByIdData.requestsType,
        paymentStatus: getServicesRequestByIdData.paymentStatus,
        referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,

        importHorseFrozenSemenId: getServicesRequestByIdData.importHorseFrozenSemenDto.importHorseFrozenSemenId,
        stallionName: getServicesRequestByIdData.importHorseFrozenSemenDto.stallionName,
        importCountry: getServicesRequestByIdData.importHorseFrozenSemenDto.importCountry,
        importCountryDrop: {
          label: getServicesRequestByIdData.importHorseFrozenSemenDto.importCountryName,
          value: getServicesRequestByIdData.importHorseFrozenSemenDto.importCountry,
        },
        originCountry: getServicesRequestByIdData.importHorseFrozenSemenDto.originCountry,
        originCountryDrop: {
          label: getServicesRequestByIdData.importHorseFrozenSemenDto.originCountryName,
          value: getServicesRequestByIdData.importHorseFrozenSemenDto.originCountry,
        },
        horseCategory: getServicesRequestByIdData.importHorseFrozenSemenDto.horseCategory,
        ueln: getServicesRequestByIdData.importHorseFrozenSemenDto.ueln == null ? "" : getServicesRequestByIdData.importHorseFrozenSemenDto.ueln,
        sire: getServicesRequestByIdData.importHorseFrozenSemenDto.sire,
        dam: getServicesRequestByIdData.importHorseFrozenSemenDto.dam,
        originStudbookRefNo: getServicesRequestByIdData.importHorseFrozenSemenDto.originStudbookRefNo,
        currentStudbookRefNo: getServicesRequestByIdData.importHorseFrozenSemenDto.currentStudbookRefNo,
        approvedCollectionCenter: getServicesRequestByIdData.importHorseFrozenSemenDto.approvedCollectionCenter,
        noStraws: getServicesRequestByIdData.importHorseFrozenSemenDto.noStraws,
        noDoses: getServicesRequestByIdData.importHorseFrozenSemenDto.noDoses,
        dispatchNumber: getServicesRequestByIdData.importHorseFrozenSemenDto.dispatchNumber,
        importDate: getServicesRequestByIdData.importHorseFrozenSemenDto.importDateTime != null ? dayjs(getServicesRequestByIdData.importHorseFrozenSemenDto.importDateTime).format("YYYY-MM-DD") : null,
        uaeHospitalSemenStocking: getServicesRequestByIdData.importHorseFrozenSemenDto.uaeHospitalSemenStocking,
        shippingAgent: getServicesRequestByIdData.importHorseFrozenSemenDto.shippingAgent,
        shippingAgentDrop: {
          label: getServicesRequestByIdData.importHorseFrozenSemenDto.shippingAgentName,
          value: getServicesRequestByIdData.importHorseFrozenSemenDto.shippingAgent,
        },

        filePathHorsePassport: getServicesRequestByIdData.importHorseFrozenSemenDto.horsePassportAttachmentFileModel.filePath,
        fileTypeHorsePassport: getServicesRequestByIdData.importHorseFrozenSemenDto.horsePassportAttachmentFileModel.fileType,
        fileNameHorsePassport: getServicesRequestByIdData.importHorseFrozenSemenDto.horsePassportAttachmentFileModel.fileName,
        documentBase64HorsePassport: getServicesRequestByIdData.importHorseFrozenSemenDto.horsePassportAttachmentFileModel.fileBase64,

        filePathLetterSemenStorageCenter: getServicesRequestByIdData.importHorseFrozenSemenDto.letterSemenStorageCenterAttachmentFileModel.filePath,
        fileTypeLetterSemenStorageCenter: getServicesRequestByIdData.importHorseFrozenSemenDto.letterSemenStorageCenterAttachmentFileModel.fileType,
        fileNameLetterSemenStorageCenter: getServicesRequestByIdData.importHorseFrozenSemenDto.letterSemenStorageCenterAttachmentFileModel.fileName,
        documentBase64LetterSemenStorageCenter: getServicesRequestByIdData.importHorseFrozenSemenDto.letterSemenStorageCenterAttachmentFileModel.fileBase64,

        filePathResidencePermit: getServicesRequestByIdData.importHorseFrozenSemenDto.residencePermitAttachmentFileModel.filePath,
        fileTypeResidencePermit: getServicesRequestByIdData.importHorseFrozenSemenDto.residencePermitAttachmentFileModel.fileType,
        fileNameResidencePermit: getServicesRequestByIdData.importHorseFrozenSemenDto.residencePermitAttachmentFileModel.fileName,
        documentBase64ResidencePermit: getServicesRequestByIdData.importHorseFrozenSemenDto.residencePermitAttachmentFileModel.fileBase64,

        ownerComment: getServicesRequestByIdData.importHorseFrozenSemenDto.ownerComment != null ? getServicesRequestByIdData.importHorseFrozenSemenDto.ownerComment : "",
      });

      //another owner
      if (
        getServicesRequestByIdData.importHorseFrozenSemenDto.ownerId !==
        getServicesRequestByIdData.importHorseFrozenSemenDto.createdBy &&
        subOwnerProfilesByIdData.length > 0 &&
        getServicesRequestByIdData.requestsType !== "BackOffice"
      ) {
        //update profile
        setProfile({
          label:
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.importHorseFrozenSemenDto.ownerId
            )[0].registerNo +
            " - " +
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.importHorseFrozenSemenDto.ownerId
            )[0][`text${currentLanguage === "en" ? "" : "Ar"}`],
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.importHorseFrozenSemenDto.ownerId
          )[0].value,
        });
      }

      //options
      setCheckedItems({
        option1: true,
        option2: true,
        option3: true,
      });
    }
  }, [
    getServicesRequestByIdData,
  ]);

  useEffect(() => {
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_isOnlinePayment.current !== "") {
        toast.success(
          translate(
            "web.yourRequestHasSubmittedSuccessfully",
            "Your Request Has Submitted Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        if (_isOnlinePayment.current !== "") {
          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${importHorseFrozenSemenData.id}&paymentType=20&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${_isOnlinePayment.current}&lang=${currentLanguage}`;
        } else {
          navigate(`/profile?activeTab=${"e_services"}`);
        }
      }, 1000);
    }

    //error
    else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
      toast.error(
        translate(toasterMessageServicesRequests, toasterMessageServicesRequests)
      );

      dispatch(resetToasterMessageServicesRequests());
    }
  }, [toasterMessageServicesRequests]);

  //consoles
  console.log(formModel)

  return (
    <div className="container-fluid container-fluid-padding">

      {/* loading div */}
      <div
        className={`page-overlay text-center ${servicesRequestsLoading
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      {/* toast */}
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      {/* content page */}
      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl">

          {/* update Arabian Horse Data */}
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage === "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>

          {/* Selected Account */}
          {subOwnerProfilesByIdData.length > 0 &&
            (action == null || action === "edit") &&
            (
              <div className="col-md-6 col-sm-12 mb-3">
                {!!userProfile && userProfile?.accountType !== "Agent" ? (
                  <>
                    <label className="capatalize_text">
                      {translate("web.selectedAccount", "Selected Account")}
                    </label>
                    <Select
                      className="mt-10 zindex200"
                      options={[
                        {
                          label:
                            userProfile?.ownerRegisterNo +
                            " - " +
                            userProfile[
                            `fullName${currentLanguage === "en" ? "" : "Ar"}`
                            ],
                          value: localStorage.getItem("EAHSUserID"),
                        },
                        ...(!!subOwnerProfilesByIdData &&
                          !!subOwnerProfilesByIdData.length > 0 &&
                          subOwnerProfilesByIdData.map((a) => ({
                            label:
                              a?.registerNo +
                              " - " +
                              a[`text${currentLanguage === "en" ? "" : "Ar"}`],
                            value: a.value,
                          }))),
                      ]}
                      value={profile}
                      onChange={(e) => {
                        handelSelectedAccountChange(e);
                      }}
                      placeholder={localStorage.getItem("EAHSUserFullName")}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      styles={customStyles}
                      key={"EAHSUserFullName"}
                    />
                  </>
                ) : (
                  <label className="capatalize_text">
                    {getSelectedAccountName()?.registerNo +
                      " - " +
                      getSelectedAccountName()[
                      `text${currentLanguage === "en" ? "" : "Ar"}`
                      ]}
                  </label>
                )}
              </div>
            )}

          <EahsForm title={translate("web.stallionDetails", "Stallion Details")}>
            <div className="col-md-12 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.stallionName", "Stallion Name")}
                name={"stallionName"}
                type={"text"}
                onChange={(e) => handleFormChange(e, "stallionName")}
                value={formModel.stallionName}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorStallionName &&
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              }
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <label>
                {translate("web.importFrom", "Import From")}
                <i className="colorred"> *</i>
              </label>
              <Select
                options={[
                  {
                    label: translate("web.chooseCountry", "Choose Country"),
                    value: null,
                  },
                  ...(!!studBookCountries &&
                    studBookCountries.map((a) => ({
                      label: a.text,
                      value: a.value,
                    }))),
                ]}
                value={formModel.importCountryDrop}
                onChange={(e) => handleFormChange(e, "importCountry")}
                placeholder={translate("web.chooseCountry", "Choose Country")}
                name={"importCountry"}
                isSearchable
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
                autoFocus={true}
                key={"importCountry"}
                isDisabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorImportCountry && (
                  <span style={{ color: "red" }}>
                    {translate("web.requiredField", "Required Field")}
                  </span>
                )}
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <label>
                {translate("web.originFrom", "Origin From")}
                <i className="colorred"> *</i>
              </label>
              <Select
                options={[
                  {
                    label: translate("web.chooseCountry", "Choose Country"),
                    value: null,
                  },
                  ...(!!studBookCountries &&
                    studBookCountries.map((a) => ({
                      label: a.text,
                      value: a.value,
                    }))),
                ]}
                value={formModel.originCountryDrop}
                onChange={(e) => handleFormChange(e, "originCountry")}
                placeholder={translate("web.chooseCountry", "Choose Country")}
                name={"originCountry"}
                isSearchable
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
                autoFocus={true}
                key={"originCountry"}
                isDisabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {errorOriginCountry && (
                <span style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </span>
              )}
            </div>

            <div className="AuctionFilterradioServiceRequestContainer mt-3 row col-md-6 col-sm-12" style={{ margin: 0 }}>
              <label>
                {translate("web.horseCategory", "Horse Category")}
              </label>

              <div style={{ maxWidth: 200 }}>
                <NetaqInput
                  name={"horseCategory"}
                  type={"radioServiceRequest"}
                  value={"Show"}
                  label={`${translate("web.isShowCategory", "Is Show Category")}-`}
                  onChange={handleFormChange}
                  checked={
                    formModel.horseCategory === "Show"
                  }
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>

              <div style={{ maxWidth: 200 }}>
                <NetaqInput
                  name={"horseCategory"}
                  type={"radioServiceRequest"}
                  value={"Race"}
                  label={`${translate("web.isRaceCategory", "Is Race Category")}-`}
                  onChange={handleFormChange}
                  checked={
                    formModel.horseCategory === "Race"
                  }
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.ueln", "UELN")}
                name={"ueln"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.ueln}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.sire", "Sire")}
                name={"sire"}
                type={"text"}
                onChange={(e) => handleFormChange(e, "sire")}
                value={formModel.sire}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorSire &&
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              }
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.dam", "Dam")}
                name={"dam"}
                type={"text"}
                onChange={(e) => handleFormChange(e, "dam")}
                value={formModel.dam}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorDam &&
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              }
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.originStudbookRefNo", "Origin Studbook Ref.No")}
                name={"originStudbookRefNo"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.originStudbookRefNo}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorOriginStudbookRefNo &&
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              }
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.currentStudbookRefNo", "Current Studbook Ref.No")}
                name={"currentStudbookRefNo"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.currentStudbookRefNo}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorCurrentStudbookRefNo &&
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              }
            </div>
          </EahsForm>

          <EahsForm title={translate("web.approvedCollectionCenterDetails", "Approved Collection Center Details")}>
            <div className="col-md-4 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.approvedCollectionCenter", "Approved Collection Center")}
                name={"approvedCollectionCenter"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.approvedCollectionCenter}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorApprovedCollectionCenter &&
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              }
            </div>

            <div className="col-md-4 col-sm-12 mt-3">
              <NetaqInput
                name={"noStraws"}
                key={"noStraws"}
                type={"number"}
                value={formModel.noStraws}
                minValue={1}
                label={translate("web.noStraws", "No Of Straws")}
                onChange={handleFormChange}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorNoStraws &&
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              }
            </div>

            <div className="col-md-4 col-sm-12 mt-3">
              <NetaqInput
                name={"noDoses"}
                key={"noDoses"}
                type={"number"}
                value={formModel.noDoses}
                minValue={1}
                label={translate("web.noDoses", "No Of Doses")}
                onChange={handleFormChange}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorNoDoses &&
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              }
            </div>

            <div className="col-md-4 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.dispatchNumber", "Dispatch Number")}
                name={"dispatchNumber"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.dispatchNumber}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorDispatchNumber &&
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              }
            </div>

            <div className="col-md-4 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.uaeHospitalSemenStocking", "UAE Hospital Semen Stocking")}
                name={"uaeHospitalSemenStocking"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.uaeHospitalSemenStocking}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorUaeHospitalSemenStocking &&
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              }
            </div>

            <div className="col-md-4 col-sm-12 rtl service mt-3">
              <NetaqInput
                htmlTags={
                  formModel.importDate ?
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      {
                        dayjs(formModel.importDate).format("DD/MM/YYYY")
                      }
                    </p> :
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      DD/MM/YYYY
                    </p>
                }
                name={"importDate"}
                type={"normalDateService"}
                label={translate("web.importDate", "Date Of Import")}
                onChange={handleFormChange}
                value={formModel.importDate}
                required={true}
                maxDate={dayjs().format("YYYY-MM-DD")}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />

              {
                errorImportDate &&
                <p style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </p>
              }
            </div>
          </EahsForm>

          <EahsForm title={translate("web.shippingAgent", "Shipping Agent")}>
            <div className="col-md-6 col-sm-12">
              <label>
                {translate("web.shippingAgent", "Shipping Agent")}
                <i className="colorred"> *</i>
              </label>
              <Select
                options={[
                  {
                    label: translate("web.shippingAgent", "Shipping Agent"),
                    value: null,
                  },
                  ...(!!getShippingAgentsData &&
                    getShippingAgentsData.map((a) => ({
                      label: a.text,
                      value: a.value,
                    }))),
                ]}
                value={formModel.shippingAgentDrop}
                onChange={(e) => handleFormChange(e, "shippingAgent")}
                placeholder={translate("web.shippingAgent", "Shipping Agent")}
                name={"shippingAgent"}
                isSearchable
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
                autoFocus={true}
                key={"shippingAgent"}
                isDisabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {
                errorShippingAgent && (
                  <span style={{ color: "red" }}>
                    {translate("web.requiredField", "Required Field")}
                  </span>
                )}
            </div>
          </EahsForm>

          <EahsForm title={translate("web.uploadDocument", "Upload Document")}>
            <div className="col-md-8 col-sm-12 row mb-25 row align-items-center">
              <label>
                {translate("web.horsePassport", "Horse Passport")}
                <i className="colorred"> *</i>
              </label>
              <div className="col-7">
                <SimpleFileUpload
                  isMulti={false}
                  initialFiles={
                    !!formModel.filePathHorsePassport &&
                      !!formModel.documentBase64HorsePassport &&
                      horsePassportAttachmentFile.length === 0
                      ? [
                        {
                          file: base64ToBlob(
                            formModel.documentBase64HorsePassport,
                            formModel.fileTypeHorsePassport ===
                              ".pdf"
                              ? "application/pdf"
                              : "image/png"
                          ),
                          name: formModel.fileNameHorsePassport,
                        },
                      ]
                      : horsePassportAttachmentFile
                  }
                  isServer={
                    !!formModel.filePathHorsePassport &&
                      !!formModel.documentBase64HorsePassport &&
                      horsePassportAttachmentFile.length === 0
                      ? true
                      : false
                  }
                  onChange={handleUploadHorsePassportChange}
                  accept={"image/*,application/pdf"}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                {
                  errorHorsePassport && (
                    <span style={{ color: "red" }}>
                      {translate("web.requiredField", "Required Field")}
                    </span>
                  )}
              </div>
            </div>

            <div className="col-md-8 col-sm-12 row mb-25 row align-items-center">
              <label>
                {translate("web.letterSemenStorageCenter", "Letter Semen Storage Center")}
                <i className="colorred"> *</i>
              </label>
              <div className="col-7">
                <SimpleFileUpload
                  isMulti={false}
                  initialFiles={
                    !!formModel.filePathLetterSemenStorageCenter &&
                      !!formModel.documentBase64LetterSemenStorageCenter &&
                      letterSemenStorageCenterAttachmentFile.length === 0
                      ? [
                        {
                          file: base64ToBlob(
                            formModel.documentBase64LetterSemenStorageCenter,
                            formModel.fileTypeLetterSemenStorageCenter ===
                              ".pdf"
                              ? "application/pdf"
                              : "image/png"
                          ),
                          name: formModel.fileNameLetterSemenStorageCenter,
                        },
                      ]
                      : letterSemenStorageCenterAttachmentFile
                  }
                  isServer={
                    !!formModel.filePathLetterSemenStorageCenter &&
                      !!formModel.documentBase64LetterSemenStorageCenter &&
                      letterSemenStorageCenterAttachmentFile.length === 0
                      ? true
                      : false
                  }
                  onChange={handleUploadLetterSemenStorageCenterChange}
                  accept={"image/*,application/pdf"}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                {
                  errorLetterSemenStorageCenter && (
                    <span style={{ color: "red" }}>
                      {translate("web.requiredField", "Required Field")}
                    </span>
                  )}
              </div>
            </div>

            <div className="col-md-8 col-sm-12 row mb-25 row align-items-center">
              <label>
                {translate("web.residencePermit", "Residence Permit")}
              </label>
              <div className="col-7">
                <SimpleFileUpload
                  isMulti={false}
                  initialFiles={
                    !!formModel.filePathResidencePermit &&
                      !!formModel.documentBase64ResidencePermit &&
                      residencePermitAttachmentFile.length === 0
                      ? [
                        {
                          file: base64ToBlob(
                            formModel.documentBase64ResidencePermit,
                            formModel.fileTypeResidencePermit ===
                              ".pdf"
                              ? "application/pdf"
                              : "image/png"
                          ),
                          name: formModel.fileNameResidencePermit,
                        },
                      ]
                      : residencePermitAttachmentFile
                  }
                  isServer={
                    !!formModel.filePathResidencePermit &&
                      !!formModel.documentBase64ResidencePermit &&
                      residencePermitAttachmentFile.length === 0
                      ? true
                      : false
                  }
                  onChange={handleUploadResidencePermitChange}
                  accept={"image/*,application/pdf"}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                {
                  errorResidencePermit && (
                    <span style={{ color: "red" }}>
                      {translate("web.requiredField", "Required Field")}
                    </span>
                  )}
              </div>
            </div>
          </EahsForm>

          {/* Comments */}
          {(!!formModel.comment || !!formModel.ownerComment) && (
            <EahsForm title={translate("web.comments", "Comments")}>
              {!!formModel.comment && (
                // eahs Comment
                <div className="col-md-6 col-sm-12">
                  <NetaqInput
                    label={translate("web.eahsComments", "EAHS Comments")}
                    name={"adminComment"}
                    type={"textarea"}
                    maxchars={1150}
                    value={formModel.comment}
                    disabled={true}
                  />
                </div>
              )}

              {(!!formModel.ownerComment || formModel.eventName === "SentBack") && (
                // owner Comment 
                <div className="col-md-6 col-sm-12">
                  <NetaqInput
                    label={translate("web.ownerComment", "Owner Comment")}
                    name={"ownerComment"}
                    type={"textarea"}
                    maxchars={1150}
                    value={formModel.ownerComment}
                    onChange={handleFormChange}
                    disabled={formModel.eventName !== "SentBack" ? true : false}
                  />
                </div>
              )}
            </EahsForm>
          )}

          <EahsForm title={translate("web.terms", "Terms")}>
            <div className="col-md-12 col-sm-12 rtl p0" >
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.importHorseFrozenSemenNote1",
                  "Import Horse Frozen Semen Note1"
                )}
                name="option1"
                checked={checkedItems.option1}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.importHorseFrozenSemenNote2",
                  "Import Horse Frozen Semen Note2"
                )}
                name="option2"
                checked={checkedItems.option2}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>

            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.ServiceGeneralNote",
                  "Service General Note"
                )}
                name="option3"
                checked={checkedItems.option3}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
          </EahsForm>

          {/* buttons*/}
          <div className="col-md-12 col-sm-12 mt-5 text-end">
            {/* draft/pay/submit btn */}
            {(
              (action == null || action === "edit")
              ||
              (
                action === "approve"
                &&
                (
                  formModel.eventName === "SentBack"
                  ||
                  (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice")
                )
              )
            ) && (
                <div className="row mx-0 justify-content-lg-end justify-content-sm-start">
                  {(formModel.eventName === "" || formModel.eventName === "Draft") &&
                    <div className="col-lg-2 my-1">
                      <ActionButton
                        type={"bootstrapbtn"}
                        className="btn btn-outline-danger mx-auto w-100"
                        label={translate("web.draft", "Draft")}
                        text={`<i class="fa fa-save fa-lg marginrightleft-10"></i>                   
                          ${translate("web.draft", "Draft")}
                          `}
                        margin={"auto 10px"}
                        width={"100%"}
                        onClick={() => handelFormSubmit("Draft", "")}
                        disabled={
                          formModel.eventName === "" ||
                            formModel.eventName === "Draft"
                            ? false
                            : true
                        }
                      />
                    </div>
                  }

                  {userProfile?.participateWithoutPayment === true && formModel.eventName !== "SentBack" && getServiceDefinitionByIdData.serviceFees > 0 && (
                    <div className="col-lg-3 my-1">
                      <ActionButton
                        className="btn-info text-white mx-auto"
                        type={"bootstrapbtn"}
                        text={`<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>                   
                          ${translate("web.submitAndPayLater", "Submit And Pay Later")}
                          `}
                        margin={"0"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Draft", "no")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}

                  {formModel.eventName !== "SentBack" && getServiceDefinitionByIdData.serviceFees > 0 && (
                    <div className="col-lg-3 my-1">
                      <ActionButton
                        className={"btn-success requestBtn mx-0 mx-auto w-100"}
                        type={"requests"}
                        label={translate("web.approvePay", "Approve And Pay")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submitPay", "Submit And Pay")}
                          </>
                        }
                        margin={"0"}
                        onClick={() =>
                          handelFormSubmit("Draft", "yes")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}

                  {(getServiceDefinitionByIdData.serviceFees === 0 || formModel.eventName === "SentBack") && (
                    <div className="col-lg-2 my-sm-1">
                      <ActionButton
                        type={"requests"}
                        className="btn-success text-white requestBtn w-100 mx-auto"
                        label={translate("web.submit", "Submit")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submit", "Submit")}
                          </>
                        }
                        margin={"auto 25px"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Submit", "")
                        }
                        disabled={
                          getServiceDefinitionByIdData.serviceFees === 0 || formModel.eventName === "SentBack"
                            ? false
                            : true
                        }
                      />
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
