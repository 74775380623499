import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchCreateOrEditHorseOwnershipCertificatesServiceRequestthunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetGetServicesRequestByIdData,
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import EahsForm from "../components/EahsForm";

export default function RequestForOwnedEAHSRegisteredHorsesCertificate() {
  //env
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  //const UMBRACOURL = process.env.REACT_APP_Local_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
    createOrEditHorseOwnershipCertificatesServiceRequestData
  } = useSelector((state) => state.servicesRequestsSlice);

  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

  const dispatch = useDispatch();

  const { translate } = useTranslate();

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    eventName: "",
    comment: "",
    ownerComment: "",
    referenceNumber: "",
    requestsType: "",
    paymentStatus: "",
    ownedRegisteredHorseCertificateId: null,
    typeOfCertificate: "ToWhomItMayConcern",
  });

  const _isOnlinePayment = useRef("");

  const [checkedItems, setCheckedItems] = useState({
    option1: false,
  });

  const [profile, setProfile] = useState({
    label:
      !!userProfile &&
      userProfile?.ownerRegisterNo +
      " - " +
      userProfile[`fullName${currentLanguage === "en" ? "" : "Ar"}`],
    value: localStorage.getItem("EAHSUserID"),
  });
  const subAccountId = localStorage.getItem("currentSubAccountId");

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  //function
  const handleFormChange = (e, input) => {
    setFormModel({
      ...formModel,
      [e.target.name]: e.target.value,
    });
  };

  const validInputs = () => {
    let valid = true;

    //terms
    if (
      !checkedItems.option1
    ) {
      toast.error(
        translate("web.pleaseCheckAllTerms", "Please Check All Terms")
      );
      valid = false;
    }

    return valid;
  }

  const handelFormSubmit = (status, isOnlinePayment) => {
    if (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice" && formModel.eventName !== "SentBack") {
      const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

      const encodeURI = encodeURIComponent(returnUrl);

      window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=13&userId=${localStorage.getItem("EAHSUserID")}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment}&lang=${currentLanguage}`;
    }
    else {
      if (
        validInputs()
      ) {
        _isOnlinePayment.current = isOnlinePayment;

        let params = {
          id: id,
          ownerId: getUserID(),
          certificateOption: formModel.typeOfCertificate,
          status: status,
          ownerComment: formModel.ownerComment,
        };

        dispatch(FetchCreateOrEditHorseOwnershipCertificatesServiceRequestthunk(params));
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    setProfile(element);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  function CheckIfAgentHasPermission() {
    const AgentHasPermission = userProfile.subAccounts
      .filter((x) => x.value == subAccountId)[0]
      .ownersPermissionsList.some(
        (x) =>
          x.permissionName === "HorseOwnershipCertificates" || x.permissionName === "All"
      );
    return AgentHasPermission;
  }

  const getUserID = () => {
    const userID =
      !!userProfile && userProfile?.accountType !== "Agent" ? profile.value : subAccountId;
    return userID;
  };

  const getSelectedAccountName = () => {
    const selectAccount = userProfile.subAccounts.filter(
      (x) => x.value == subAccountId
    );

    if (selectAccount.length > 0) {
      return selectAccount[0];
    }
    else {
      return false;
    }
  };

  const dismissAll = () => toast.dismiss();

  //Effect
  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      dispatch(FetchGetServicesRequestByIdthunk(params));
    }

    return () => {
      dispatch(resetGetServicesRequestByIdData());
    };
  }, []);

  useEffect(() => {
    if (!!userProfile && userProfile?.haveProfileSignture != true) {
      toast.error(
        translate(
          "web.completeOwnerProfileData",
          "Please complete the personal data of the profile."
        )
      );

      setTimeout(() => {
        navigate("/profile");
      }, 3000);
    }

    if (!!userProfile && userProfile?.accountType === "Agent") {
      if (userProfile.id == subAccountId) {
        navigate("/403-Not-Authrized");
      }

      if (!CheckIfAgentHasPermission()) {
        navigate("/403-Not-Authrized");
      }
    }

    let subOwnerProfilesParams = {
      id: getUserID(),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));
  }, [currentLanguage]);

  useEffect(() => {
    if (
      Object.keys(getServicesRequestByIdData).length > 0 && (getServicesRequestByIdData.serviceDefinitionId == serviceId)
    ) {
      setFormModel({
        ...formModel,
        eventName: getServicesRequestByIdData.eventName,
        comment: getServicesRequestByIdData.comment,
        requestsType: getServicesRequestByIdData.requestsType,
        paymentStatus: getServicesRequestByIdData.paymentStatus,
        referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
        ownedRegisteredHorseCertificateId: getServicesRequestByIdData.horseOwnershipCertificate.id,
        typeOfCertificate: getServicesRequestByIdData.horseOwnershipCertificate.certificateOption,
        ownerComment: getServicesRequestByIdData.horseOwnershipCertificate.ownerComment != null ? getServicesRequestByIdData.horseOwnershipCertificate.ownerComment : "",
      });

      //another owner
      if (
        getServicesRequestByIdData.horseOwnershipCertificate.ownerId !==
        getServicesRequestByIdData.horseOwnershipCertificate.createdBy &&
        subOwnerProfilesByIdData.length > 0 &&
        getServicesRequestByIdData.requestsType !== "BackOffice"
      ) {
        //update profile        
        setProfile({
          label:
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.horseOwnershipCertificate.ownerId
            )[0].registerNo +
            " - " +
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.horseOwnershipCertificate.ownerId
            )[0][`text${currentLanguage === "en" ? "" : "Ar"}`],
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.horseOwnershipCertificate.ownerId
          )[0].value,
        });
      }

      //options
      setCheckedItems({
        option1: true,
      });
    }
  }, [
    getServicesRequestByIdData,
  ]);

  useEffect(() => {
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_isOnlinePayment.current !== "") {
        toast.success(
          translate(
            "web.yourRequestHasSubmittedSuccessfully",
            "Your Request Has Submitted Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        if (_isOnlinePayment.current !== "") {
          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${createOrEditHorseOwnershipCertificatesServiceRequestData.id}&paymentType=13&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${_isOnlinePayment.current}&lang=${currentLanguage}`;
        } else {
          navigate(`/profile?activeTab=${"e_services"}`);
        }
      }, 1000);
    }

    //error
    else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
      toast.error(
        translate(toasterMessageServicesRequests, toasterMessageServicesRequests)
      );

      dispatch(resetToasterMessageServicesRequests());
    }
  }, [toasterMessageServicesRequests]);

  //consoles
  console.log(formModel)

  return (
    <div className="container-fluid container-fluid-padding">
      {/* loading div */}
      <div
        className={`page-overlay text-center ${servicesRequestsLoading
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>
      {/* toast */}
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />
      {/* content page */}
      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl">
          {/* owned Registered Horse Certificate title */}
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage === "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>
          {/* Selected Account */}
          {subOwnerProfilesByIdData.length > 0 &&
            (action == null || action === "edit") &&
            (
              <div className="col-md-6 col-sm-12 mb-3">
                {!!userProfile && userProfile?.accountType !== "Agent" ? (
                  <>
                    <label className="capatalize_text">
                      {translate("web.selectedAccount", "Selected Account")}
                    </label>
                    <Select
                      className="mt-10 zindex200"
                      options={[
                        {
                          label:
                            userProfile?.ownerRegisterNo +
                            " - " +
                            userProfile[
                            `fullName${currentLanguage === "en" ? "" : "Ar"}`
                            ],
                          value: localStorage.getItem("EAHSUserID"),
                        },
                        ...(!!subOwnerProfilesByIdData &&
                          !!subOwnerProfilesByIdData.length > 0 &&
                          subOwnerProfilesByIdData.map((a) => ({
                            label:
                              a?.registerNo +
                              " - " +
                              a[`text${currentLanguage === "en" ? "" : "Ar"}`],
                            value: a.value,
                          }))),
                      ]}
                      value={profile}
                      onChange={(e) => {
                        handelSelectedAccountChange(e);
                      }}
                      placeholder={localStorage.getItem("EAHSUserFullName")}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      styles={customStyles}
                      key={"EAHSUserFullName"}
                    />
                  </>
                ) : (
                  <label className="capatalize_text">
                    {getSelectedAccountName()?.registerNo +
                      " - " +
                      getSelectedAccountName()[
                      `text${currentLanguage === "en" ? "" : "Ar"}`
                      ]}
                  </label>
                )}
              </div>
            )}

          {/* type Of Certificate*/}
          <EahsForm title={translate("web.typeOfCertificate", "Type Of Certificate")}>

            <div className="col-md-6 col-sm-12 rtl">
              <div className="col-md-12 col-sm-12 rtl p0 mt-3">
                <NetaqInput
                  name={"typeOfCertificate"}
                  type={"radioServiceRequest"}
                  value={"ToWhomItMayConcern"}
                  label={translate(
                    "web.concernCertificate",
                    "To whom it may concern certificate of my registered Arabian horses with EAHS"
                  )}
                  onChange={handleFormChange}
                  checked={formModel.typeOfCertificate === "ToWhomItMayConcern"}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>

              <div className="col-md-12 col-sm-12 rtl p0 mt-3">
                <NetaqInput
                  name={"typeOfCertificate"}
                  type={"radioServiceRequest"}
                  value={"ResultsOfRegisteredHorses"}
                  label={translate(
                    "web.resultsCertificate",
                    "Results of horses registered under our records with EAHS"
                  )}
                  onChange={handleFormChange}
                  checked={formModel.typeOfCertificate === "ResultsOfRegisteredHorses"}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>

              {
                // <div className="col-md-12 col-sm-12 rtl p0 mt-3">
                //   <NetaqInput
                //     name={"typeOfCertificate"}
                //     type={"radioServiceRequest"}
                //     value={"ResultsForSpecificShows"}
                //     label={translate(
                //       "web.resultsForSpecificShows",
                //       "Results of horses registered under our records with EAHS"
                //     )}
                //     onChange={handleFormChange}
                //     checked={formModel.typeOfCertificate === "ResultsForSpecificShows"}
                //     disabled={true}
                //   />
                // </div>
                // <div className="col-md-12 col-sm-12 rtl p0 mt-3">
                //   <NetaqInput
                //     name={"typeOfCertificate"}
                //     type={"radioServiceRequest"}
                //     value={"ResultsFromLastTwoYears"}
                //     label={translate(
                //       "web.resultsFromLastTwoYears",
                //       "Results of horses registered under our records with EAHS"
                //     )}
                //     onChange={handleFormChange}
                //     checked={formModel.typeOfCertificate === "ResultsFromLastTwoYears"}
                //     disabled={true}
                //   />
                // </div>
              }
            </div>
          </EahsForm>
          {/* Comments */}
          {(!!formModel.comment ||
            formModel.eventName === "SentBack") && (
              <EahsForm title={translate("web.comments", "Comments")}>
                {!!formModel.comment && (
                  // eahs Comment
                  <div className="col-md-6 col-sm-12">
                    <NetaqInput
                      label={translate("web.eahsComments", "EAHS Comments")}
                      name={"adminComment"}
                      type={"textarea"}
                      maxchars={1150}
                      value={formModel.comment}
                      disabled={true}
                    />
                  </div>
                )}

                {(
                  formModel.eventName === "SentBack"
                  //|| formModel.eventName === "PendingPayment"
                ) && (
                    // owner Comment 
                    <div className="col-md-6 col-sm-12">
                      <NetaqInput
                        label={translate("web.ownerComment", "Owner Comment")}
                        name={"ownerComment"}
                        type={"textarea"}
                        maxchars={1150}
                        value={formModel.ownerComment}
                        onChange={handleFormChange}
                      />
                    </div>
                  )}
              </EahsForm>
            )}

          {/* terms */}
          <EahsForm title={translate("web.terms", "Terms")}>
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.ServiceGeneralNote",
                  "Service General Note"
                )}
                name="option1"
                checked={checkedItems.option1}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
          </EahsForm>

          {/* buttons */}
          <div className="col-md-12 col-sm-12 mt-5 text-end">
            {/* draft/pay/submit btn */}
            {(
              (action == null || action === "edit")
              ||
              (
                action === "approve"
                &&
                (
                  formModel.eventName === "SentBack"
                  ||
                  (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice")
                )
              )
            ) && (
                <div className="row mx-0 justify-content-lg-end justify-content-sm-start">
                  {(formModel.eventName === "" || formModel.eventName === "Draft") &&
                    <div className="col-lg-2 my-1">
                      <ActionButton
                        type={"bootstrapbtn"}
                        className="btn buttonformshowentrycard height46 linkbtn w-100"
                        label={translate("web.draft", "Draft")}
                        text={`<i class="fa fa-save fa-lg marginrightleft-10"></i>                   
                          ${translate("web.draft", "Draft")}
                          `}
                        margin={"auto 10px"}
                        width={"100%"}
                        onClick={() => handelFormSubmit("Draft", "")}
                        disabled={
                          formModel.eventName === "" ||
                            formModel.eventName === "Draft"
                            ? false
                            : true
                        }
                      />
                    </div>
                  }

                  {userProfile?.participateWithoutPayment === true && formModel.eventName !== "SentBack" && getServiceDefinitionByIdData.serviceFees > 0 && (
                    <div className="col-lg-3 my-1">
                      <ActionButton
                        className="btn-info text-white mx-auto"
                        type={"bootstrapbtn"}
                        text={`<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>                   
                          ${translate("web.submitAndPayLater", "Submit And Pay Later")}
                          `}
                        margin={"0"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Draft", "no")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}

                  {formModel.eventName !== "SentBack" && getServiceDefinitionByIdData.serviceFees > 0 && (
                    <div className="col-lg-3 my-1">
                      <ActionButton
                        className={"btn-success requestBtn mx-0 mx-auto w-100"}
                        type={"requests"}
                        label={translate("web.approvePay", "Approve And Pay")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submitPay", "Submit And Pay")}
                          </>
                        }
                        margin={"0"}
                        onClick={() =>
                          handelFormSubmit("Draft", "yes")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}

                  {(getServiceDefinitionByIdData.serviceFees === 0 || formModel.eventName === "SentBack") && (
                    <div className="col-lg-2 my-sm-1">
                      <ActionButton
                        type={"requests"}
                        className="btn-success text-white requestBtn w-100 mx-auto"
                        label={translate("web.submit", "Submit")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submit", "Submit")}
                          </>
                        }
                        margin={"auto 25px"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Submit", "")
                        }
                        disabled={
                          getServiceDefinitionByIdData.serviceFees === 0 || formModel.eventName === "SentBack"
                            ? false
                            : true
                        }
                      />
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
