export function isLessThanUndefinedYearOld(date, numberOfYears) {

  const currentDate = new Date();

  let years = currentDate.getFullYear() - date.getFullYear();
  let months = currentDate.getMonth() - date.getMonth();
  let days = currentDate.getDate() - date.getDate();

  // Adjust for negative days
  if (days < 0) {
    months -= 1;
  }

  // Adjust for negative months
  if (months < 0) {
    years -= 1;
    months += 12;
  }

  // Convert everything to total months difference for comparison
  const totalMonthsDifference = years * 12 + months + (days >= 0 ? 0 : -1);

  // Convert the dynamic number of years to months
  const maxMonths = numberOfYears * 12;

  // Return true if the total difference is within the specified number of years
  return totalMonthsDifference < maxMonths;

  // const yearsDifference = currentDate.getFullYear() - date.getFullYear();
  // return yearsDifference < numberOfYears

}
