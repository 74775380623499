export function normalDateFormat(date, isbirthDate = false) {

    if (isbirthDate) {
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
        let input = date;
        // Check if the input matches the pattern
        if (regex.test(input)) {
            // Extract and return only the "YYYY-MM-DD" part
            let x = input.split('T')[0];
            return x;
        } else {
            // Return the original input if it doesn't match
            return input;
        }
    } else {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
}