import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchGetShowJournalistRequestTermsDatathunk,
  FetchSubmitJournalistRequestthunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import SimpleFileUpload from "../components/SimpleFileUpload";
import EahsForm from "../components/EahsForm";
import { isValidUrl } from "../helper/validateURL";

export default function PressCoverageRequest() {
  //env

  //selector
  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getShowJournalistRequestTermsDataData
  } = useSelector((state) => state.servicesRequestsSlice);

  const dispatch = useDispatch();

  const { translate } = useTranslate();

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const showId = searchParams.has("showId") ? searchParams.get("showId") : null;

  //state
  const [formModel, setFormModel] = useState({
    name: "",
    email: "",
    mobileNo: "",
    gender: "Male",
    organizerType: "Freelancer",
    organizationName: "",
    link: "",
    websiteOrSocialMediaLinks: [],
    idOrPassportCopyAttachmentFile: null,
    isPolicyAccepted: false
  });

  const [checkedItems, setCheckedItems] = useState({
    option1: false,
    option2: false,
    option3: false,
  });

  const [errorName, setErrorName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailRegex, setErrorEmailRegex] = useState("");
  const [errorURLFormat, setErrorURLFormat] = useState("");
  const [errorMobileNo, setErrorMobileNo] = useState(false);
  const [errorIdOrPassportCopy, setErrorIdOrPassportCopy] = useState(false);
  const [errorOrganizationName, setErrorOrganizationName] = useState(false);
  const [errorWebsiteOrSocialMediaLinks, setErrorWebsiteOrSocialMediaLinks] = useState(false);

  // Regular expression for validating email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // URL validation regex with full path accept all
  // const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{2,}\.[a-zA-Z]{2,})(\/[^\s]*)?$/;


  const [
    idOrPassportCopyAttachmentFile,
    setIdOrPassportCopyAttachmentFile,
  ] = useState([]);

  //function

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  const setPhoneNumber = (value) => {
    setFormModel((prev) => ({
      ...prev,
      mobileNo: value
    }));
  }

  const handleFormChange = (e, input) => {
    if (!!input && input === "organizerType") {
      setFormModel({
        ...formModel,
        organizerType: e.target.value,
        organizationName: "",
      });
    }
    if (!!input && input === "email") {
      const value = e.target.value;

      setFormModel({
        ...formModel,
        email: value,
      });

      // Validate email
      if (!emailRegex.test(value)) {
        setErrorEmailRegex(translate("web.invalidEmailAddress", "Invalid email address"));
      } else {
        setErrorEmailRegex("");
      }
    }
    else if (!!input && input === "isPolicyAccepted") {
      setFormModel({
        ...formModel,
        isPolicyAccepted: e.target.checked,
      });
    }
    else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleAddLink = () => {
    if (
      formModel.link !== "" && isValidUrl(formModel.link)
    ) {
      setFormModel({
        ...formModel,
        websiteOrSocialMediaLinks: [...formModel.websiteOrSocialMediaLinks, formModel.link],
        link: "",
      });
    }

    if (!isValidUrl(formModel.link)) {
      setErrorURLFormat(translate("web.invalidURLFormat", "Invalid URL Format"));
    } else {
      setErrorURLFormat("");
    }
  };

  const handleRemoveLink = (index) => {
    const updatedLinks = formModel.websiteOrSocialMediaLinks.filter((_, i) => i !== index);

    setFormModel({
      ...formModel,
      websiteOrSocialMediaLinks: updatedLinks,
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  const handleUploadIdOrPassportCopyChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          idOrPassportCopyAttachmentFile: e.target.files[0],
        });

        setIdOrPassportCopyAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const validInputs = () => {
    let valid = true;

    if (
      formModel.name === ""
    ) {
      setErrorName(true);
      valid = false;
    }
    else {
      setErrorName(false);
    }

    if (
      formModel.email === ""
    ) {
      setErrorEmail(true);
      valid = false;
    }
    else {
      setErrorEmail(false);
    }

    if (
      errorEmailRegex
    ) {
      valid = false;
    }

    if (
      formModel.mobileNo === ""
    ) {
      setErrorMobileNo(true);
      valid = false;
    }
    else {
      setErrorMobileNo(false);
    }

    if (
      idOrPassportCopyAttachmentFile.length === 0
    ) {
      setErrorIdOrPassportCopy(true);
      valid = false;
    }
    else {
      setErrorIdOrPassportCopy(false);
    }

    if (
      formModel.organizerType === "Organization" && formModel.organizationName === ""
    ) {
      setErrorOrganizationName(true);
      valid = false;
    }
    else {
      setErrorOrganizationName(false);
    }

    if (
      formModel.websiteOrSocialMediaLinks.length === 0
    ) {
      if (!formModel?.link ?? true) {
        setErrorWebsiteOrSocialMediaLinks(true);
        valid = false;
      }
    }
    else {
      setErrorWebsiteOrSocialMediaLinks(false);
    }

    //terms
    if (
      // !checkedItems.option1 || !checkedItems.option2 || !checkedItems.option3
      !formModel.isPolicyAccepted
    ) {
      toast.error(
        translate("web.pleaseCheckTerm", "Please Check Term")
      );
      valid = false;
    }

    if (
      !valid
    ) {
      toast.error(
        translate("web.fillAllRequiredFields", "Fill All Required Fields")
      );
    }

    return valid;
  }

  const handelFormSubmit = () => {
    if (
      validInputs()
    ) {
      let params = {
        showId: '',
        name: formModel.name,
        email: formModel.email,
        mobileNo: formModel.mobileNo,
        gender: formModel.gender,
        idOrPassportCopy: formModel.idOrPassportCopyAttachmentFile,
        organizerType: formModel.organizerType,
        organizationName: formModel.organizationName,
        websiteOrSocialMediaLinks: [formModel?.link, ...formModel.websiteOrSocialMediaLinks],
        isPolicyAccepted: formModel.isPolicyAccepted,
      };

      dispatch(FetchSubmitJournalistRequestthunk(params));
    }
  };

  const dismissAll = () => toast.dismiss();

  //Effect
  useEffect(() => {
    let params = {
      showId: showId,
    };

    dispatch(FetchGetShowJournalistRequestTermsDatathunk(params));
  }, []);

  useEffect(() => {
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      toast.success(
        translate("web.dataSavedSuccessfully", "Data Saved Successfully")
      );

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        navigate(`/`);
      }, 1000);
    }

    //error
    else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
      toast.error(
        translate(toasterMessageServicesRequests, toasterMessageServicesRequests)
      );

      dispatch(resetToasterMessageServicesRequests());
    }
  }, [toasterMessageServicesRequests]);

  //consoles
  console.log(formModel)

  return (
    <div className="container-fluid px-5 rtl">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${servicesRequestsLoading
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>
      {
        //toast
      }
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />
      {
        //content page
      }

      {
        //Journalist Request title
      }
      <div className="col-md-12 col-sm-12 mb-4">
        <h3 className="fontWeight display-inline">
          {translate("web.mediaRequest", "Media Request") + " - "}
        </h3>
        <h3 className="fontWeight display-inline">
          {getShowJournalistRequestTermsDataData?.showName}
        </h3>
      </div>

      <EahsForm title={translate("web.personalDetails", "Personal Details")}>
        <div className="col-md-6 col-sm-12 mt-3">
          <NetaqInput
            label={translate("web.name", "Name")}
            required={true}
            name={"name"}
            type={"text"}
            value={formModel.name}
            onChange={handleFormChange}
          />
          {
            errorName && (
              <span style={{ color: "red" }}>
                {translate("web.requiredField", "Required Field")}
              </span>
            )}
        </div>

        <div className="col-md-6 col-sm-12 mt-3">
          <NetaqInput
            label={translate("web.email", "Email")}
            required={true}
            name={"email"}
            type={"text"}
            value={formModel.email}
            onChange={(e) => handleFormChange(e, "email")}
          />
          {
            errorEmail && (
              <span style={{ color: "red", marginLeft: 5, marginRight: 5 }}>
                {translate("web.requiredField", "Required Field")}
              </span>
            )}
          {
            errorEmailRegex && (
              <span style={{ color: "red" }}>
                {errorEmailRegex}
              </span>
            )}
        </div>

        <div className="col-md-6 col-sm-12 mt-3">
          <NetaqInput
            type={'phone'}
            label={translate("web.mobileNo", "Mobile No")}
            name={'mobileNo'}
            placeholder={'971xxxxxxxxx'}
            required={true}
            onChange={setPhoneNumber}
            setIsPhoneNumberValid={setIsPhoneNumberValid}
          />
          {!isPhoneNumberValid &&
            <div className='text-danger'>
              {translate('web.phoneNumberIsNotValid', 'Phone Number Is Not Valid')}
            </div>
          }
          {
            errorMobileNo && (
              <span style={{ color: "red" }}>
                {translate("web.requiredField", "Required Field")}
              </span>
            )}
        </div>

        <div className="col-md-6 col-sm-12 mt-4">
          <label>
            {translate("web.gender", "Gender")}
            <i className="colorred"> *</i>
          </label>
          <div className="row mt-1 mx-0 rtl">
            <div className="col-md-4 col-sm-5 col-6 text-start" >
              <NetaqInput
                name={"gender"}
                type={"radioServiceRequest"}
                value={"Male"}
                label={`${translate("web.male", "Male")}`}
                onChange={(e) => handleFormChange(e, "gender")}
                checked={
                  formModel.gender === "Male"
                }
              />
            </div>
            <div className="col-md-4 col-sm-5 col-6 text-start" >
              <NetaqInput
                name={"gender"}
                type={"radioServiceRequest"}
                value={"Female"}
                label={`${translate("web.female", "Female")}`}
                onChange={(e) => handleFormChange(e, "gender")}
                checked={
                  formModel.gender === "Female"
                }
              />
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-12 mt-4">
          <label>
            {translate("web.organizerType", "Organizer Type")}
            <i className="colorred"> *</i>
          </label>
          <div className="row mt-1 mx-0 rtl flex-wrap " >
            <div className="col-md-4 col-sm-5 col-6 text-start" style={{minWidth:130}}>
              <NetaqInput
                name={"organizerType"}
                type={"radioServiceRequest"}
                value={"Freelancer"}
                label={`${translate("web.freelancer", "Freelancer")}`}
                onChange={(e) => handleFormChange(e, "organizerType")}
                checked={
                  formModel.organizerType === "Freelancer"
                }
              />
            </div>
            <div className="col-md-4 col-sm-5 col-6 text-start" style={{minWidth:130 }}>
              <NetaqInput
                name={"organizerType"}
                type={"radioServiceRequest"}
                value={"Organization"}
                label={`${translate("web.organization", "Organization")}`}
                onChange={(e) => handleFormChange(e, "organizerType")}
                checked={
                  formModel.organizerType === "Organization"
                }
              />
            </div>



          </div>
        </div>

        <div className="col-md-6 col-sm-12 mt-4">
          {
            formModel.organizerType === "Organization" && (
              <NetaqInput
                label={translate("web.organizationName", "Organization Name")}
                required={true}
                name={"organizationName"}
                type={"text"}
                value={formModel.organizationName}
                onChange={handleFormChange}
              />
            )}
          {
            errorOrganizationName && (
              <span style={{ color: "red" }}>
                {translate("web.requiredField", "Required Field")}
              </span>
            )}
        </div>

        <div className="col-md-6 col-sm-12 mt-3 align-items-center">
          <label>
            {translate("web.idOrPassport", "Id Or Passport")}
            <i className="colorred"> *</i>
          </label>
          <SimpleFileUpload
            isMulti={false}
            initialFiles={idOrPassportCopyAttachmentFile}
            isServer={false}
            onChange={handleUploadIdOrPassportCopyChange}
            accept={"image/*,application/pdf"}
          />
          {
            errorIdOrPassportCopy && (
              <span style={{ color: "red" }}>
                {translate("web.requiredField", "Required Field")}
              </span>
            )}
        </div>
      </EahsForm>

      <EahsForm title={translate("web.websiteOrSocialMediaLinks", "Website Or Social Media Links")}>
        <div className="col-md-4 col-sm-12">
          <NetaqInput
            label={translate("web.link", "Link")}
            required={true}
            name={"link"}
            type={"text"}
            value={formModel.link}
            onChange={handleFormChange}
          />
          {
            errorURLFormat && (
              <span style={{ color: "red", marginLeft: 5, marginRight: 5 }}>
                {errorURLFormat}
              </span>
            )}
          {
            errorWebsiteOrSocialMediaLinks && (
              <span style={{ color: "red" }}>
                {translate("web.requiredField", "Required Field")}
              </span>
            )}
        </div>

        <div className="col-md-2 col-sm-12 " style={{ marginTop: 27 }}>
          <ActionButton
            type={"requests"}
            className="btn-success text-white"
            label={translate("web.add", "Add")}
            text={
              <>
                <i className="fa-solid fa-plus fa-lg marginrightleft-10"></i>
                {translate("web.add", "Add")}
              </>
            }
            onClick={handleAddLink}
          />
        </div>

        {
          formModel.websiteOrSocialMediaLinks.map((item, index) => {
            return (
              <div className="col-md-12 col-sm-12 mt-3 row rtl" key={index}>
                <div className="col-md-4 col-sm-12">
                  <NetaqInput
                    name={`${index}`}
                    type={"text"}
                    value={item}
                    disabled={true}
                  />
                </div>
                <div className="col-md-2 col-sm-12 mt-4">
                  <ActionButton
                    type={"requests"}
                    className="btn-danger text-white"
                    label={translate("web.remove", "Remove")}
                    text={
                      <>
                        <i className="fa-solid fa-trash-alt fa-lg marginrightleft-10"></i>
                        {translate("web.remove", "Remove")}
                      </>
                    }
                    onClick={() => handleRemoveLink(index)}
                  />
                </div>
              </div>
            );
          })}
      </EahsForm>

      <EahsForm title={translate("web.declarationTerms", "Declaration & Terms")}>
        <div className="col-md-12 col-sm-12 rtl p0" dangerouslySetInnerHTML={{ __html: getShowJournalistRequestTermsDataData?.terms }}>
        </div>

        <div className="col-md-12 col-sm-12 rtl p0">
          <NetaqInput
            type={"checkbox"}
            label={translate(
              "web.isPolicyAcceptedTerm",
              "By submitting this form, you acknowledge and agree to the above terms"
            )}
            name="isPolicyAccepted"
            checked={formModel.isPolicyAccepted}
            onChange={(e) => handleFormChange(e, "isPolicyAccepted")}
          />
        </div>
      </EahsForm>



      <div className="col-md-10 col-sm-12 mt-5">
      </div>
      <div className="col-md-2 col-sm-12 mt-5">
        <ActionButton
          type={"requests"}
          className="btn-success text-white requestBtn w-100"
          label={translate("web.submit", "Submit")}
          text={
            <>
              <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
              {translate("web.submit", "Submit")}
            </>
          }
          margin={"auto 25px"}
          width={"100%"}
          onClick={() =>
            handelFormSubmit("Submit", "")
          }
        />
      </div>
    </div >
  );
}
