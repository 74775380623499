import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Popup from 'reactjs-popup'
const BaseURL = process.env.REACT_APP_BASE_URL;
const MediaBaseURL = process.env.REACT_APP_UMBRACO;

export default function OrgChartCard({ cardInfo = {}, translate, isChild = false, child, isFirstLevel = false, index, isVertical = false, isMobileView = false, isRelative = false, addBottomSpace = false }) {
    const currentLanguage = localStorage.getItem('eahsLang') ?? 'en';
    const [isHovered, setIsHovered] = useState(false);
    const [top, setTop] = useState(0);
    const [right, setRight] = useState(0);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        if (isHovered) {
            setTimeout(() => {
                setTop(-100);
                setRight(-250);
                setOpacity(1);
            }, 100);

        } else {
            setTop(-100);
            setRight(-250);
            setOpacity(0);
        }
    }, [isHovered])


    return (
        <>
            <div onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)} className={`OrgChartHeaderCard position-relative  ${isFirstLevel && index == 0 && 'isFirstOrgChartCard'}`} style={isMobileView ? { marginTop: -35, } : { marginBottom: addBottomSpace ? 20 : 0 }}>
                {!isChild && !!cardInfo?.img &&
                    <div className='mx-auto p-0 mb-2' style={{ width: 100, height: 100, borderRadius: 50, marginTop: -65, borderTop: '4px solid #C89C66', borderLeft: '4px solid #C89C66', overflow: 'hidden', transform: 'rotate(45deg)' }}>
                        <img src={`${MediaBaseURL}${cardInfo?.img}`} className='w-100' style={{ transform: 'rotate(-45deg)', marginTop: -0 }} alt='orgChartImage' />
                    </div>
                }
                {cardInfo?.name &&
                    <span dangerouslySetInnerHTML={{ __html: cardInfo?.name }} />
                }
                {!!child &&
                    <>
                        <span>{child?.content?.properties?.serviceCategoryName}</span>
                        <p>
                            {child?.content?.properties?.serviceCategoryIcon}
                        </p>
                    </>
                }
                {!isChild &&
                    <p dangerouslySetInnerHTML={{ __html: cardInfo?.title }} />
                }
                {isHovered && (cardInfo?.email || cardInfo?.phoneNumber) &&
                    <div className='OrgChartHeaderCard rtl  position-absolute pt-0 d-flex align-items-center justify-content-center  flex-column ' style={{ top: top, right: currentLanguage == 'ar' ? right : 'unset', left: currentLanguage == 'ar' ? 'unset' : right, border: '2px solid #C89C66', borderRadius:  '50% 50% 0 50%' , opacity: opacity, transition: 'all 500ms' }} >
                        {cardInfo?.phoneNumber &&
                            <>
                                <h5 className='text-start fw-bold  mt-3 justRotate' style={{ minWidth: 135 }} >{translate('web.phone', 'Phone')}</h5>
                                <Link to={`tel:${cardInfo?.phoneNumber}`} className='text-start text-dark d-block justRotate' style={{ minWidth: 135 }} ><i className='fa fa-phone me-1'></i> {cardInfo?.phoneNumber}</Link>
                            </>}
                        {cardInfo?.email &&
                            <>
                                <h5 className='text-start fw-bold mt-3 justRotate' style={{ minWidth: 135 }} >{translate('web.email', 'Email')}</h5>
                                <Link to={`mailto:${cardInfo?.email}`} className='text-start  text-dark d-block justRotate' style={{ minWidth: 135 }} ><i className='fa fa-envelope me-1'></i>{cardInfo?.email}</Link>
                            </>}
                    </div>
                }
                {cardInfo?.isViewMore && !isChild &&
                    <Popup
                        key={cardInfo.name}
                        trigger={<div className='hoveronlink ViewProfileLink  rtl'>
                            {translate("web.viewDetails", "View Details")}
                            <i
                                className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"
                            ></i>
                            <i
                                className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"
                            ></i>
                            <i
                                className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"
                            >
                            </i>
                        </div>}
                        modal
                        overlayStyle={{ zIndex: 5000 }}
                        contentStyle={{ maxWidth: 600, padding: '15px 15px', border: '4px solid rgb(200, 156, 102)', margin: 0, marginLeft: 'auto', }}
                    >
                        {(close) => (
                            <div >
                                <div className="position-absolute closeDivAboluste bg-white" onClick={close} style={{ borderColor: 'rgb(200, 156, 102)', }} > <i className="fa fa-times " style={{ color: 'rgb(200, 156, 102)' }} ></i> </div>
                                <div className='row rtl pt-3 ' >
                                    <div className='col-3'>
                                        {cardInfo?.img &&
                                            <img src={`${MediaBaseURL}${cardInfo?.img}`} className=' rounded-circle px-0 mx-auto w-100' style={{ border: '4px solid rgb(200, 156, 102)', }} alt='profile' />
                                        }
                                    </div>
                                    <div className='col-9 pt-3' >
                                        {cardInfo?.name &&
                                            <h3 className='fw-bold'>{cardInfo?.name}</h3>
                                        }
                                        {cardInfo?.title &&
                                            <h6>{cardInfo?.title}</h6>
                                        }
                                    </div>
                                    <div className='col-12 judgesList' style={{ overflowY: 'auto', maxHeight: '70vh' }}>
                                        {cardInfo?.about &&
                                            <>
                                                <h5 className='text-start fw-bold mt-3' >{cardInfo?.aboutTitle}</h5>
                                                <p className='text-start'>{cardInfo?.about}</p>
                                            </>
                                        }
                                        {cardInfo?.phoneNumber &&
                                            <>
                                                <h5 className='text-start fw-bold mt-3' >{translate('web.phone', 'Phone')}</h5>
                                                <Link to={`tel:${cardInfo?.phoneNumber}`} className='text-start text-dark d-block'><i className='fa fa-phone me-1'></i> {cardInfo?.phoneNumber}</Link>
                                            </>}
                                        {cardInfo?.email &&
                                            <>
                                                <h5 className='text-start fw-bold mt-3' >{translate('web.email', 'Email')}</h5>
                                                <Link to={`mailto:${cardInfo?.email}`} className='text-start  text-dark d-block'><i className='fa fa-envelope me-1'></i>{cardInfo?.email}</Link>
                                            </>}
                                        {(!!cardInfo?.twitterLink || !!cardInfo?.facebookLink || !!cardInfo?.instagramLink) &&
                                            <>
                                                <h5 className='text-start fw-bold mt-3' >{translate('web.socialMedia', 'Social Media')}</h5>
                                                <div className='row rtl'>
                                                    {!!cardInfo?.facebookLink &&
                                                        <Link to={cardInfo?.facebookLink} className='text-start' style={{ width: 'unset' }} >
                                                            <img
                                                                src={`${BaseURL}/assets/img/facebook.png`}
                                                                style={{ width: 30 }}
                                                                alt=""
                                                            />
                                                        </Link>
                                                    }
                                                    {!!cardInfo?.twitterLink &&
                                                        <Link to={cardInfo?.twitterLink} className='ms-1 text-start' style={{ width: 'unset' }} >
                                                            <img
                                                                style={{ width: 30 }}
                                                                src={`${BaseURL}/assets/img/twitter.png`}
                                                                alt=""
                                                            />
                                                        </Link>
                                                    }
                                                    {!!cardInfo?.instagramLink &&
                                                        <Link to={cardInfo?.instagramLink} className='ms-1 text-start' style={{ width: 'unset' }} >
                                                            <img
                                                                style={{ width: 30 }}
                                                                src={`${BaseURL}/assets/img/instagram.png`}
                                                                alt=""
                                                            />
                                                        </Link>
                                                    }

                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                            </div>
                        )}
                    </Popup>}
                {isVertical && cardInfo?.organizationalChartChildsList?.map((child, index) => (
                    <div className=' justRotate ms-3' style={{ borderLeft: 0, borderRadius: 0, position: 'absolute', top: (-50 + (index * 100 * 1)), right: currentLanguage == 'ar' ? 350 : 'unset', left: currentLanguage == 'ar' ? 'unset' : 350 }} >
                        {index < 1 &&
                            <>
                                <div className='OrgChartHeaderCardSpacer position-absolute' style={{ top: '3.1rem', left: -20, height: '6.3rem' }} />
                                <div className='OrgChartHorizentalHeaderCardSpacer position-absolute' style={{ top: 100, left: -40 }} />
                                <div className='OrgChartHorizentalHeaderCardSpacer position-absolute' style={{ top: 50, left: -20 }} />
                                <div className='OrgChartHorizentalHeaderCardSpacer position-absolute' style={{ top: 150, left: -20 }} />
                            </>
                        }
                        <OrgChartCard isChild={true} child={child} translate={translate} />
                    </div>
                ))}

            </div>
            {!isVertical && cardInfo?.organizationalChartChildsList?.map((child) => (
                <div className='ClusterContainer ' style={isRelative ? { marginTop: index == 0 ? 50 : 0, marginBottom: 0, } : {}} >
                    <OrgChartCard isChild={true} child={child} translate={translate} />
                </div>
            ))}
        </>
    )
}
