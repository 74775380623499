import "./App.css";
import React from "react";
import HomePage from "./pages/HomePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuctionPage from "./pages/AuctionPage";
import Layout from "./components/Layout";
import LoginPage from "./pages/LoginPage";
import LoginUaePassPage from "./pages/LoginUaePassPage";
import LoginUaePassPageOTP from "./pages/LoginUaePassPageOTP";
import RegisterPage from "./pages/RegisterPage";
import AuctionDetailsPage from "./pages/AuctionDetailsPage";
import HorseProfile from "./pages/HorseProfile";
import BiddingPage from "./pages/BiddingPage";
import StudBookPage from "./pages/StudBookPage";
import OwnerProfile from "./pages/OwnerProfile";
import OwnerDetailsPage from "./pages/OwnerDetailsPage";
import HorseDetailsPage from "./pages/HorseDetailsPage";
import HorseMarket from "./pages/HorseMarket";
import HorseMarketProfile from "./pages/HorseMarketProfile";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import SingleServicePage from "./pages/SingleServicePage";
import SinglePost from "./pages/SinglePost";
import AboutUs from "./pages/AboutUs";
import NewsPage from "./pages/NewsPage";
import HorseSupplies from "./pages/HorseSupplies";
import SingleSupplier from "./pages/SingleSupplier";
import SingleTransportCompany from "./pages/SingleTransportCompany";
import HorseTransportCompanies from "./pages/HorseTransportCompanies";
import EquestrianCentersClubs from "./pages/EquestrianCentersClubs";
import SingleEquestrianCentersClubs from "./pages/SingleEquestrianCentersClubs";
import ContactUs from "./pages/ContactUs";
import ServicesPage from "./pages/ServicesPage";
import OrgChartPage from "./pages/OrgChartPage";
import Shows from "./pages/Shows";
import ShowDetails from "./pages/ShowDetails";
import WahoConference from "./pages/WahoConference";
import ShowEntryCard from "./pages/ShowEntryCard";
import ScrollToTop from "./components/scroll-to-top";
import CalendarPage from "./pages/CalendarPage";
import ShowEntryDetails from "./pages/ShowEntryDetails";
import AddRegisterHorse from "./pages/AddRegisterHorse";
import ShowEntryConflict from "./pages/ShowEntryConflict";
import ShowEntryTotalRegisteredHorses from "./pages/ShowEntryTotalRegisteredHorses";
import ShowEntryParticipantsRequest from "./pages/ShowEntryParticipantsRequest";
import ShowEntrySubmissionSuccessful from "./pages/ShowEntrySubmissionSuccessful";
import GalleryPage from "./pages/GalleryPage";
import AlbumPage from "./pages/AlbumPage";
import WahoTrophy from "./pages/WahoTrophy";
import BookTable from "./pages/BookTable";
import ShowsCategories from "./pages/ShowsCategories";
import AuctionEntryCard from "./pages/AuctionEntryCard";
import Auctionentrytotalregisteredhorses from "./pages/AuctionEntryTotalRegisteredHorses";
import AuctionEntrySubmissionSuccessful from "./pages/AuctionEntrySubmissionSuccessful";
import BookingSubmissionSuccessful from "./pages/BookingSubmissionSuccessful";
import OpendBooking from "./pages/OpendBooking";
import RequestHorseOwnershipTransferSeller from "./pages/RequestHorseOwnershipTransferSeller";
import ServicesRequestsPaymentSuccessful from "./pages/ServicesRequestsPaymentSuccessful";
import ComingSoon from "./components/ComingSoon";
import SingleHospital from "./pages/SingleHospital";
import HorseHospitals from "./pages/HorseHospitals";
import CoursesPage from "./pages/CoursesPage";
import SponsersPage from "./pages/SponsersPage";
import SingleTermsAndConditionsShowPage from "./pages/SingleTermsAndConditionsShowPage";
import EducationalPage from "./pages/EducationalPage";
import AddOrganizerRequest from "./pages/AddOrganizerRequest";
import LiveStream from "./pages/LiveStream";
import RequestCoveringCertificate from "./pages/RequestCoveringCertificate";
import AwardPage from "./pages/AwardPage";
import { toast, ToastContainer } from "react-toastify";
import ShowCatalog from "./pages/ShowCatalog";
import RequestEmbryoOocyteTransferPermit from "./pages/RequestEmbryoOocyteTransferPermit";
import LiveBiddingScreen from "./pages/LiveBiddingScreen";
import HybridBiddingPage from "./pages/HybridBiddingPage";
import RequestEmbryoTransferRightPurchaceAgreement from "./pages/RequestEmbryoTransferRightPurchaceAgreement";
import RequestRegisteringNewArabianHorseFoal from "./pages/RequestRegisteringNewArabianHorseFoal";
import RequestForOwnedEAHSRegisteredHorsesCertificate from "./pages/RequestForOwnedEAHSRegisteredHorsesCertificate";
import RequestForRegistrationOfAnArabianHorseStud from "./pages/RequestForRegistrationOfAnArabianHorseStud";
import RequestToUpdateArabianHorseData from "./pages/RequestToUpdateArabianHorseData";
import PressCoverageRequest from "./pages/PressCoverageRequest";
import RequestToDeclarationOfArabianHorseDeath from "./pages/RequestToDeclarationOfArabianHorseDeath";
import InactiveHorseRegistrationRequest from "./pages/InactiveHorseRegistrationRequest";
import RequestToAddARacingNameForAnImportedArabianHorse from "./pages/RequestToAddARacingNameForAnImportedArabianHorse";
import ReqestForIssuingAnArabianHorsePassportDuplicateDamaged from "./pages/ReqestForIssuingAnArabianHorsePassportDuplicateDamaged";
import ImportHorseFrozenSemen from "./pages/ImportHorseFrozenSemen";
import OpenApp from "./pages/OpenApp";
function App() {
  const dismissAll = () => toast.dismiss();

  return (
    // <Router basename={"/uat"}>
      <Router>
      <ScrollToTop />
      <Layout>
        <ToastContainer
          position="top-right"
          onClick={dismissAll}
          theme="colored"
          autoClose={5000}
        />

        <Routes>
          <Route path="/" Component={HomePage} />
          <Route path="/studbook" Component={StudBookPage} />
          <Route path="/auctions" Component={AuctionPage} />
          <Route path="/singleauction" Component={AuctionDetailsPage} />
          <Route path="/ownerdetails" Component={OwnerDetailsPage} />
          <Route path="/horsedetails" Component={HorseDetailsPage} />
          <Route path="/horsemarketprofile" Component={HorseMarketProfile} />
          <Route path="/login" Component={LoginPage} />
          <Route path="/loginuaepass" Component={LoginUaePassPage} />
          <Route path="/uaepassotp" Component={LoginUaePassPageOTP} />
          <Route path="/register" Component={RegisterPage} />
          <Route path="/horse-auction" Component={HorseProfile} />
          <Route path="/bidding" Component={BiddingPage} />
          <Route path="/fullScreenBidding" element={<LiveBiddingScreen />} />
          <Route path="/hybridBidding" element={<HybridBiddingPage />} />
          <Route path="/profile" Component={OwnerProfile} />
          <Route path="/horsemarket" Component={HorseMarket} />
          <Route path="/shows" Component={Shows} />
          <Route path="/showdetails" Component={ShowDetails} />
          <Route path="/forgetpassword" Component={ForgetPasswordPage} />
          <Route path="/singleservice" Component={SingleServicePage} />
          <Route path="/singlePost" Component={SinglePost} />
          <Route path="/about-us" Component={AboutUs} />
          <Route path="/news" Component={NewsPage} />
          <Route path="/horseSupplies" Component={HorseSupplies} />
          <Route path="/SingleSupplier" Component={SingleSupplier} />
          <Route
            path="/HorseTransportCompanies"
            Component={HorseTransportCompanies}
          />
          <Route
            path="/SingleTransportCompany"
            Component={SingleTransportCompany}
          />
          <Route path="/HorseHospitals" Component={HorseHospitals} />
          <Route path="/SingleHospital" Component={SingleHospital} />
          <Route
            path="/EquestrianCentersClubs"
            Component={EquestrianCentersClubs}
          />
          <Route
            path="/SingleEquestrianCentersClubs"
            Component={SingleEquestrianCentersClubs}
          />
          <Route path="/contactus" Component={ContactUs} />
          <Route path="/services" Component={ServicesPage} />
          <Route path="/orgChart" Component={OrgChartPage} />
          <Route path="/wahoconference" Component={WahoConference} />
          <Route path="/m-wahoconference" Component={WahoConference} />
          <Route path="/showentrycard" Component={ShowEntryCard} />
          <Route path="/calendar" Component={CalendarPage} />
          <Route path="/showentrydetails" Component={ShowEntryDetails} />
          <Route path="/addRegisterHorse" Component={AddRegisterHorse} />
          <Route path="/showentryconflict" Component={ShowEntryConflict} />
          <Route path="/courses" Component={CoursesPage} />
          <Route path="/sponsorsPage" Component={SponsersPage} />
          <Route
            path="/showentrytotalregisteredhorses"
            Component={ShowEntryTotalRegisteredHorses}
          />
          <Route
            path="/showentryparticipantsrequest"
            Component={ShowEntryParticipantsRequest}
          />
          <Route
            path="/showentrysubmissionsuccessful"
            Component={ShowEntrySubmissionSuccessful}
          />
          <Route path="/gallery" Component={GalleryPage} />
          <Route path="/album-details" Component={AlbumPage} />
          <Route path="/waho-trophy" Component={WahoTrophy} />
          <Route path="/book-table" Component={BookTable} />
          <Route path="/ads-book-table" element={<BookTable isAds={true} />} />
          <Route path="/opend-events" element={<OpendBooking key="events" />} />
          <Route
            path="/opend-ads"
            element={<OpendBooking isAds={true} key="ads" />}
          />
          <Route path="/shows-categories" Component={ShowsCategories} />
          <Route path="/auctionentrycard" Component={AuctionEntryCard} />
          <Route path="/eahs-award" Component={AwardPage} />
          <Route path="/openAppOrStore" Component={OpenApp} />
          <Route
            path="/auctionentrytotalregisteredhorses"
            Component={Auctionentrytotalregisteredhorses}
          />
          <Route
            path="/auctionentrysubmissionsuccessful"
            Component={AuctionEntrySubmissionSuccessful}
          />
          <Route
            path="/bookingsuccessful"
            Component={BookingSubmissionSuccessful}
          />
          <Route
            path="/requestHorseOwnershipTransferSeller"
            Component={RequestHorseOwnershipTransferSeller}
          />
          <Route
            path="/servicesrequestspaymentsuccessful"
            Component={ServicesRequestsPaymentSuccessful}
          />
          <Route path="/Educational-Courses" Component={EducationalPage} />
          {/* <Route
            path="/Educational-Courses"
            Component={ComingSoon}
          /> */}
          <Route
            path="/SingleTermsAndConditionsShowPage"
            Component={SingleTermsAndConditionsShowPage}
          />
          <Route
            path="/add-organizer-request"
            Component={AddOrganizerRequest}
          />
          <Route path="/ShowCatalog" Component={ShowCatalog} />
          <Route path="/404-not-found" Component={ComingSoon} />
          <Route path="/403-Not-Authrized" key={'not Auth'} element={<ComingSoon key={'not authrized'} text="web.notAuthrized" defaultText="Not Authrized" />} />
          <Route path="/live-event" Component={LiveStream} />
          <Route
            path="/requestCoveringCertificate"
            Component={RequestCoveringCertificate}
          />
          <Route
            path="/requestEmbryoOocyteTransferPermit"
            Component={RequestEmbryoOocyteTransferPermit}
          />
          <Route
            path="/requestEmbryoTransferRightPurchaceAgreement"
            Component={RequestEmbryoTransferRightPurchaceAgreement}
          />
          <Route
            path="/requestRegisteringNewArabianHorseFoal"
            Component={RequestRegisteringNewArabianHorseFoal}
          />
          <Route
            path="/requestForOwnedEAHSRegisteredHorsesCertificate"
            Component={RequestForOwnedEAHSRegisteredHorsesCertificate}
          />
          <Route
            path="/requestForRegistrationOfAnArabianHorseStud"
            element={<RequestForRegistrationOfAnArabianHorseStud toast={toast} />}
          />
          <Route
            path="/requestToUpdateArabianHorseData"
            Component={RequestToUpdateArabianHorseData}
          />
          <Route
            path="/requestToDeclarationOfArabianHorseDeath"
            Component={RequestToDeclarationOfArabianHorseDeath}
          />
          <Route
            path="/inactiveHorseRegistrationRequest"
            Component={InactiveHorseRegistrationRequest}
          />
          <Route
            path="/requestToAddARacingNameForAnImportedArabianHorse"
            Component={RequestToAddARacingNameForAnImportedArabianHorse}
          />
          <Route
            path="/reqestForIssuingAnArabianHorsePassportDuplicateDamaged"
            Component={ReqestForIssuingAnArabianHorsePassportDuplicateDamaged}
          />
          <Route
            path="/importHorseFrozenSemen"
            Component={ImportHorseFrozenSemen}
          />
          <Route
            path="/pressCoverageRequest"
            Component={PressCoverageRequest}
          />
          <Route
            path="/m-opend-events"
            element={<OpendBooking isMobileWebView={true} />}
          />
          <Route
            path="/m-opend-ads"
            element={<OpendBooking isAds={true} isMobileWebView={true} />}
          />
          <Route path="/m-book-table" element={<BookTable />} />
          <Route
            path="/m-ads-book-table"
            element={<BookTable isAds={true} />}
          />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
